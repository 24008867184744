import { ScrollArea } from "@/components/ui/scrollarea";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
import { apiFetch, apiFetchNoContent, useAppContext } from "@/contexts";
import { Dialog, DialogTrigger, DialogClose, DialogContent, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { isOrgAdmin } from "@/contexts/User";
import { toast } from "sonner";

interface SubscriptionInfo {
    plan: string;
    status: string;
    startDate: string;
    billingDate: string;
    trialEnd?: string;
}

async function getSubscriptionPlan(): Promise<SubscriptionInfo> {
    return await apiFetch<SubscriptionInfo>('/api/v1/org/subscription',
        { method: "GET", headers: { "Content-Type": "application/json" } }
    );
}

async function deactivateSubscription(): Promise<void> {
    return await apiFetchNoContent('/api/v1/org/subscription',
        { method: "DELETE", headers: { "Content-Type": "application/json" } }
    );
}

export function SubscriptionSettings() {
    const { loggedInUser } = useAppContext();
    const orgAdmin = isOrgAdmin(loggedInUser);
    const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionInfo | null>(null);
    const [cancelText, setCancelText] = useState("");

    useEffect(() => {
        if (orgAdmin) {
            getSubscriptionPlan().then(setSubscriptionPlan);
        }
    }, [loggedInUser, orgAdmin]);

    const checkConfirm = () => {
        if (cancelText.toLowerCase() === "cancel") {
            deactivateSubscription().then(() => {
                toast.info("Your subscription has been canceled");
            }).catch(() => {
                toast.error("There was an error canceling your subscription, please contact support");
            });
        } else {
            alert("Your subscription was not canceled. Please type 'cancel' to confirm");
        }
    };

    return <ScrollArea className="p-8 detail-grid__scrolling-area">
        {subscriptionPlan && <>
            <div className="extra-padding grid grid-cols-2 items-center rounded-lg border p-4 mt-2">
                <h4 className="mb-2 col-span-2">Subscription</h4>
                <Label>Plan:</Label> <p>{subscriptionPlan.plan}</p>
                <Label>Status:</Label> <p>{subscriptionPlan.status}</p>
                <Label>Start Date:</Label> <p>{subscriptionPlan.startDate}</p>
                <Label>Billing Date:</Label> <p>{subscriptionPlan.billingDate}</p>
                {subscriptionPlan.trialEnd && <><Label>Trial End:</Label><p>{subscriptionPlan.trialEnd}</p></>}
                <Dialog>
                    <DialogTrigger>
                        <Button className="mt-4 col-span-2 w-1/2 mx-auto btn">Cancel Subscription</Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogTitle>Are you sure you want to cancel your subscription?</DialogTitle>
                        <DialogDescription>Any usage beyond the basic plan will be disabled and your excess projects and team members will be archived.</DialogDescription>
                        <Input value={cancelText} onChange={(e) => setCancelText(e.target.value)} placeholder="Type 'cancel' to confirm" />
                        <DialogClose className="flex justify-end">
                            <Button className="btn bg-red-500 dark:bg-hellotrope-400 mr-auto" onClick={checkConfirm}>Confirm</Button>
                            <Button className="btn">Back</Button>
                        </DialogClose>
                    </DialogContent>
                </Dialog>
            </div>
        </>}
    </ScrollArea>;
}
