import { ChevronsDown, Lightbulb, Milestone, Terminal } from "lucide-react";
import { TbReport } from "react-icons/tb";
import {
    FaCircleChevronRight,
    FaCircleCheck,
    FaCircleXmark,
    FaDiagramProject,
} from "react-icons/fa6";
import { FaDatabase, FaPencilAlt, FaProjectDiagram, FaRegChartBar, FaTasks, FaUsers } from "react-icons/fa";
import React, { useEffect, useState, useImperativeHandle, useCallback, forwardRef } from "react";

import { cache, useAppContext, useInstructionContext } from "@/contexts";
import { useTask } from "@/contexts/Task";
import {
    Consent,
    ConsentMilestone,
    ConsentMilestoneReply,
    ConsentMilestoneUpdateWrapper,
    ConsentProject,
    ConsentProjectReply,
    ConsentProjectUpdateWrapper,
    ConsentRequest,
    ConsentTask,
    ConsentTaskReply,
    ConsentTaskUpdateWrapper,
    Message,
    TipPayload
} from "@/contexts/InstructionContext";
import AppPage from "@/components/AppPage";
import Spinner from "@/components/Spinner";
import assistantImage from "@/assets/SUBSEQ-logo.png";
import errorImage from "@/assets/error.webp";
import defaultUserImage from "@/assets/protag.webp";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
    Sheet,
    SheetClose,
    SheetHeader,
    SheetOverlay,
    SheetPortal,
    SheetPrimitive,
    sheetVariants,
} from "@/components/ui/sheet"
import { cn } from "@/lib/utils";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import useAutosizeTextArea from "@/useAutosizeTextArea";
import { shuffle } from "@/tools";
import { UploadFileList, UploadFileButton } from "./UploadFiles";
import { UploadedFile } from "@/contexts/Data";
import { TaskMessage, ProjectMessage, MilestoneMessage } from "@/components/InstructionMessages";

interface MessageDisplay {
    portrait: string;
    text: string;
}

function ErrorMessage({ portrait, text }: MessageDisplay) {
    return (
        <div className="flex my-10 mx-1 p-4 border border-red-500 dark:border-red-500 rounded-lg">
            <img src={portrait} className="personelle__img" />
            <div className="my-3">{text}</div>
        </div>
    );
}

function LeftMessage({ portrait, text }: MessageDisplay) {
    return (
        <div className="flex">
            <img src={portrait} className="personelle__img mr-2" />
            <Markdown className="instructions__left-text">{text}</Markdown>
        </div>
    );
}

function RightMessage({ portrait, text }: MessageDisplay) {
    return (
        <div className="flex">
            <div className="instructions__right-text">{text}</div>
            <img src={portrait} className="personelle__img" />
        </div>
    );
}

interface TaskConsentPanelProps {
    task: ConsentTask;
    onAccept(): void;
    onReject(): void;
}

function TaskConsentPanel({
    task,
    onAccept,
    onReject,
}: TaskConsentPanelProps) {
    return (
        <div className="flex">
            <div className="instructions__card flex-col w-full m-4 dark:border-neutral-600 shadow-lg shadow-black">
                <div className="card__top-bar items-center rounded-lg m-2">
                    <div className="card__icon">
                        <FaTasks />
                    </div>
                    <h2 className="card-header">{task.title}</h2>
                </div>
                <div className="detail-grid__text max-h-24 text-sm dark:border-neutral-500">{task.description}</div>
                <AcceptRejectButtons onAccept={onAccept} onReject={onReject} />
            </div>
        </div>
    );
}

interface TaskUpdatePanelProps {
    update: ConsentTaskUpdateWrapper;
    onAccept(): void;
    onReject(): void;
}

function TaskUpdatePanel({
    update,
    onAccept,
    onReject,
}: TaskUpdatePanelProps) {
    const { data: taskDetail } = useTask(update.task.task_id);
    const task = taskDetail?.task;
    const name = task ? task.title : update.task.task_id;

    return (
        <div className="flex">
            <div className="instructions__card flex-col w-full m-4 dark:border-neutral-600 shadow-lg shadow-black">
                <div className="card__top-bar items-center m-2 rounded-lg p-2">
                    <div className="card__icon">
                        <FaTasks />
                    </div>
                    <h2 className="card-header">{name}</h2>
                </div>
                <div className="detail-grid__text max-h-24 text-sm dark:border-neutral-600 mb-2">{update.task.description}</div>
                <div className="detail-grid__text text-sm mb-2">Update this task?</div>
                <AcceptRejectButtons onAccept={onAccept} onReject={onReject} />
            </div>
        </div>
    );
}

interface AcceptRejectButtonsProps {
    onAccept(): void;
    onReject(): void;
    acceptString?: string;
    rejectString?: string;
    className?: string;
}

function AcceptRejectButtons({ onAccept, onReject, acceptString, rejectString, className }: AcceptRejectButtonsProps) {
    const acceptText = acceptString || "Accept";
    const rejectText = rejectString || "Reject";
    return <div className={cn("flex m-2", className)}>
        <Button
            className="p-2 transition-colors duration-500 shadow-black shadow-md btn"
            onClick={onAccept}
        >
            <FaCircleCheck className="cursor-pointer" />
            <Label className="ml-1 cursor-pointer">{acceptText}</Label>
        </Button>
        <Button className="rounded-3xl transition-colors duration-500 shadow-black shadow-md bg-red-500 hover:bg-netural-red-700 dark:bg-hellotrope-400 dark:hover:bg-neutral-100 text-white dark:text-black ml-auto" onClick={onReject}>
            <FaCircleXmark className="cursor-pointer" />
            <Label className="ml-1 cursor-pointer">{rejectText}</Label>
        </Button>
    </div>;
}

interface ProjectConsentPanelProps {
    project: ConsentProject;
    onAccept(): void;
    onReject(): void;
}

function ProjectConsentPanel({
    project,
    onAccept,
    onReject,
}: ProjectConsentPanelProps) {
    return (
        <div className="flex">
            <div className="instructions__card flex-col w-full m-4 dark:border-neutral-600 shadow-lg shadow-black">
                <div className="card__top-bar items-center m-2 rounded-lg p-2">
                    <div className="card__icon">
                        <FaDiagramProject />
                    </div>
                    <h2 className="card-header">{project.name}</h2>
                </div>
                <div className="detail-grid__text text-sm dark:border-neutral-600 mb-2">{project.description}</div>
                <AcceptRejectButtons onAccept={onAccept} onReject={onReject} />
            </div>
        </div>
    );
}

interface ProjectUpdatePanelProps {
    update: ConsentProjectUpdateWrapper;
    onAccept(): void;
    onReject(): void;
}

function ProjectUpdatePanel({
    update,
    onAccept,
    onReject,
}: ProjectUpdatePanelProps) {
    return (
        <div className="flex">
            <div className="instructions__card flex-col w-full m-4 dark:border-neutral-600 shadow-lg shadow-black">
                <div className="card__top-bar items-center m-2 rounded-lg p-2">
                    <div className="card__icon">
                        <FaDiagramProject />
                    </div>
                    <h2 className="card-header">{update.project.name}</h2>
                </div>
                <div className="detail-grid__text text-sm dark:border-neutral-600 mb-2">{update.project.description}</div>
                <div className="detail-grid__text text-sm mb-2">Update this project?</div>
                <AcceptRejectButtons onAccept={onAccept} onReject={onReject} />
            </div>
        </div>
    );
}

interface MilestoneConsentPanelProps {
    milestone: ConsentMilestone;
    onAccept(): void;
    onReject(): void;
}

function MilestoneConsentPanel({
    milestone,
    onAccept,
    onReject,
}: MilestoneConsentPanelProps) {
    return (
        <div className="flex">
            <div className="instructions__card flex-col w-full m-4 dark:border-neutral-600 shadow-lg shadow-black">
                <div className="card__top-bar items-center m-2 rounded-lg p-2">
                    <div className="card__icon">
                        <Milestone />
                    </div>
                    <h2 className="card-header">{milestone.name}</h2>
                </div>
                <div className="detail-grid__text text-sm dark:border-neutral-600 mb-2">{milestone.description}</div>
                <AcceptRejectButtons onAccept={onAccept} onReject={onReject} />
            </div>
        </div>
    );
}

interface MilestoneUpdatePanelProps {
    update: ConsentMilestoneUpdateWrapper;
    onAccept(): void;
    onReject(): void;
}

function MilestoneUpdatePanel({
    update,
    onAccept,
    onReject,
}: MilestoneUpdatePanelProps) {
    return (
        <div className="flex">
            <div className="instructions__card flex-col w-full m-4 dark:border-neutral-600 shadow-lg shadow-black">
                <div className="card__top-bar items-center m-2 rounded-lg p-2">
                    <div className="card__icon">
                        <Milestone />
                    </div>
                    <h2 className="card-header">{update.milestone.name}</h2>
                </div>
                <div className="detail-grid__text text-sm dark:border-neutral-600 mb-2">{update.milestone.description}</div>
                <div className="detail-grid__text text-sm mb-2">Update this milestone?</div>
                <AcceptRejectButtons onAccept={onAccept} onReject={onReject} />
            </div>
        </div>
    );
}

interface ConsentMessageProps {
    payload: ConsentRequest;
    onAccept(): void;
    onReject(): void;
}

interface TipJarProps {
    visible: boolean,
    tips: TipPayload[]
    onUseTip(tip: TipPayload): void;
    display: number;
}

function TipJar({ visible, tips, onUseTip, display }: TipJarProps) {
    if (!visible) {
        return null;
    }
    const displayTips = shuffle(tips).slice(0, display);

    return (
        <div className="m-4 w-96 grid grid-cols-2 gap-4">
            {displayTips.map((tip, index) => (
                <TipMessage key={index} onClick={() => onUseTip(tip)} message={tip} className="instructions__tip" />
            ))}
        </div>
    );
}

interface TipMessageProps {
    message: TipPayload;
    className?: string;
    onClick?(): void;
}

export function TipMessage({ message, className, onClick }: TipMessageProps) {
    let icon = <Lightbulb className="mb-2" />;
    if (message.icon === "pen") {
        icon = <FaPencilAlt className="mb-2" />;
    } else if (message.icon === "plan") {
        icon = <FaRegChartBar className="mb-2" />;
    } else if (message.icon === "milestone") {
        icon = <Milestone className="mb-2" />;
    } else if (message.icon === "data") {
        icon = <FaDatabase className="mb-2" />;
    } else if (message.icon === "reports") {
        icon = <TbReport className="mb-2" />;
    } else if (message.icon === "team") {
        icon = <FaUsers className="mb-2" />;
    } else if (message.icon === "projects") {
        icon = <FaProjectDiagram className="mb-2" />;
    } else if (message.icon === "tasks") {
        icon = <FaTasks className="mb-2" />;
    } else if (message.icon === "console") {
        icon = <Terminal className="mb-2" />;
    }

    return <div className={className} onClick={onClick}>
        {icon}
        {message.content}
    </div>;
}

function ConsentMessage({ payload, onAccept, onReject }: ConsentMessageProps) {
    if ("task" in payload) {
        return <TaskConsentPanel
            task={payload.task}
            onAccept={onAccept}
            onReject={onReject}
        />;
    } else if ("taskUpdate" in payload) {
        return <TaskUpdatePanel
            update={payload.taskUpdate}
            onAccept={onAccept}
            onReject={onReject}
        />
    } else if ("project" in payload) {
        return <ProjectConsentPanel
            project={payload.project}
            onAccept={onAccept}
            onReject={onReject}
        />;
    } else if ("projectUpdate" in payload) {
        return <ProjectUpdatePanel
            update={payload.projectUpdate}
            onAccept={onAccept}
            onReject={onReject}
        />;
    } else if ("milestone" in payload) {
        return <MilestoneConsentPanel
            milestone={payload.milestone}
            onAccept={onAccept}
            onReject={onReject}
        />;
    } else if ("milestoneUpdate" in payload) {
        return <MilestoneUpdatePanel
            update={payload.milestoneUpdate}
            onAccept={onAccept}
            onReject={onReject}
        />;
    } else {
        console.error("Unknown consent payload", payload);
        return null;
    }
}

interface InstructionMessageProps {
    message: Message;
    onAccept(): void;
    onReject(): void;
}

function InstructionMessage({
    message,
    onAccept,
    onReject,
}: InstructionMessageProps) {
    const [userImage, setUserImage] = useState(defaultUserImage);
    const { loggedInUser } = useAppContext();

    useEffect(() => {
        if (loggedInUser === null) {
            return;
        }
        cache.getPortrait(loggedInUser.id).then((portrait) => {
            setUserImage(portrait);
        });
    }, [loggedInUser]);

    if ("consentId" in message) {
        return <ConsentMessage
            payload={message.payload}
            onAccept={onAccept}
            onReject={onReject} />;
    } else if ("tipId" in message) {
        return null;
    } else if ("content" in message) {
        if ("text" in message.content) {
            const text = message.content.text;
            if (message.role === "error") {
                return <ErrorMessage portrait={errorImage} text={text} />;
            } else if (message.role === "assistant" || message.role === "dashboard") {
                return <LeftMessage portrait={assistantImage} text={text} />;
            } else if (message.role === "user") {
                return <RightMessage portrait={userImage} text={text} />;
            } else {
                return null;
            }
        } else {
            if ("task" in message.content.object) {
                const obj = message.content.object as ConsentTaskReply;
                return <TaskMessage {...obj.task} />;
            } else if ("project" in message.content.object) {
                const obj = message.content.object as ConsentProjectReply;
                return <ProjectMessage {...obj.project} />;
            } else if ("milestone" in message.content.object) {
                const obj = message.content.object as ConsentMilestoneReply;
                return <MilestoneMessage {...obj.milestone} />;
            }
            const obj = JSON.stringify(message.content.object);
            return <LeftMessage portrait={assistantImage} text={obj} />;
        }
    }
}


function instructionsRunning(messages: Message[]): boolean {
    if (messages.length === 0) {
        return false;
    }
    const lastMessage = messages[messages.length - 1];
    if ("role" in lastMessage) {
        return lastMessage.role === "user" || lastMessage.state === "ongoing";
    }
    return false;
}

interface ConsentIndexGroup {
    message: Consent;
    index: number;
}

const STICKY_THRESHOLD = 400; // px

interface InstructionMessagesProps {
    refocus: number;
    className?: string;
    showTipsDefault?: boolean;
    showScrollButton?: boolean;
}

export interface InstructionRef {
    useTip(tip: TipPayload): void;
}

export const InstructionMessages = forwardRef<InstructionRef, InstructionMessagesProps>(({ refocus, className, showTipsDefault = true, showScrollButton = true }, instructionRef) => {
    const { sendWebMessage, socketIsConnected } = useAppContext();
    const { tips } = useInstructionContext();
    const innerRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<InstructionInputBoxHandle>(null);
    const [highestScroll, setHighestScroll] = useState(0);
    const navigate = useNavigate();

    const useTip = (tip: TipPayload) => {
        if (tip.path) {
            navigate(tip.path);
        } else {
            inputRef.current?.setText(tip.completeMessage);
        }
    };

    useImperativeHandle(instructionRef, () => ({
        useTip,
    }));

    const {
        messages,
        onAcceptConsent,
        onRejectConsent,
        onAcceptAllConsent,
        onRejectAllConsent } = useInstructionContext();
    const [showTips, setShowTips] = useState(showTipsDefault);

    const scrollToBottom = () => {
        innerRef.current?.scrollTo({
            top: innerRef.current.scrollHeight,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (refocus >= 0) {
            setTimeout(() => {
                scrollToBottom();
            }, 100);
        }
    }, [refocus]);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            if (innerRef.current?.scrollHeight || 0 > highestScroll) {
                if (isNearBottom() || highestScroll === 0) {
                    scrollToBottom();
                }
                setHighestScroll(innerRef.current?.scrollHeight || 0);
            }
        });
        observer.observe(innerRef.current!, {
            childList: true,
            subtree: true,
            characterData: true,
        });
        return () => observer.disconnect();
    }, [highestScroll]);

    const isNearBottom = () => {
        const { scrollHeight, scrollTop, clientHeight } = innerRef.current!;
        return scrollHeight - scrollTop - clientHeight < STICKY_THRESHOLD;
    }

    const onAcceptAll = useCallback(() => {
        onAcceptAllConsent();
        scrollToBottom();
    }, [onAcceptAllConsent]);

    const onRejectAll = useCallback(() => {
        onRejectAllConsent();
    }, [onRejectAllConsent]);

    function sendTextMessage(msg: string, uploadedFiles: UploadedFile[]) {
        if (!socketIsConnected) {
            console.error("Websocket is not connected");
            return;
        }
        setShowTips(false);
        const attachments = uploadedFiles?.map((file) => file.fileId);
        sendWebMessage({ instruct: { message: { msg, attachments } } });
        inputRef.current?.clearText();
    }

    const isRunning = instructionsRunning(messages);
    const consentMessages: ConsentIndexGroup[] = messages
        .map((message, index) => { return { message: message as Consent, index }; })
        .filter(({ message }) => "consentId" in message);

    return (
        <div className="h-full flex flex-col">
            <div ref={innerRef} className={cn("instructions__messages", className)}>
                {showScrollButton && <Button className="btn mx-auto" onClick={scrollToBottom}><ChevronsDown /></Button>}
                {messages.map((message, index) => (
                    <InstructionMessage
                        key={index}
                        message={message}
                        onAccept={() => onAcceptConsent(index)}
                        onReject={() => onRejectConsent(index)}
                    />
                ))}
                {consentMessages.length > 1 && <><div className='h-4' /><AcceptRejectButtons className="mx-4" onAccept={onAcceptAll} onReject={onRejectAll} acceptString="Accept All" rejectString="Reject All" /></>}
                <TipJar visible={showTips} tips={tips} onUseTip={useTip} display={4} />
                {isRunning && <Spinner className="text-cerulean-600 dark:text-aquamarine-400" />}
            </div>
            <InstructionInputBox onSend={sendTextMessage} refocus={refocus} ref={inputRef} />
        </div>);
});

interface InstructionInputBoxHandle {
    clearText(): void;
    setText(msg: string): void;
}

interface InputProps {
    onSend(msg: string, uploadedList: UploadedFile[]): void;
    refocus: number;
}

const InstructionInputBox = React.forwardRef<InstructionInputBoxHandle, InputProps>(({ onSend, refocus }, ref) => {
    const [content, setContent] = useState("");
    const [uploadedList, setUploadedList] = useState<UploadedFile[]>([]);
    const inputRef = React.useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => ({
        clearText() {
            setContent("");
        },
        setText(msg: string) {
            setContent(msg);
        }
    }));

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 100);
    }, [inputRef, refocus]);

    function clickEventHandler() {
        onSend(content, uploadedList);
        setUploadedList([]);
        setContent("");
    }

    function changeEventHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        if (event.target !== null) {
            const change = event.target.value;
            setContent(change);
        }
    }

    function handleEnter(event: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (event.key == "Enter" && !event.shiftKey) {
            event.preventDefault();
            content.trim();
            if (content.length > 0) {
                clickEventHandler();
            }
        }
    }

    function addUploadedList(data: UploadedFile) {
        setUploadedList([...uploadedList, data]);
    }

    function removeUploadedList(fileId: string) {
        setUploadedList(uploadedList.filter((file) => file.fileId !== fileId));
    }

    useAutosizeTextArea(inputRef.current, content);

    return (
        <>
            <UploadFileList uploadedList={uploadedList} removeUploadedList={removeUploadedList} />
            <div className="instructions__input-box p-2 flex">
                <textarea
                    ref={inputRef}
                    onInput={changeEventHandler}
                    rows={1}
                    onKeyDown={handleEnter}
                    value={content}
                    autoCapitalize="sentences"
                    spellCheck={true}
                    aria-label="input"
                    className="instructions__input"
                    placeholder="Conversation..."
                ></textarea>
                <UploadFileButton fileType="assistant" iconOnly={true} addUploadedList={addUploadedList} />
                <div onClick={clickEventHandler} className="mr-1">
                    <FaCircleChevronRight />
                </div>
            </div>
        </>
    );
});

export function InstructionsPage() {
    return <AppPage active="instructions">
        <Instructions fullPage />
    </AppPage>;
}

interface InstructionsProps {
    fullPage?: boolean;
}

function Instructions({ fullPage = false }: InstructionsProps) {
    const [dataState, setDataState] = useState<"open" | "closed">("closed");
    const [rightDataState, setRightDataState_] = useState<"open" | "closed">("closed");
    const [rightIsVisible, setRightIsVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [refocus, setRefocus] = useState(0);
    const { conversationOpen, setConversationOpen } = useInstructionContext();

    const setRightDataState = useCallback((state: "open" | "closed") => {
        if (state === "open") {
            setRightDataState_("open");
            setTimeout(() => setRightIsVisible(true), 100);
        } else {
            setRightDataState_("closed");
            setTimeout(() => setRightIsVisible(false), 200);
        }
    }, [setRightDataState_, setRightIsVisible]);

    const handleOpenClosed = useCallback((state: boolean) => {
        if (state) {
            setConversationOpen(state);
            setDataState("open");
            setRefocus(new Date().getTime());
            setTimeout(() => setIsVisible(state), 100);
        } else {
            setDataState("closed");
            setRightDataState("closed");
            setTimeout(() => {
                setIsVisible(state)
                setConversationOpen(state);
            }, 200);
        }
    }, [setConversationOpen, setDataState, setRightDataState]);

    useEffect(() => {
        handleOpenClosed(conversationOpen);
    }, [conversationOpen, handleOpenClosed]);

    const instructions = fullPage ? (
        <div className="instructions__container w-full p-4" >
            <div className="instructions">
                <InstructionMessages refocus={refocus} />
            </div>
        </div>
    ) : (
        <>
            <Sheet open={conversationOpen} onOpenChange={handleOpenClosed}>
                <SheetHeader>
                    <SheetClose />
                </SheetHeader>
                <SheetPortal>
                    <SheetOverlay />
                    <SheetPrimitive.Content className={isVisible ? "" : "hidden"}>
                        <>
                            <div
                                data-state={rightDataState}
                                hidden={!rightIsVisible}
                                className={cn(sheetVariants({ side: "right" }), `w-1/3 data-[state=${rightDataState}]`)}
                            >
                            </div>
                            <div data-state={dataState} className={cn(sheetVariants({ side: "left" }), "w-1/3")}>
                                <InstructionMessages refocus={refocus} />
                            </div>
                        </>
                    </SheetPrimitive.Content>
                </SheetPortal>
            </Sheet>
        </>
    );

    return instructions;
}
export default Instructions;
