import { SideBarContainer, SideBarIcon } from "@/components/SideBar";
import { ChevronLeft } from 'lucide-react';
import { FaCreditCard, FaUser, FaUsers } from "react-icons/fa6";
import { GoOrganization } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function Icons({ active }: { active: string }) {
    const navigate = useNavigate();

    const navigateToSubpage = (name: string) => {
        if (name === active) {
            return;
        }
        navigate(`/app/settings/${name}`);
    }

    return (
        <>
            <SideBarIcon fn={() => navigate("/")} mobile={false} text="Back" name="back" active={""} icon={<ChevronLeft />} />
            <SideBarIcon fn={() => navigateToSubpage("profile")} mobile={false} text="Profile" name="profile" active={active} icon={<FaUser />} />
            <SideBarIcon fn={() => navigateToSubpage("accounts")} mobile={false} text="Accounts" name="accounts" active={active} icon={<FaUsers />} />
            <SideBarIcon fn={() => navigateToSubpage("org")} mobile={false} text="Organization" name="org" active={active} icon={<GoOrganization />} />
            <SideBarIcon fn={() => navigateToSubpage("plan")} mobile={false} text="Plan" name="plan" active={active} icon={<FaCreditCard />} />
        </>
    );
}

function BottomIcons() {
    return <></>;
}

export default function SideBar({ active }: { active: string }) {
    return <SideBarContainer icons={<Icons active={active} />} bottomIcons={<BottomIcons />} />;
}
