import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import useWebSocket, { ReadyState } from "react-use-websocket";
import store from 'store2';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import type { Config } from 'tailwindcss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { isDevelopment, eventEmitter } from '@/contexts';
import ChartProvider from '@/contexts/Chart';
import DataProvider from '@/contexts/Data';
import InstructionProvider from '@/contexts/InstructionContext';
import MilestoneProvider from '@/contexts/Milestone';
import ProjectsProvider from '@/contexts/Project';
import UsersProvider from '@/contexts/User';
import tailwindConfig from "../../tailwind.config.js";
import { ProviderProps } from '@/contexts/ContextTypes';
import { TooltipProvider } from '@/components/ui/tooltip';
import { Order } from "@/contexts/Task";
import { UserData } from "@/contexts/User";

export type CallbackType = (data?: unknown) => void;
export type SendMessageFunction = (message: object | Blob) => void;
export type SocketIsConnectedFunction = () => boolean;

export type DisplayTheme = "dark" | "light";

export interface TourState {
    onboarding: boolean;
    stepIndex: number;
}

export interface AppContextProps {
    config: Config;
    loggedInUser: UserData | null;
    loginAttempted: boolean;
    selectedTheme: DisplayTheme | null;
    sendWebMessage: SendMessageFunction;
    socketIsConnected: SocketIsConnectedFunction;
    theme: DisplayTheme;
    tourState: TourState;
    narrow: boolean;
    mobile: boolean;
    width: number;
    height: number;
    order: Order;
    filter: string;

    setLoggedInUser: (user: UserData | null) => void;
    refreshUser: () => void;
    setOrder: (order: Order) => void;
    setFilter: (filter: string) => void;
    setTheme: (theme: DisplayTheme | null) => void;
    setTourState: (tourState: TourState) => void;
}

export const AppContext = createContext<null | AppContextProps>(null);

interface WebSocketProps {
    wsAddr: string;
    onClose: (e: Event) => void;
    onError: (e: Event) => void;
}

interface WebMessage {
    channel: string;
}
function useAppWebSocket({ wsAddr, onClose, onError }: WebSocketProps) {
    const RETRY_MAX = 1000;
    let shouldReconnect = true;
    if (isDevelopment) {
        shouldReconnect = false;
    }

    const { sendMessage, sendJsonMessage, lastJsonMessage, readyState } =
        useWebSocket(wsAddr, {
            shouldReconnect: () => shouldReconnect,
            reconnectInterval: RETRY_MAX,
            reconnectAttempts: Infinity,
            onError,
            onClose,
            heartbeat: true,
        });
    useEffect(() => {
        if (lastJsonMessage !== null && readyState === ReadyState.OPEN) {
            const msg = lastJsonMessage as WebMessage;
            console.log("Received message on channel", msg.channel, ":", msg);
            eventEmitter.emit(msg.channel, lastJsonMessage);
        }
    }, [lastJsonMessage, readyState]);

    const sendWebMessage = useCallback(
        (message: Blob | object) => {
            if (message instanceof Blob) {
                sendMessage(message);
            } else {
                sendJsonMessage(message);
            }
        },
        [sendMessage, sendJsonMessage],
    );

    const socketIsConnected = useCallback(() => {
        return readyState === ReadyState.OPEN;
    }, [readyState]);

    return { sendWebMessage, socketIsConnected };
}

function getWebSocketUrl(): string {
    const protocol = window.location.protocol;
    const wsProtocol = protocol === "https:" ? "wss://" : "ws://";
    const host = window.location.hostname;
    const port = window.location.port ? `:${window.location.port}` : "";
    const wsUrl = `${wsProtocol}${host}${port}/api/v1/ws`;
    return wsUrl;
}

async function whoAmI(): Promise<UserData> {
    const response = await fetch('/api/v1/user/me', { method: 'GET' });
    if (!response.ok) {
        throw new Error("Failed to retrieve user");
    }
    return await response.json();
}

function storeTheme(theme: DisplayTheme | null) {
    store.set('theme', theme);
}

function getStoredTheme(): DisplayTheme | null {
    return store.get('theme', null);
}

function detectTheme(): DisplayTheme {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
}

const NARROW_WIDTH = 1400;
const MOBILE_WIDTH = 750;

const AppContextProvider: React.FC<ProviderProps> = ({ children }) => {
    const [loggedInUser, setLoggedInUser] = useState<UserData | null>(null);
    const [loginAttempted, setLoginAttempted] = useState<boolean>(false);
    const [tourState, setTourState] = useState<TourState>({ onboarding: false, stepIndex: 0 });
    const [loginCache, setLoginCache] = useState(Date.now());
    const [systemTheme, setSystemTheme] = useState<DisplayTheme>(detectTheme());
    const [selectedTheme, setSelectedTheme] = useState<DisplayTheme | null>(getStoredTheme());
    const [theme, setTheme_] = useState<DisplayTheme>(selectedTheme ? selectedTheme : systemTheme);
    const [config] = useState(tailwindConfig);

    // Search parameters
    const [order, setOrder] = useState<Order>("priority");
    const [filter, setFilter] = useState<string>("");

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [narrow, setNarrow] = useState(width < NARROW_WIDTH);
    const [mobile, setMobile] = useState(width < MOBILE_WIDTH);

    useEffect(() => {
        const resizeListener = () => {
            setNarrow(window.innerWidth < NARROW_WIDTH);
            setMobile(window.innerWidth < MOBILE_WIDTH);
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    const setTheme = useCallback((selectedTheme: DisplayTheme | null) => {
        setSelectedTheme(selectedTheme);
        storeTheme(selectedTheme);
        const newTheme = selectedTheme ? selectedTheme : systemTheme;
        if (newTheme != theme) {
            const rootElement = document.documentElement;
            rootElement.classList.add('theme-transition');
            setTimeout(() => {
                setTheme_(newTheme);
                setTimeout(() => {
                    rootElement.classList.remove('theme-transition');
                }, 800);
            }, 400);
        }
    }, [systemTheme, theme]);

    if (theme === 'dark') {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }

    const systemThemeChangeListener = useCallback((e: MediaQueryListEvent) => {
        const newTheme = e.matches ? 'dark' : 'light';
        if (selectedTheme === null) {
            setTheme_(newTheme);
        }
        setSystemTheme(newTheme);
    }, [selectedTheme]);

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', systemThemeChangeListener);

    const { sendWebMessage, socketIsConnected } = useAppWebSocket({
        wsAddr: getWebSocketUrl(),
        onError: (e: Event) => {
            if ("code" in e && e.code === 1008) {
                eventEmitter.emit("LOGGED_OUT");
            }
        },
        onClose: (e: Event) => {
            if ("code" in e && e.code === 1008) {
                eventEmitter.emit("LOGGED_OUT");
            }
            if (!isDevelopment && window.location.pathname !== '/app/down' && loggedInUser !== null) {
                window.location.replace('/app/down');
            }
        }
    });

    useEffect(() => {
        whoAmI().then((user) => {
            console.log("Logged in as", user.username);
            setLoginAttempted(true);
            return setLoggedInUser(user)
        }).catch(() => {
            setLoginAttempted(true);
        });
    }, [loginCache]);

    useEffect(() => {
        const clearLoggedInUser = () => {
            setLoggedInUser(null);
            setLoginCache(Date.now());
        };
        eventEmitter.on("LOGGED_OUT", clearLoggedInUser);
        return () => {
            eventEmitter.off("LOGGED_OUT", clearLoggedInUser);
        }
    }, []);

    const context = useMemo(() => {
        return {
            config,
            loggedInUser,
            loginAttempted,
            sendWebMessage,
            selectedTheme,
            socketIsConnected,
            theme,
            tourState,
            narrow,
            mobile,
            width,
            height,
            order,
            filter,

            setOrder,
            setFilter,
            setLoggedInUser,
            refreshUser: () => {
                setLoginAttempted(false);
                setLoginCache(Date.now());
            },
            setTheme,
            setTourState,
        };
    }, [loggedInUser, loginAttempted, narrow, mobile, width, height,
        sendWebMessage, order, setOrder, socketIsConnected, selectedTheme,
        filter, setFilter, theme, setTheme, config, tourState, setTourState]);

    const queryClient = useMemo(() => new QueryClient(), []);

    // Spaceship woooosh
    return (<AppContext.Provider value={context}>
        <TooltipProvider>
            <QueryClientProvider client={queryClient}>
                <InstructionProvider>
                    <ProjectsProvider>
                        <UsersProvider>
                            <ChartProvider>
                                <DataProvider>
                                    <MilestoneProvider>
                                        <DndProvider backend={HTML5Backend}>
                                            {children}
                                        </DndProvider>
                                    </MilestoneProvider>
                                </DataProvider>
                            </ChartProvider>
                        </UsersProvider>
                    </ProjectsProvider>
                </InstructionProvider>
            </QueryClientProvider>
        </TooltipProvider>
    </AppContext.Provider>);
};
export default AppContextProvider;
