import React from 'react';
import { FaVoicemail } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import DetailGrid, {
    DetailContentArea,
} from "../DetailGrid";
import { useNavigate } from 'react-router-dom';
import { UploadFileButton } from '@/components/UploadFiles';

function DataCreate() {
    const navigate = useNavigate();
    const modalRef = React.createRef<HTMLDivElement>();

    const vanish = () => {
        navigate(-1);
    };

    return (
        <div ref={modalRef} className="card-detail" >
            <div className="card-detail__top-bar">
                <div className="card__icon">
                    <FaVoicemail />
                </div>
                <div className="flex-grow" />
                <div className="card-detail__header-container">
                    <h2 className="card-detail__header">Upload Transcripts</h2>
                </div>
                <div className="card-detail__close" onClick={vanish}>
                    <FaXmark />
                </div>
            </div>
            <DetailGrid narrow={true}>
                <DetailContentArea>
                    <UploadFileButton fileType="transcript" accept="text/plain, text/html, application/json" />
                </DetailContentArea>
            </DetailGrid>
        </div>);
}
export default DataCreate;
