import React, { useState, useEffect, useCallback } from "react";

import { CgSmartphoneChip } from "react-icons/cg";
import { FaRobot, FaCodeBranch, FaFileExport } from "react-icons/fa6";
import { FaTasks, FaLink, FaArchive, FaRecycle, FaRegSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { apiFetch, eventEmitter, useAppContext, useProjectsContext, useUsersContext } from "@/contexts";
import DetailGrid, {
    DetailControls,
    DetailSidePanel,
    DetailControl,
    DetailContentArea,
} from "@/components/cards/DetailGrid";
import DetailCard from "@/components/cards/DetailCard";
import Milestone from "@/components/cards/task/Milestone";
import Personelle, { PersonelleSelector } from "@/components/Personelle";
import PlannedTask from "@/components/cards/task/PlannedTask";
import Priority from "@/components/cards/task/Priority";
import TaskRepositoryLinkage from "@/components/cards/task/TaskRepositoryLinkage";
import ChildTasks from "@/components/cards/task/ChildTasks";
import TaskComments from "@/components/cards/task/TaskComments";
import TaskState, { LinkState, TagState } from "@/components/cards/task/TaskState";
import { Button } from "@/components/ui/button";
import Floater from "@/components/Floater";
import { EditDateTime } from "@/components/cards/EditableDropdown";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useTask, useUpdateTask, Milestone as MilestoneData, TaskDetails } from "@/contexts/Task";
import { Tag } from "@/contexts/Project";
import { isOrgPlanner } from "@/contexts/User";
import { Project, Repo } from "@/contexts/Project";
import { useQueryClient } from "@tanstack/react-query";
import TaskSearch from "./TaskSearch";
import ShareButton from "@/components/ShareButton";
import Editor from "@/components/Editor";
import { toast } from "sonner";

function getProjectRepo(project: Project | null): Repo | undefined {
    if (project) {
        return project.repos.find((repo) => repo.isDefault);
    }
    return undefined;
}

function convertGitUrlToHttps(gitUrl: string): string {
    const regex = /git@github\.com:(.*)\/(.*).git/;
    const match = gitUrl.match(regex);

    if (!match) {
        throw new Error('Invalid GitHub SSH URL');
    }
    const user = match[1];
    const repo = match[2];
    return `https://github.com/${user}/${repo}`;
}

async function replanTask(taskId: string): Promise<{ seconds: number }> {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    return await apiFetch(
        `/api/v1/task/${taskId}/replan`,
        {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                units: "hours",
                today: date,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        },
        "Failed to replan task"
    );
}

async function runTask(taskId: string) {
    return await apiFetch(`/api/v1/task/run/${taskId}`, { method: "PUT" }, (response) => {
        if (!response.ok) {
            response.text().then((text) => {
                eventEmitter.emit("ERROR", "Failed to run task: " + text);
            });
            throw new Error("Failed to run task");
        }
    });
}

function TaskDetail({ slug }: { slug: string }) {
    const { loggedInUser, narrow } = useAppContext();
    const { activeProject } = useProjectsContext();
    const { users: teamUsers } = useUsersContext();

    const navigate = useNavigate();
    const activeProjectId = activeProject?.id || "";

    const { mutate: updateTask } = useUpdateTask();
    const { data: taskDetail, isLoading, isError } = useTask(slug);

    useEffect(() => {
        const updateTaskLocals = (task: TaskDetails) => {
            setMilestone(task.task.milestone);
        };

        if (!isLoading && !isError && taskDetail) {
            updateTaskLocals(taskDetail);
        }
    }, [taskDetail, isError, isLoading]);

    const [milestone, setMilestone] = useState<undefined | MilestoneData>(undefined);
    const users = activeProject?.orgId ? teamUsers : loggedInUser ? [loggedInUser] : [];

    async function openCodeLink(event: React.MouseEvent<Element, MouseEvent>) {
        event.stopPropagation();
        const repo = getProjectRepo(activeProject);

        if (repo) {
            const gitUrl = repo.repoWebUrl;
            if (gitUrl) {
                window.open(gitUrl, "_blank");
            } else {
                const gitRemote = repo.repoGitRemote;
                const httpsUrl = convertGitUrlToHttps(gitRemote);
                window.open(httpsUrl, "_blank");
            }
        }
    }

    async function onChangeTitle(title: string) {
        if (taskDetail && title != taskDetail.task.title) {
            updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: { changeTitle: { title } } });
            toast.success("Task updated", { icon: <FaRegSave /> });
        }
    }

    function assignUser(userId: string) {
        if (taskDetail) {
            updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: { assignOther: { userId } } });
            toast.success("Task updated", { icon: <FaRegSave /> });
        }
    }

    function handleSetState(flowNodeId: string) {
        if (taskDetail) {
            updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: { transition: { nodeId: flowNodeId } } })
            toast.success("Task updated", { icon: <FaRegSave /> });
        }
    }

    async function onSave(markdown: string) {
        if (taskDetail) {
            const update = { changeDescription: { description: markdown } };
            updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: update });
            toast.success("Task updated", { icon: <FaRegSave /> });
        }
    }

    const vanish = () => {
        navigate(-1);
    };

    const handleTagAdd = useCallback((tag: Tag) => {
        if (taskDetail) {
            updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: { metadata: { source: "tag", value: tag } } })
            toast.success("Task updated", { icon: <FaRegSave /> });
        }
    }, [activeProjectId, taskDetail, updateTask]);

    const handleDeleteTag = useCallback((tag: Tag) => {
        if (taskDetail) {
            updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: { removeMetadata: { value: tag } } });
            toast.success("Task updated", { icon: <FaRegSave /> });
        }
    }, [activeProjectId, updateTask, taskDetail]);

    const runEnabled = false;
    const queryClient = useQueryClient();
    const [popOverLink, setPopOverLink] = useState<string | null>(null);
    const triggerRef = React.createRef<HTMLButtonElement>();

    const onSetDate = useCallback(async (dateString: string | null) => {
        if (taskDetail?.task.id && dateString != taskDetail?.task.dueDate) {
            updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: { changeDueDate: { dueDate: dateString } } });
            toast.success("Task updated", { icon: <FaRegSave /> });
        }
    }, [taskDetail, updateTask, activeProjectId]);

    const onSetPriority = useCallback(async (value: number) => {
        if (taskDetail?.task.id && value != taskDetail?.task.priority) {
            updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: { changePriority: { value } } });
            toast.success("Task updated", { icon: <FaRegSave /> });
        }
    }, [taskDetail, updateTask, activeProjectId]);

    const handleSubmitLink = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        console.log("FORM SUBMIT");
        event.preventDefault();
        const form = event.currentTarget;
        const formElements = form.elements;
        const linkType = (formElements[1] as HTMLSelectElement).value;
        const linkTo = popOverLink;

        if (linkType && linkTo && taskDetail?.task.id) {
            updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: { link: { taskId: linkTo, linkType } } })
            toast.success("Task updated", { icon: <FaRegSave /> });
            triggerRef.current?.click();
        }
    }, [popOverLink, triggerRef, taskDetail, activeProjectId, updateTask]);

    if (isLoading || !taskDetail) {
        return <div>Loading...</div>;
    }

    const dueDate = taskDetail?.task.dueDate ? new Date(taskDetail.task.dueDate) : null;
    const completed = taskDetail?.task.abstractState === "closed";

    const DetailContentMode = !narrow ? (
        <DetailContentArea>
            <h2 className="text-center mb-2">{taskDetail?.task.slug}</h2>
            <Editor
                markdown={taskDetail.task.description}
                users={users}
                placeholder="Description..."
                onSaveMarkdown={onSave}
            />
            <ChildTasks children={taskDetail.linksIn.filter((item) => {
                return item.linkType === "subtaskOf";
            }).map((item) => item.link)} />
            {taskDetail && <TaskComments taskId={taskDetail.task.id} />}
        </DetailContentArea>
    ) : (
        <DetailContentArea id="taskDetailSidebar">
            <h1 className="text-center mb-2">{taskDetail?.task.slug}</h1>
            {taskDetail.association && <TaskRepositoryLinkage association={taskDetail.association} />}
            <div className="m-2 items-center rounded-lg border p-4">
                <Floater content="Author of this task">
                    <Personelle className="m-auto w-full" userData={taskDetail?.task.author} tag="author" />
                </Floater>
                <div className="h-2" />
                <Floater content="Assignee for this task">
                    <PersonelleSelector
                        selectedUserData={taskDetail?.task.assignee}
                        tag="assignee"
                        className="m-auto w-full"
                        userSelectionShortList={users}
                        handleUserSelect={assignUser}
                    />
                </Floater>
            </div>
            <h2 className="text-center mb-2">Description</h2>
            <TaskState
                state={taskDetail.state}
                validTransitions={taskDetail.validTransitions}
                handleSetState={handleSetState}
            />
            <Priority value={taskDetail.task.priority} onSetPriority={onSetPriority} />
            <Editor
                markdown={taskDetail.task.description}
                users={users}
                placeholder="Description..."
                onSaveMarkdown={onSave}
            />
            <ChildTasks children={taskDetail.linksIn.filter((item) => {
                return item.linkType === "subtaskOf";
            }).map((item) => item.link)} />
            <EditDateTime
                textLabel="Due"
                overdueLabel="Overdue"
                date={dueDate}
                completed={completed}
                onCompleted={onSetDate}
            />
            {taskDetail.plan && <PlannedTask plan={taskDetail.plan} />}
            {milestone && <Milestone milestone={milestone} />}
            <LinkState
                outLinks={taskDetail.linksOut}
                inLinks={taskDetail.linksIn.filter((item) => item.linkType !== "subtaskOf")}
            />
            <TagState
                metadata={taskDetail.metadata}
                handleAddTag={handleTagAdd}
                handleDeleteTag={handleDeleteTag}
            />
            {taskDetail && <TaskComments taskId={taskDetail.task.id} />}
        </DetailContentArea >
    );

    const DetailSidePanelMode = !narrow ? (
        <DetailSidePanel id="taskDetailSidebar">
            {taskDetail.association && <TaskRepositoryLinkage association={taskDetail.association} />}
            <div className="m-2 items-center justify-between rounded-lg border p-4">
                <Floater content="Author of this task">
                    <Personelle userData={taskDetail.task.author} className="m-auto w-full" tag="author" />
                </Floater>
                <div className="h-2" />
                <Floater content="Assignee for this task">
                    <PersonelleSelector
                        selectedUserData={taskDetail.task.assignee}
                        className="m-auto w-full"
                        tag="assignee"
                        userSelectionShortList={users}
                        handleUserSelect={assignUser}
                    />
                </Floater>
            </div>
            <TaskState
                state={taskDetail.state}
                validTransitions={taskDetail.validTransitions}
                handleSetState={handleSetState}
            />
            <Priority value={taskDetail ? taskDetail.task.priority : 0} onSetPriority={onSetPriority} />
            <EditDateTime
                textLabel="Due"
                overdueLabel="Overdue"
                date={dueDate}
                completed={completed}
                onCompleted={onSetDate}
            />
            {taskDetail.plan && <PlannedTask plan={taskDetail.plan} />}
            {milestone && <Milestone milestone={milestone} />}
            <LinkState
                outLinks={taskDetail.linksOut}
                inLinks={taskDetail.linksIn.filter((item) => item.linkType !== "subtaskOf")}
            />
            <TagState
                metadata={taskDetail.metadata}
                handleAddTag={handleTagAdd}
                handleDeleteTag={handleDeleteTag}
            />
        </DetailSidePanel>
    ) : <div />;

    return (
        <DetailCard
            title={taskDetail.task.title}
            titleSummaries={taskDetail.task.titleSummaries}
            titleIcon={<FaTasks className="text-lg" />}
            editable={true}
            onSetTitle={onChangeTitle}
            onClose={vanish}
        >
            <DetailGrid narrow={narrow}>
                <DetailControls id="taskControlPanel">
                    {runEnabled && <DetailControl icon={<FaRobot />} text="Run" onClick={() => {
                        if (taskDetail) {
                            runTask(taskDetail.task.id)
                        }
                    }} />}
                    <Floater content="View code for this project">
                        <DetailControl
                            icon={<FaCodeBranch />}
                            text="Code"
                            onClick={openCodeLink}
                        />
                    </Floater>
                    <Popover>
                        <PopoverTrigger ref={triggerRef}>
                            <Floater content="Create a link to another task">
                                <DetailControl icon={<FaLink />} text="Links" />
                            </Floater>
                        </PopoverTrigger>
                        <PopoverContent className="w-80 bg-neutral-50 dark:bg-neutral-950">
                            <div className="p-4 grid grid-gap-4">
                                <form onSubmit={handleSubmitLink} className="grid grid-cols-3 items-center gap-4">
                                    <Select>
                                        <SelectTrigger className="bg-neutral-50 text-neutral-600 col-span-3">
                                            <SelectValue placeholder="Link Type" />
                                        </SelectTrigger>
                                        <SelectContent className="bg-neutral-50 text-neutral-600 col-span-3">
                                            <SelectGroup>
                                                <SelectItem className="select__hover" value="dependsOn">Depends On</SelectItem>
                                                <SelectItem className="select__hover" value="subtaskOf">Subtask Of</SelectItem>
                                                <SelectItem className="select__hover" value="relatedTo">Related To</SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <TaskSearch taskId={taskDetail.task.id} onTaskSelect={setPopOverLink} />
                                    <Button className="btn col-span-3" type="submit">Create</Button>
                                </form>
                            </div>
                        </PopoverContent>
                    </Popover>
                    {isOrgPlanner(loggedInUser) && <Floater content="Run an estimate for this task's duration">
                        <DetailControl icon={<CgSmartphoneChip />} text="Smart Estimate" onClick={() => {
                            if (taskDetail) {
                                replanTask(taskDetail.task.id).then(({ seconds }: { seconds: number }) => {
                                    const hours = Math.floor(seconds / 3600);
                                    toast.success(`Estimated duration: ${hours} hours`, { icon: <FaRegSave /> });
                                    queryClient.invalidateQueries({ queryKey: ["task", taskDetail.task.id] });
                                });
                            }
                        }} />
                    </Floater>
                    }
                    <Floater content="Export this task to a YAML file">
                        <a href={`/api/v1/task/${taskDetail.task.id}/export`} target="_blank" rel="noreferrer">
                            <Button className="detail-grid__control_button m-1">
                                <FaFileExport />
                                <div>&nbsp;Export</div>
                            </Button>
                        </a>
                    </Floater>
                    <Floater content="Copy the link for this task">
                        <ShareButton className="detail-grid__control_button m-1" />
                    </Floater>
                    <div className="flex-grow" />
                    {taskDetail && taskDetail.task.abstractState === "closed" && (
                        <div className="flex">
                            <Floater content="Reopen this task">
                                <DetailControl
                                    icon={<FaRecycle />}
                                    text="Reopen"
                                    onClick={() => {
                                        updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: "restart" });
                                        toast.success("Task updated", { icon: <FaRegSave /> });
                                    }}
                                />
                            </Floater>
                            <Floater content="Archive this task, it will not be accessible">
                                <DetailControl
                                    icon={<FaArchive />}
                                    text="Archive"
                                    onClick={() => {
                                        updateTask({ projectId: activeProjectId, taskId: taskDetail.task.id, data: "archive" });
                                        toast.success("Task updated", { icon: <FaRegSave /> });
                                    }}
                                />
                            </Floater>
                        </div>
                    )}
                </DetailControls>
                {DetailSidePanelMode}
                {DetailContentMode}
            </DetailGrid>
        </DetailCard>
    );
}
export default TaskDetail;
