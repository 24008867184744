import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { debounce } from 'lodash';
import { useProjectsContext } from "@/contexts";
import { TaskData, useTasks } from "@/contexts/Task";
import { getFont, selectFromWidth } from "@/utils/tools";
import { useEffect, useRef, useState } from "react";

interface TaskSearchProps {
    taskId: string;
    onTaskSelect: (taskId: string) => void;
}

function taskTitles(task: TaskData, slug: string): string[] {
    const titles: string[] = [];
    if (task.title) {
        titles.push(`${slug}: ${task.title}`);
    }
    if (task.titleSummaries) {
        for (const summary of task.titleSummaries) {
            titles.push(`${slug}: ${summary}`);
        }
    }
    return titles;
}

function selectTitle(task: TaskData, width: number, font: string, slug: string): string {
    const title = taskTitles(task, slug);
    return selectFromWidth(title, width, font) || task.slug;
}

function TaskSearch({ taskId, onTaskSelect }: TaskSearchProps) {
    const { activeProject } = useProjectsContext();
    const projectId = activeProject?.id || null;
    const [filter, setFilter] = useState<string | null>(null);
    const [debouncedFilter, setDebouncedFilter] = useState<string | null>(null);

    useEffect(() => {
        const handle = debounce(() => setDebouncedFilter(filter), 300);
        handle();
        return () => handle.cancel();
    }, [filter]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState<TaskData | null>(null);
    const { data, isLoading, isError } = useTasks(projectId, "created", debouncedFilter || undefined);

    const triggerRef = useRef<HTMLButtonElement>(null);
    const widthSpace = triggerRef.current?.clientWidth || 0;
    const font = triggerRef.current ? getFont(triggerRef.current) : "inherit";
    const tasks = data ? data.pages.flatMap(page => page.data).filter(task => task.id != taskId) : [];

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger>
                <Button type="button" variant="outline" role="combobox" aria-expanded={isOpen} ref={triggerRef}>{selectedTask ? selectedTask.slug : "Search tasks"}</Button>
            </PopoverTrigger>
            <PopoverContent>
                <Command shouldFilter={false}>
                    <CommandInput
                        placeholder="Search tasks"
                        className="p-2 mb-1 bg-white text-black"
                        value={filter || ""}
                        onValueChange={setFilter} />
                    <CommandList className="my-1">
                        <CommandEmpty>No tasks found</CommandEmpty>
                        <CommandGroup>
                            {isLoading && <CommandItem>Loading...</CommandItem>}
                            {isError && <CommandItem>Error loading!</CommandItem>}
                            {tasks && tasks.map((task: TaskData) => (
                                <CommandItem key={task.id}
                                    onSelect={() => {
                                        console.log("Task selected", task);
                                        setSelectedTask(task);
                                        onTaskSelect(task.id);
                                        setIsOpen(false);
                                    }}>
                                    {selectTitle(task, widthSpace, font, task.slug)}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
export default TaskSearch;
