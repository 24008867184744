import { useEffect, useRef } from "react";
import AppPage from "./AppPage";
import { InstructionMessages, InstructionRef, TipMessage } from "./Instructions";
import { ScrollArea } from "@/components/ui/scrollarea";
import { useAppContext, useInstructionContext } from "@/contexts";
import { useTips } from "@/contexts/InstructionContext";

function bgForIcon(icon: string | undefined, theme: string): [string, string] {
    let bg = "bg-white";
    let text = "text-black";

    if (icon === "lightbulb" || icon === "tasks") {
        if (theme === "dark") {
            bg = "bg-ultraviolet-400";
            text = "text-white";
        } else {
            bg = "bg-vanilla-300";
        }
    } else if (icon === "console" || icon === "data") {
        if (theme === "dark") {
            bg = "bg-night-900";
            text = "text-white";
        } else {
            bg = "bg-cerulean-600";
            text = "text-white";
        }
    } else if (icon === "pen" || icon === "team") {
        if (theme === "dark") {
            bg = "bg-spacecadet-700";
            text = "text-white";
        } else {
            bg = "bg-verdigris-500";
            text = "text-white";
        }
    } else if (icon === "reports" || icon === "milestone") {
        if (theme === "dark") {
            bg = "bg-aquamarine-400";
        } else {
            bg = "bg-blue-600";
            text = "text-white";
        }
    } else if (icon === "plan" || icon === "projects") {
        if (theme === "dark") {
            bg = "bg-hellotrope-400";
        } else {
            bg = "bg-rufous-400";
            text = "text-white";
        }
    }
    return [bg, text];
}

function Dashboard() {
    const { theme, narrow, mobile, loggedInUser, setTourState, tourState } = useAppContext();
    const { sendDashboardMessage } = useInstructionContext();
    const { data: tips } = useTips();
    const displayTips = tips || [];

    useEffect(() => {
        if (tourState.onboarding && tourState.stepIndex === 0) {
            sendDashboardMessage("onboarding");
            setTourState({ ...tourState, stepIndex: 1 });
        } else if (!tourState.onboarding) {
            sendDashboardMessage("dashboard");
        }
    }, []);

    let mode = "wide";
    if (mobile) {
        mode = "mobile";
    } else if (narrow) {
        mode = "narrow";
    }

    const firstName = loggedInUser?.givenName || "there!";
    const instructionRef = useRef<InstructionRef>(null);

    return (
        <AppPage active="dashboard">
            <ScrollArea className="justify-center items-center my-10 mx-auto w-11/12 max-w-[1000px]">
                <h1 className="text-3xl font-bold text-center mb-4">Hello {firstName}</h1>
                <InstructionMessages ref={instructionRef} refocus={-1} showTipsDefault={false} showScrollButton={false} className="h-96 w-full border rounded-lg p-4" />
                {mode === "mobile" &&
                    <div className="mt-10 grid grid-cols-4 gap-2">
                        {displayTips.map((tip, index) => {
                            const [bg, text] = bgForIcon(tip.icon, theme);
                            return <div key={index} onClick={() => instructionRef.current?.useTip(tip)} className={`cursor-pointer mx-auto p-4 rounded-lg overflow-hidden h-[7rem] w-[7rem] shadow-md shadow-black ${text} ${bg}`}>
                                <TipMessage message={tip} className={`${bg} ${text}`} />
                            </div>;
                        })}
                    </div>
                }
                {mode === "narrow" &&
                    <div className="mt-10 grid grid-cols-4 gap-2">
                        {displayTips.map((tip, index) => {
                            const [bg, text] = bgForIcon(tip.icon, theme);
                            return <div key={index} onClick={() => instructionRef.current?.useTip(tip)} className={`cursor-pointer mx-auto p-4 rounded-lg overflow-hidden h-[9rem] w-[9rem] shadow-md shadow-black hover:scale-105 hover:shadow-black hover:shadow-lg ${text} ${bg}`}>
                                <TipMessage message={tip} className={`${bg} ${text}`} />
                            </div>;
                        })}
                    </div>
                }
                {mode === "wide" &&
                    <div className="mt-10 grid grid-cols-4 gap-4">
                        {displayTips.map((tip, index) => {
                            const [bg, text] = bgForIcon(tip.icon, theme);
                            return <div key={index} onClick={() => instructionRef.current?.useTip(tip)} className={`cursor-pointer mx-auto p-4 rounded-lg overflow-hidden h-[10rem] w-[10rem] shadow-md shadow-black hover:scale-105 hover:shadow-black hover:shadow-lg ${text} ${bg}`}>
                                <TipMessage message={tip} className={`${bg} ${text}`} />
                            </div>;
                        })}
                    </div>
                }
            </ScrollArea>
        </AppPage>
    );
}

export default Dashboard;
