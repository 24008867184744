import React, { memo, useRef } from "react";
import { UploadedFile } from "../../../contexts/Data";
import { ListChildComponentProps, areEqual } from "react-window";

export const Card = memo(({ data, index, style }: ListChildComponentProps) => {
    const { items, handleClick, icon } = data;
    const dataItem = items[index];
    if (!dataItem) {
        return null;
    }
    const height = style.height as number;

    const dynamicStyle = {
        ...style,
        margin: 8,
        marginLeft: 16,
        height: height - 8,
        width: 'calc(100% - 32px)',
    };

    return (<MiniData
        key={`data-${dataItem.id}`}
        handleClick={() => handleClick(dataItem.fileId)}
        card={dataItem}
        style={dynamicStyle}
        icon={icon}
    />);
}, areEqual);

interface MiniDataProps {
    handleClick: () => void;
    card: UploadedFile;
    style: React.CSSProperties
    icon: React.ReactNode;
}

function MiniData({ handleClick, card, style, icon }: MiniDataProps) {
    const ref = useRef<HTMLDivElement>(null);
    return (
        <div ref={ref} style={style} onClick={handleClick} className="card">
            <div className="card__top-bar text-left">
                <div className="card__icon mr-2">
                    {icon}
                </div>
                <div className="card-header">{card.fileName}</div>
            </div>
        </div>
    );
}
