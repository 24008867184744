import ReactTimeAgo from "react-time-ago";
import { Label } from "@/components/ui/label";

export function Overdue({ completed, dueDate, compressed }: { completed: boolean, dueDate: Date | null, compressed?: boolean }) {
    const isOverdue = dueDate && dueDate < new Date();
    const classes = compressed ? "flex items-center" : "w-64 flex m-2 items-center justify-between rounded-lg border p-2";

    if (!completed && isOverdue) {
        return <div className={`${classes} border-red-500 text-red-500`}>
            <Label className="detail-grid__label">Overdue</Label>
            <ReactTimeAgo className="ml-2" date={dueDate} />
        </div>;
    } else if (!completed && dueDate) {
        return <div className={classes}>
            <Label className="detail-grid__label">Due</Label>
            <ReactTimeAgo className="ml-2" date={dueDate} />
        </div>;
    } else {
        return null;
    }
}
