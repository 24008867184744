import { useEffect, useState, useCallback } from "react";

import { FaTasks, FaProjectDiagram } from "react-icons/fa";
import { MilestoneIcon } from "lucide-react";

import { useTask } from "@/contexts/Task";
import { useProjectsContext, useMilestoneContext } from "@/contexts";
import {
    ChangeType,
} from "@/contexts/InstructionContext";
import { Link } from "react-router-dom";
import { Label } from "@/components/ui/label";
import { Project } from "@/contexts/Project";
import { Badge } from "./ui/badge";
import { Skeleton } from "./ui/skeleton";
import { Milestone } from "@/contexts/Milestone";

const renderChangeType = (changeType: ChangeType) => {
    switch (changeType) {
        case "created":
            return "Created";
        case "archived":
            return "Archived";
        default:
            return "Updated";
    }
};

interface TaskMessageProps {
    taskId: string;
    changeType: ChangeType;
}

export function TaskMessage({ taskId, changeType }: TaskMessageProps) {
    const { data: task, isLoading, isError } = useTask(taskId);
    console.log("TaskMessage", taskId, task, isLoading, isError);

    if (isError) {
        return <div>Couldn't load task</div>;
    }

    if (isLoading) {
        return <Skeleton className="mx-1 w-full h-16" />;
    }

    const slug = task?.task.slug;

    return (
        <div className="flex mx-1 p-2 items-center rounded-lg">
            <FaTasks className="mr-4 text-2xl" />
            <Label className="text-lg">{renderChangeType(changeType)}</Label>
            <Link to={`/app/tasks/id/${taskId}`}><Badge className="mx-2 text-lg">{slug}</Badge></Link>
        </div>
    );
}

interface ProjectMessageProps {
    projectId: string;
    changeType: ChangeType;
}

export function ProjectMessage({ projectId, changeType }: ProjectMessageProps) {
    const { getProject } = useProjectsContext();
    const [proj, setProject] = useState<Project | null>(null);
    const [timeoutAmount, setTimeoutAmount] = useState(100);

    const fetchProject = useCallback(async () => {
        getProject(projectId).then((project) => {
            if (project !== null) {
                setProject(project);
            } else {
                setTimeout(fetchProject, timeoutAmount);
                setTimeoutAmount(Math.max(timeoutAmount * 2, 2000));
            }
        });
    }, [getProject, projectId, timeoutAmount]);

    useEffect(() => {
        if (proj === null) {
            fetchProject();
        }
    }, [fetchProject, proj]);

    console.log("ProjectMessage", proj);
    if (proj === null) {
        return null;
    }

    const name = proj.name;
    return (
        <div className="flex mx-1 p-2 items-center rounded-lg">
            <FaProjectDiagram className="mr-4 text-2xl" />
            <Label className="text-lg">{renderChangeType(changeType)}</Label>
            <Link to={`/app/projects/${projectId}`}><Badge className="mx-2 text-lg">{name}</Badge></Link>
        </div>
    );
}

interface MilestoneMessageProps {
    milestoneId: string;
    changeType: ChangeType;
}

export function MilestoneMessage({ milestoneId, changeType }: MilestoneMessageProps) {
    const { getMilestone } = useMilestoneContext();
    const [proj, setMilestone] = useState<Milestone | null>(null);
    const [timeoutAmount, setTimeoutAmount] = useState(100);

    const fetchMilestone = useCallback(async () => {
        getMilestone(milestoneId).then((milestone) => {
            if (milestone !== null) {
                setMilestone(milestone);
            } else {
                setTimeout(fetchMilestone, timeoutAmount);
                setTimeoutAmount(Math.max(timeoutAmount * 2, 2000));
            }
        });
    }, [getMilestone, milestoneId, timeoutAmount]);

    useEffect(() => {
        if (proj === null) {
            fetchMilestone();
        }
    }, [fetchMilestone, proj]);

    console.log("MilestoneMessage", proj);
    if (proj === null) {
        return null;
    }

    const name = proj.name;
    return (
        <div className="flex mx-1 p-2 items-center rounded-lg">
            <MilestoneIcon className="mr-4 text-2xl" />
            <Label className="text-lg">{renderChangeType(changeType)}</Label>
            <Link to={`/app/milestones/${milestoneId}`}><Badge className="mx-2 text-lg">{name}</Badge></Link>
        </div>
    );
}
