import { createContext, useEffect, useState, useCallback } from 'react';
import { ProviderProps } from "./ContextTypes";
import { UserData } from "./User";
import axios from "axios";
import { apiFetch } from '.';

export interface UploadedFile {
    fileId: string;
    fileName: string;
    fileType: string;
    createdAt: string;
    createdBy: UserData;
}

async function uploadFile(filePath: string, fileType: string, file: File): Promise<UploadedFile> {
    const response = await fetch('/api/v1/file', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            filePath,
            fileType,
        }),
    });

    if (!response.ok) {
        throw new Error(`Failed to upload file ${filePath}`);
    }

    const { url, data: uploadedFile, contentType } = await response.json();

    const result = await axios.put(url, file, {
        headers: {
            'Content-Type': contentType,
            'Content-Disposition': `attachment;filename="${uploadedFile.fileName}"`,
        },
    });
    if (result.status !== 200) {
        throw new Error(`Failed to upload file ${filePath}`);
    }
    return uploadedFile;
}

async function getFileById(fileId: string): Promise<UploadedFile> {
    const response = await fetch(`/api/v1/file/${fileId}`, {
        method: 'GET',
    });

    if (!response.ok) {
        throw new Error(`Failed to get file ${fileId}`);
    }
    return await response.json();
}

async function downloadFile(fileId: string): Promise<string> {
    const response = await fetch(`/api/v1/file/${fileId}/download`, {
        method: 'GET',
    });

    if (!response.ok) {
        throw new Error(`Failed to download file ${fileId}`);
    }
    const { url } = await response.json();

    return url;
}

async function getFiles(fileType: string, page: number, perPage: number): Promise<UploadedFile[]> {
    const response: { files: UploadedFile[] } = await apiFetch(
        `/api/v1/file?page=${page}&perPage=${perPage}&fileType=${fileType}`,
        {
            method: 'GET',
        });
    return response.files;
}

async function fetchFiles(
    setFileData: (fileData: FileTypeData) => void,
    fileData: FileTypeData,
    fileType: string) {
    const perPage = 20;
    const newFiles = await getFiles(fileType, fileData.page, perPage);
    const files = [...fileData.files, ...newFiles];
    setFileData({
        files,
        hasNextPage: newFiles.length === perPage,
        page: fileData.page + 1,
    });
}

export interface FileTypeData {
    files: UploadedFile[],
    hasNextPage: boolean,
    page: number,
}

export interface FileTypes {
    attachment: FileTypeData,
    transcript: FileTypeData,
    assistant: FileTypeData,
    document: FileTypeData,
    media: FileTypeData,
}

export interface DataContextProps {
    types: FileTypes,

    loadNextPage: (fileType: string) => void,
    clearCache: () => void,
    downloadFile: (fileId: string) => Promise<string>,
    getFileById: (fileId: string) => Promise<UploadedFile>,
    uploadFile: (filePath: string, fileType: string, file: File) => Promise<UploadedFile>,
}
export const DataContext = createContext<DataContextProps | undefined>(undefined);

function newFileTypeData(): FileTypeData {
    return {
        files: [],
        hasNextPage: true,
        page: 1,
    };
}

const DataProvider: React.FC<ProviderProps> = ({ children }) => {
    const [attachments, setAttachments] = useState<FileTypeData>(newFileTypeData());
    const [transcripts, setTranscripts] = useState<FileTypeData>(newFileTypeData());
    const [assistantFiles, setAssistantFiles] = useState<FileTypeData>(newFileTypeData());
    const [documents, setDocuments] = useState<FileTypeData>(newFileTypeData());
    const [media, setMedia] = useState<FileTypeData>(newFileTypeData());
    const [dataCache, setDataCache] = useState(Date.now());

    const loadNextPage = useCallback((fileType: string) => {
        switch (fileType) {
            case 'attachment':
                fetchFiles(setAttachments, attachments, fileType);
                break;
            case 'transcript':
                fetchFiles(setTranscripts, transcripts, fileType);
                break;
            case 'assistant':
                fetchFiles(setAssistantFiles, assistantFiles, fileType);
                break;
            case 'document':
                fetchFiles(setDocuments, documents, fileType);
                break;
            case 'media':
                fetchFiles(setMedia, media, fileType);
                break;
            default:
                throw new Error(`Unknown file type ${fileType}`);
        }
    }, [attachments, transcripts, assistantFiles, documents, media]);

    useEffect(() => {
        fetchFiles(setAttachments, newFileTypeData(), 'attachment');
        fetchFiles(setTranscripts, newFileTypeData(), 'transcript');
        fetchFiles(setAssistantFiles, newFileTypeData(), 'assistant');
        fetchFiles(setDocuments, newFileTypeData(), 'document');
        fetchFiles(setMedia, newFileTypeData(), 'media');
    }, [dataCache]);

    const value = {
        types: {
            attachment: attachments,
            transcript: transcripts,
            assistant: assistantFiles,
            document: documents,
            media: media,
        },
        loadNextPage,
        clearCache: () => setDataCache(Date.now()),
        downloadFile,
        getFileById,
        uploadFile,
    };

    return (<DataContext.Provider value={value}>
        {children}
    </DataContext.Provider>);
}
export default DataProvider;
