import React from "react";
import { useNavigate } from "react-router-dom";
import { login, useAppContext } from "../contexts";

function ServerDown() {
    const { socketIsConnected, loginAttempted, loggedInUser } = useAppContext();
    const navigate = useNavigate();

    React.useEffect(() => {
        const checkRefresh = () => {
            if (loginAttempted && !loggedInUser) {
                login();
            }

            if (socketIsConnected()) {
                navigate('/');
            }
        };

        checkRefresh();
        const timer = setTimeout(() => {
            checkRefresh();
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [navigate, socketIsConnected, loginAttempted, loggedInUser]);

    return (<div className="text-center w-full m-auto">
        Lost connection to server. Reconnecting...
    </div>);
}
export default ServerDown
