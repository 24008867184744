import { useEffect, useState } from "react";
import { useDataContext } from "../../../contexts";
import { UploadedFile } from "../../../contexts/Data";
import { FaImage, FaNoteSticky, FaPaperclip, FaRobot, FaVoicemail } from 'react-icons/fa6';
import { useNavigate } from "react-router-dom";
import DetailGrid, { DetailContentArea } from "../DetailGrid";
import Personelle from "../../Personelle";
import ReactTimeAgo from "react-time-ago";
import DetailCard from "../DetailCard";

interface DataDetailProps {
    id: string
}

function DataDetail({ id }: DataDetailProps) {
    const { getFileById } = useDataContext();
    const [data, setData] = useState<UploadedFile | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getFileById(id).then((data) => {
            setData(data);
        });
    }, [getFileById, id]);

    const vanish = () => {
        navigate(-1);
    };

    if (!data) {
        return null;
    }
    const createdDate = new Date(data.createdAt + "Z");

    let icon = <FaPaperclip />;
    if (data.fileType == "media") {
        icon = <FaImage />;
    } else if (data.fileType == "transcript") {
        icon = <FaVoicemail />;
    } else if (data.fileType == "assistant") {
        icon = <FaRobot />;
    } else if (data.fileType == "document") {
        icon = <FaNoteSticky />;
    }

    return (
        <DetailCard
            title={data.fileName}
            titleIcon={icon}
            onClose={vanish} >
            <DetailGrid narrow={true}>
                <DetailContentArea>
                    <div className="ml-auto mb-2 w-64 mr-2">
                        <Personelle userData={data.createdBy} tag="Uploaded by" />
                        <div className="flex">
                            <div className="flex-grow" />
                            <ReactTimeAgo className="mr-2" date={createdDate} />
                        </div>
                    </div>
                </DetailContentArea>
            </DetailGrid>
        </DetailCard>
    );
}
export default DataDetail;
