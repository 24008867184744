import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $convertToMarkdownString } from '@lexical/markdown';
import { $isBeautifulMentionNode, BeautifulMentionNode } from 'lexical-beautiful-mentions';
import { TextMatchTransformer, TRANSFORMERS, $convertFromMarkdownString } from '@lexical/markdown';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { USER_CACHE } from "@/contexts/User";
import { LexicalNode } from "lexical";
import { Button } from '@/components/ui/button';
import { useCallback, useEffect, useState } from 'react';

interface MarkdownActionsProps {
    onSaveMarkdown(markdown: string): void;
    markdown: string;
}

const USER_ID_REGEX = /@\{([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\}/;

const MARKDOWN: TextMatchTransformer = {
    dependencies: [BeautifulMentionNode],
    export: (node: LexicalNode) => {
        if (!$isBeautifulMentionNode(node)) {
            return null;
        }
        const beautifulNode = node as BeautifulMentionNode;
        const data = beautifulNode.getData();
        if (!data) return '';
        return `@{${data.id}}`;
    },
    importRegExp: USER_ID_REGEX,
    regExp: USER_ID_REGEX,
    replace: (parentNode, match) => {
        const [, userId] = match;
        const user = USER_CACHE.getUser(userId);
        let nodeData;
        if (user) {
            nodeData = { id: userId, value: user.username };
        } else {
            nodeData = { id: userId, value: 'Unknown User' };
        }
        const mentionNode = new BeautifulMentionNode('@', nodeData.value, nodeData);
        parentNode.replace(mentionNode);
    },
    trigger: '}',
    type: 'text-match',
};

export const TRANSFORMERS_PLUS = [...TRANSFORMERS, MARKDOWN];

export const MarkdownSave = ({ onSaveMarkdown, markdown }: MarkdownActionsProps) => {
    const [editor] = useLexicalComposerContext();
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        editor.update(() => {
            $convertFromMarkdownString(markdown, TRANSFORMERS_PLUS);
        });
        setIsDirty(false);
    }, [markdown, editor]);

    const handleEditorChange = useCallback(() => {
        editor.read(() => {
            const newMarkdown = $convertToMarkdownString(TRANSFORMERS_PLUS);
            setIsDirty(newMarkdown !== markdown);
        })
    }, [markdown, editor]);

    const handleOnClick = useCallback(() => {
        if (!isDirty) return;
        editor.update(() => {
            const newMarkdown = $convertToMarkdownString(TRANSFORMERS_PLUS);
            onSaveMarkdown(newMarkdown);
        });
        setIsDirty(false);
    }, [editor, onSaveMarkdown, isDirty]);

    return (
        <>
            <OnChangePlugin onChange={handleEditorChange} />
            {isDirty ? <Button disabled={false} onClick={handleOnClick} className="flex items-center bg-neutral-300 dark:bg-neutral-700 dark:hover:bg-aquamarine-400 px-2 min-w-12 h-12">Save</Button> : <div className="h-12" />}
        </>
    );
};
