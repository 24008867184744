import { FaCog } from "react-icons/fa";

import AppPage from "@/components/AppPage";
import DetailCard from "@/components/cards/DetailCard";
import SideBar from "./SideBar";
import { Route, Routes, useParams } from "react-router-dom";
import { UserProfile } from "./UserProfile";
import { OrganizationSettings } from "./OrganizationSettings";
import { SubscriptionSettings } from "./SubscriptionSettings";
import { AccountsSettings } from "./AccountsSettings";
import { useState } from "react";

const SettingsWindow = ({setActive}: {setActive: (active: string) => void}) => {
    const { page } = useParams<{ page?: string }>();
    console.log("window", page);
    const active = page || "profile";
    setActive(active);

    if (active === "profile") {
        return <UserProfile />;
    } else if (active === "accounts") {
        return <AccountsSettings />;
    } else if (active === "org") {
        return <OrganizationSettings />;
    } else if (active === "plan") {
        return <SubscriptionSettings />;
    } else {
        return null;
    }
}

const UserSettings = () => {
    const [active, setActive] = useState("profile");

    return <AppPage active="settings" customSideBar={<SideBar active={active} />}>
        <DetailCard
            title="Settings"
            titleIcon={<FaCog className="text-xl mt-1" />}
        >
            <Routes>
                <Route path="/" element={<SettingsWindow setActive={setActive} />} />
                <Route path="/:page" element={<SettingsWindow setActive={setActive} />} />
            </Routes>
        </DetailCard>
    </AppPage >;
};
export default UserSettings;
