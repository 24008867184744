import React from "react";
import { Link } from "react-router-dom";

import { TaskAssociation } from "@/contexts/Task";
import { FaGithub, FaJira } from "react-icons/fa";

interface TaskRepositoryLinkageProps {
    association: TaskAssociation;
}

function associationTypeIcon(associationType: string): React.ReactNode {
    if (associationType == 'jira') {
        return <FaJira className="text-lg" />;
    } else if (associationType == 'github') {
        return <FaGithub className="text-lg" />;
    } else {
        return null;
    }
}

export default function TaskRepositoryLinkage({ association }: TaskRepositoryLinkageProps) {
    return (
        <div className="m-2 flex items-center justify-between rounded-lg border p-4">
            {associationTypeIcon(association.source)}
            <Link to={association.url} className="ml-2 text-lg font-bold">{`${association.source.toUpperCase()} Issue`}</Link>
        </div>
    )
}
