import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { useMemo } from "react";

const PromptSchema = z.object({
    prompt: z.string(),
});

interface UserPromptProps {
    prompt: string;
    onConfirm: (newPrompt: string) => void;
}

export default function UserPrompt({ prompt, onConfirm }: UserPromptProps) {
    const form = useForm<z.infer<typeof PromptSchema>>({
        resolver: zodResolver(PromptSchema),
        defaultValues: useMemo(() => {
            return {
                prompt
            };
        }, [prompt]),
    });

    const onCancel = () => {
        form.reset();
    }

    const onSubmit = (data: z.infer<typeof PromptSchema>) => {
        console.log(data);
        onConfirm(data.prompt);
    }

    return <Form {...form}>
        <form className="width-full" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
                control={form.control}
                name="prompt"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel className="col-span-2">Prompt</FormLabel>
                        <FormControl >
                            <Textarea className='col-span-2' {...field} />
                        </FormControl>
                        <FormDescription>Enter a new prompt</FormDescription>
                    </FormItem>
                )}
            />
            <div className="flex justify-center">
                <Button className="w-1/2 btn m-4" type="submit">Update</Button>
                <Button className="w-1/2 rounded-3xl m-4" onClick={onCancel}>Cancel</Button>
            </div>
        </form>
    </Form>;
}
