import { useCallback, useEffect, useState } from 'react';
import { Card } from './MiniData';
import InfiniteLoader from 'react-window-infinite-loader';
import AppPage from '../../AppPage';
import { useDataContext } from '../../../contexts';
import { FixedSizeList } from 'react-window';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DataDetailsView from './DataDetailsView.tsx';
import { FaImage, FaNoteSticky, FaPaperclip, FaRobot, FaVoicemail } from 'react-icons/fa6';

const BOTTOM_MARGIN = 293 + 400;

interface DataPageProps {
    typeName: "attachment" | "transcript" | "assistant" | "document" | "media";
    icon: React.ReactNode;
}

const DataPage = ({ typeName, icon }: DataPageProps) => {
    const navigate = useNavigate();
    const { types, loadNextPage } = useDataContext();
    const { files, hasNextPage } = types[typeName];

    const isItemLoaded = useCallback((index: number): boolean => {
        return index < files.length;
    }, [files.length]);

    const defaultHeight = useCallback(() => {
        let margin = 0;
        margin += BOTTOM_MARGIN;
        return window.innerHeight - margin;
    }, []);

    const defaultWidth = useCallback(() => {
        if (window.innerWidth < 1300) {
            return 225;
        }
        return 450;
    }, []);

    const [height, setHeight] = useState(defaultHeight());
    const [width, setWidth] = useState(defaultWidth());

    useEffect(() => {
        const resizeListener = () => {
            setHeight(defaultHeight());
            setWidth(defaultWidth());
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, [defaultHeight, defaultWidth]);

    const itemData = {
        items: files,
        icon,
        handleClick: (id: string) => navigate(`/app/data/${id}`),
    };
    const header = typeName == "media" ? "Media" :
        typeName == "assistant" ? "Chat Files" :
            typeName.charAt(0).toUpperCase() + typeName.slice(1) + "s";

    return <div className="flex-col m-2">
        <h1 className="text-2xl font-bold text-center">{header}</h1>
        <div className="border border-gray-300 rounded-lg">
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={hasNextPage ? files.length + 1 : files.length}
                loadMoreItems={() => loadNextPage(typeName)}
            >
                {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                        height={height}
                        width={width}
                        itemSize={50}
                        itemData={itemData}
                        itemCount={files.length}
                        onItemsRendered={onItemsRendered}
                        ref={ref}>
                        {Card}
                    </FixedSizeList>
                )}
            </InfiniteLoader>
        </div>
    </div>
};

const DataWindow: React.FC = () => {
    return <AppPage active="data">
        <div className="flex-col">
            <div className="flex w-1/2">
                <DataPage typeName="attachment" icon={<FaPaperclip />} />
                <DataPage typeName="transcript" icon={<FaVoicemail />} />
            </div>
            <div className="flex w-1/2">
                <DataPage typeName="assistant" icon={<FaRobot />} />
                <DataPage typeName="document" icon={<FaNoteSticky />} />
            </div>
            <div className="flex w-1/2">
                <DataPage typeName="media" icon={<FaImage />} />
            </div>
        </div>
        <Routes>
            <Route path=":id" element={<DataDetailsView />} />
        </Routes>
    </AppPage>;
};

export default DataWindow;
