import { createContext, useEffect, useState, useCallback } from 'react';
import { ProviderProps } from "./ContextTypes";
import { apiFetch, apiFetchNoContent, eventEmitter } from '.';
import { style } from "typestyle";
import { UserData } from './User';
import { Project } from './Project';

export interface Window {
    start: number;
    end: number;
}
export interface RepeatedBound {
    "n": number;
    "bound": Window;
}
export type Bound = { "lower": number } | { "upper": number };
export type Constraint = { "hardConstraint": Bound } | { "repeatedBlock": RepeatedBound } | { "block": Window };

export interface ChartLink {
    id: string;
    linkType: string;
}

export interface Task {
    id: string;
    slug: string;
    titles: string[];
    priority: number;
    attention: number;
    requirements: number[];
    segments: EventSegment[];
    open: boolean;
    assigned: string;

    actualStart?: number;
    actualDuration?: number;
    dueDate?: number;
    dependencies?: ChartLink[];
    parent?: string;
    children?: string[];
    color?: string;
}

export const ganttChartStyle = style({
    width: "100%",
    height: "calc(100% - 8rem)",
    display: "flex",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    $nest: {
        canvas: {
            width: "100%",
            height: "100%",
        },
    },
});

export interface Milestone {
    id: string;
    name: string;
    deadline: number;
    start: number;
    color?: string;
}

export interface EventSegment {
    eventId: string;
    segmentId: string;
    start: Date;
    end: Date;
    lane?: number;
}

export interface SchedulerEventSegment {
    eventId: string;
    segmentId: string;
    start: string;
    end: string;
}

export interface SchedulerTask {
    id: string;
    lane: number;
    slug: string;
    titleSummaries: string[];
    state: string;
    priority: number;
    attention: number;
    assignee?: string;

    expectedStart: string;
    actualStart?: string;
    expectedEnd: string;
    actualEnd?: string;

    constraints: Constraint[];
    dependencies: string[];
    parent?: string;
    children: string[];
    dueDate?: string;
    segments: SchedulerEventSegment[];

    color?: string;
}

export interface ApiChartTask {
    taskId: string;
    title: string;
    titleSummaries: string[];
    slug: string;
    state: string;
    priority: number;
    attention: number;
    row: number;

    assignee: UserData | null;
    expectedStart: string;
    actualStart: string | null;
    expectedDuration: number;
    actualDuration: number | null;

    dependencies: string[];
    children: string[];
    segments: EventSegment[];
    dueDate: string | null;
}

export interface ChartTask {
    taskId: string;
    lane: number;
    slug: string;
    titleSummaries: string[];
    state: string;
    priority: number;
    attention: number;
    assignee?: string;

    expectedStart: Date;
    actualStart?: Date;
    expectedEnd: Date;
    actualEnd?: Date;

    constraints: Constraint[];
    dependencies: string[];
    parent?: string;
    children: string[];
    dueDate?: Date;
    segments: EventSegment[];

    color?: string;
}

export interface ChartProblem {
    eventId: string;
    problem: string;
}

export interface Worker {
    id: string,
    username: string,
    constraints: Constraint[],
    imageId?: string,
}

export interface ChartPlan {
    planId: string;
    completed: boolean;
    completedOn?: string;
    error?: string;
    seed: string;
    tasks: ApiChartTask[];
    workers: Worker[];

    startHour: number;
    endHour: number;

    problems: ChartProblem[];
    utilization: Record<string, number>;

    workStart: number;
    workEnd: number;
}

export interface Utilization {
    workerId: string;
    utilizationRate: number;
}

export interface ChartPlanMetadata {
    projectIds: string[];
    startDate: string;
    timeZone: string;
    workingDays: boolean[];
    problems: ChartProblem[];
    utilization: Utilization[];
}

export interface ChartArgs {
    planId: string;
    orgId: string;
    createdAt: string;
    createdBy: UserData;
    projects: Project[];
    metadata: ChartPlanMetadata;
    processingFinishedAt?: string;
}

export interface CreateChart {
    projectIds: string[];
    startDate: string;
    timeZone: string;
    workingDays: boolean[];
    workingStart: number;
    workingEnd: number;
    name: string;
    isPublic: boolean;
}

interface NewPlan {
    planId: string;
}

async function createChart(create: CreateChart): Promise<NewPlan> {
    return await apiFetch("/api/v1/plan", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(create),
    });
}

export function titlesFromTask(state: ApiChartTask): string[] {
    if (state.titleSummaries.length > 0) {
        return state.titleSummaries;
    }
    return [state.slug];
}

export function loadImage(url: string): Promise<[Uint8Array, number, number]> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            if (!ctx) {
                reject(new Error("Failed to create canvas context"));
                return;
            }
            ctx.drawImage(img, 0, 0);
            const data = ctx.getImageData(0, 0, img.width, img.height);
            resolve([new Uint8Array(data.data), img.width, img.height]);
        };
        img.onerror = (error) => {
            reject(error);
        };
        img.crossOrigin = "anonymous";
        img.src = url;
    });
}



async function getCharts(): Promise<ChartArgs[]> {
    return await apiFetch("/api/v1/plan/list", { method: "GET" });
}

async function getChart(planId: string): Promise<ChartPlan> {
    return await apiFetch(`/api/v1/plan/${planId}`, { method: "GET" });
}

async function deleteChart(planId: string): Promise<boolean> {
    try {
        await apiFetchNoContent(`/api/v1/plan/${planId}`, { method: "DELETE" });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export type ChartTaskUpdate = { "expected": { "start": string, "end": string } } |
{ "segments": { "segments": { "start": string, "end": string }[], "today": string, "units": string } } |
{ "assignee": string };

async function updateChartTask(planId: string, taskId: string, update: ChartTaskUpdate) {
    console.log("Update chart task", update);
    await apiFetchNoContent(`/api/v1/plan/${planId}/task/${taskId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(update),
    });
}

export interface ChartContextProps {
    charts: ChartArgs[];
    createChart: (create: CreateChart) => Promise<string>;
    deleteChart: (planId: string) => Promise<void>;
    getChart: (planId: string) => Promise<ChartPlan>;
    updateChartTask: (planId: string, taskId: string, update: ChartTaskUpdate) => Promise<void>;
}

export const ChartContext = createContext<ChartContextProps | undefined>(undefined);

const ChartProvider: React.FC<ProviderProps> = ({ children }) => {
    const [charts, setCharts] = useState<ChartArgs[]>([]);

    useEffect(() => {
        getCharts().then(setCharts).catch(() => { });
    }, []);

    const createChartCb = useCallback(async (create: CreateChart) => {
        const newPlan = await createChart(create);
        const charts = await getCharts();
        setCharts(charts);
        return newPlan.planId;
    }, []);

    const deleteChartCb = useCallback(async (planId: string) => {
        if (await deleteChart(planId)) {
            const charts = await getCharts();
            setCharts(charts);
        } else {
            eventEmitter.emit("ERROR", "Failed to delete chart");
        }
    }, []);

    const getChartCb = useCallback(async (planId: string) => {
        return await getChart(planId);
    }, []);

    const updateChartTaskCb = useCallback(async (planId: string, taskId: string, update: ChartTaskUpdate) => {
        await updateChartTask(planId, taskId, update)
    }, []);

    const value = {
        charts,
        createChart: createChartCb,
        getChart: getChartCb,
        deleteChart: deleteChartCb,
        updateChartTask: updateChartTaskCb,
    };

    return (<ChartContext.Provider value={value}>
        {children}
    </ChartContext.Provider>);
}
export default ChartProvider;
