import { Label } from '@/components/ui/label';
import { InfoIcon } from "@/components/Floater";

export interface PlannedTask {
    taskId: string;
    expectedAssigneeId: string | null;
    expectedStart: string;
    expectedDuration: number;
    assignmentDependency: string | null;
    metadata: unknown;

    actualStart: string | null;
    actualDuration: number | null;

    actualAssigneeId: string | null;
    plannedStart: string;
    plannedDuration: number;
    parentTaskId: string | null;
}

interface PlannedTaskProps {
    plan: PlannedTask;
}

function expectedDuration(plan: PlannedTask): number {
    return Math.round(plan.expectedDuration / 60 / 60);
}

function plannedDuration(plan: PlannedTask): number {
    return Math.round(plan.plannedDuration / 60 / 60);
}

const PlannedTaskDisplay = ({ plan }: PlannedTaskProps) => {
    const expectedDurationDays = expectedDuration(plan);
    const plannedDurationDays = plannedDuration(plan);
    const content = (
        <>
            <span>Estimated duration is the automatically estimated time this task will take.</span>
            <br />
            <span>Planned duration is the current time this task takes up in the plan.</span>
        </>
    );

    return (
    <div className="m-2 flex items-center justify-between rounded-lg border p-4">
        <div className="flex flex-col">
            <Label>Estimated duration: {plannedDurationDays} hours</Label>
            <Label>Planned duration: {expectedDurationDays} hours</Label>
        </div>
        <InfoIcon
            content={content}
            className="text-sm omnibar__icon"
        />
    </div>);
};

export default PlannedTaskDisplay;
