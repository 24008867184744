import { toast } from 'sonner';
import { FaDownload, FaFile, FaTrash, FaUpload } from 'react-icons/fa6';
import { useDataContext } from '@/contexts';
import { UploadedFile } from '@/contexts/Data';

function uploadMultipleFiles(
    files: FileList,
    fileType: string,
    uploadFile: (filePath: string, fileType: string, file: File) => Promise<UploadedFile>,
    addUploadedList?: (data: UploadedFile) => void
) {
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        uploadFile(file.name, fileType, file).then((data) => {
            toast.success(`Uploaded ${file.name}`);
            if (addUploadedList) {
                addUploadedList(data);
            }
        }).catch(() => {
            toast.error(`Failed to upload ${file.name}`);
        });
    }
}

interface UploadFilesProps {
    fileType: string;
    accept?: string;
    iconOnly?: boolean;
    addUploadedList?: (data: UploadedFile) => void;
}

export function UploadFileButton({ fileType, accept, iconOnly, addUploadedList }: UploadFilesProps) {
    const { uploadFile } = useDataContext();

    const handleFileSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            uploadMultipleFiles(e.target.files, fileType, uploadFile, addUploadedList);
        }
    };

    const icon = iconOnly ?
        <label className="btn flex items-center mx-2" htmlFor="upload-files">
            <FaUpload />
        </label> :
        <label className="image-upload__button flex justify-center items-center" htmlFor="upload-files">
            <FaUpload className="mr-2" />
            Upload
        </label>;

    return (
        <form id="UploadFiles">
            <input id="upload-files" className="hidden" accept={accept} onChange={(e) => handleFileSubmit(e)} type="file" style={{ display: 'none' }} multiple />
            {icon}
        </form>
    );
}

export function DowloadFileButton({ fileId }: { fileId: string }) {
    const { downloadFile } = useDataContext();
    const handleDownload = () => {
        downloadFile(fileId).then((url) => {
            window.open(url, '_blank');
        }).catch(() => {
            toast.error(`Failed to download file ${fileId}`);
        });
    };

    return (
        <button className="btn btn-primary items-center" onClick={handleDownload}>
            <FaDownload className="mr-2" />
            Download
        </button>
    );
}

interface UploadFileListProps {
    uploadedList: UploadedFile[];
    removeUploadedList: (fileId: string) => void;
}

export function UploadFileList({ uploadedList, removeUploadedList }: UploadFileListProps) {
    return (
        <div className="flex flex-col">
            {uploadedList.map((file) => (
                <div key={file.fileId} className="flex items-center justify-between">
                    <FaFile />
                    <span className="mx-2">{file.fileName}</span>
                    <button className="btn btn-danger" onClick={() => removeUploadedList(file.fileId)}>
                        <FaTrash />
                    </button>
                </div>
            ))}
        </div>
    );
}
