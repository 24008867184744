import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    MouseEvent as ReactMouseEvent,
    forwardRef
} from 'react';
import {
    DndProvider,
    DropTargetMonitor,
    useDrag,
    useDrop,
} from 'react-dnd';
import { v4 as uuid, NIL } from 'uuid';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';
import { convertRemToPixels } from '@/utils/tools';
import Floater from '@/components/Floater';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Label } from '@/components/ui/label';
import { MdDragHandle } from 'react-icons/md';
import { ChartTask, EventSegment, SchedulerEventSegment, SchedulerTask, Worker } from '@/contexts/Chart';
import { Avatar } from '@/components/ui/avatar';
import init, { Scheduler, build } from '@subseq/planner-wasm';
import { Toaster, toast } from 'sonner';
import { AvatarFallback, AvatarImage } from '@radix-ui/react-avatar';

import { useAppContext, useChartContext, useMilestoneContext } from '@/contexts';
import { useParams } from 'react-router-dom';
import { cache } from '@/contexts';

let WASM_INIT: boolean | null = null;

interface CalendarMilestone {
    id: string;
    name: string;
    deadline: Date;
    start: Date;
    color?: string;
}

// Helper to get the start of the day (00:00:00)
function startOfDay(date: Date): Date {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
}

function nextDay(date: Date): Date {
    const d = new Date(date);
    d.setDate(d.getDate() + 1);
    d.setHours(0, 0, 0, 0);
    return d;
}

interface DayData {
    date: Date;
    events: ChartTask[];
}

const ITEM_TYPE = 'CALENDAR_EVENT';

const HOURS_PER_DAY = 24;
const HOUR_HEIGHT = 60;        // 60px per hour
const DAY_HEADER_HEIGHT = 30;  // 30px day header

// Color palette
const CERULEAN = "#4281A4";
const ULTRAVIOLET = "#54428E";

function doEventsOverlap(a: ChartTask, b: ChartTask): boolean {
    if (a.assignee !== b.assignee) {
        return false;
    }
    return a.expectedStart < b.expectedEnd && b.expectedStart < a.expectedEnd;
}

// ----------------- Drag Items -----------------
interface DragItem {
    type: typeof ITEM_TYPE;
    segment: EventSegment;
}

// ----------------- Event Box (Drag Source) -----------------

interface EventBoxProps {
    segment: EventSegment;
    event: ChartTask;
    assignees: Worker[];
    topPx: number;
    heightPx: number;
    leftPercent: number;
    widthPercent: number;
    onResize: (eventId: string, newEnd: Date) => void;
}

const EventSegmentBox: React.FC<EventBoxProps> = ({
    segment,
    event,
    assignees,
    topPx,
    heightPx,
    leftPercent,
    widthPercent,
    onResize
}) => {
    const { theme } = useAppContext();

    const [{ isDragging }, dragRef] = useDrag<DragItem, void, { isDragging: boolean }>({
        type: ITEM_TYPE,
        item: { type: ITEM_TYPE, segment },
        collect: (monitor) => ({ isDragging: monitor.isDragging() })
    });

    useEffect(() => {
        if (isDragging) {
            document.body.classList.add("cursor-grabbing");
        } else {
            document.body.classList.remove("cursor-grabbing");
        }
        return () => {
            document.body.classList.remove("cursor-grabbing");
        }
    }, [isDragging]);

    const [eventId, setEventId] = useState<string | null>(null);

    function handleResizeMouseDown(e: ReactMouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        setEventId(segment.eventId);
        startYRef.current = e.clientY;
        startEndDateRef.current = new Date(segment.end);
    }

    // ---- BOTTOM RESIZE HANDLE LOGIC ----
    const startYRef = useRef<number>(0);
    const startEndDateRef = useRef<Date>(segment.end);

    useEffect(() => {
        function onMouseMove(e: MouseEvent) {
            if (!eventId) return;
            // Delta in px from the initial mouse-down
            const deltaPx = e.clientY - startYRef.current;
            // Convert px -> hours
            const deltaHours = deltaPx / HOUR_HEIGHT;

            // Construct the new end date by adding deltaHours
            const newEnd = new Date(startEndDateRef.current);
            const newHours = newEnd.getHours() + deltaHours;
            newEnd.setHours(newHours);
            // Optional: update live UI if you want a live-resize look 
            // But typically you might re-render the event from a parent data structure.
            onResize(segment.eventId, newEnd);
        }

        function onMouseUp(e: MouseEvent) {
            if (!eventId) return;
            setEventId(null);

            // Final delta
            const deltaPx = e.clientY - startYRef.current;
            const deltaHours = deltaPx / HOUR_HEIGHT;
            const finalEnd = new Date(startEndDateRef.current);
            const newHours = finalEnd.getHours() + deltaHours;
            finalEnd.setHours(newHours);
            document.body.classList.remove("cursor-ns-resize");
        }

        if (eventId) {
            document.body.classList.add("cursor-ns-resize");
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
            return () => {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            };
        }
    }, [eventId, onResize, segment.eventId]);

    // Tailwind + minimal inline for position/color
    const eventClasses = `absolute cursor-grab mx-2 card`;

    const isDraggingOrResizing = isDragging || eventId === segment.eventId;
    const heightRem = heightPx === HOUR_HEIGHT ? "0rem" : "0.25rem";
    const username = assignees.find((worker) => worker.id === event.assignee)?.username;

    return (
        <Floater content={`Start: ${segment.start.toLocaleString()} - End: ${segment.end.toLocaleString()} assignee: ${username}`}>
            <div
                className={eventClasses}
                style={{
                    top: `calc(${topPx}px + ${heightRem})`,
                    left: `${leftPercent}%`,
                    width: `calc(${widthPercent}% - 1rem)`,
                    height: `calc(${heightPx}px - ${heightRem})`,
                    backgroundColor: theme === "dark" ? ULTRAVIOLET : CERULEAN,
                    opacity: isDraggingOrResizing ? 0.5 : 1
                }}
            >
                <div ref={dragRef} className="h-full">
                    <div className="card__top-bar--uncolored">
                        <div className="card-header">{event.titleSummaries.length > 0 ? event.titleSummaries[0] : event.slug}</div>
                    </div>
                </div>
                <div
                    className="absolute bottom-0 left-0 w-full h-2 cursor-ns-resize bg-transparent justify-center"
                    onMouseDown={handleResizeMouseDown}>
                    <MdDragHandle className="w-full h-2" />
                </div>
            </div>
        </Floater>
    );
}

const LeftHourGutter: React.FC<{ startHour: number, endHour: number }> = ({ startHour, endHour }) => {
    const hours = [];
    for (let i = startHour; i < endHour; i++) {
        hours.push(i);
    }
    const firstHour = hours[0];

    return (
        <div
            className="relative w-16 border-r border-gray-300"
            style={{ height: hours.length * HOUR_HEIGHT }}
        >
            {hours.map((hour) => {
                let classes = "border-b border-gray-200";
                if (hour === firstHour) {
                    classes = "border-t border-b border-gray-200";
                } else if (hour === HOURS_PER_DAY - 1) {
                    classes = "";
                }
                return <div
                    key={hour}
                    className={`relative p-2 pt-4 items-center justify-center ${classes}`}
                    style={{ height: HOUR_HEIGHT }}
                >
                    {hour.toString().padStart(2, '0')}:00
                </div>;
            })}
        </div>
    );
};

const CALENDAR_HOVER_POSITIONS: Record<string, EventSegment> = {};

interface DayColumnProps {
    dayData: DayData;
    milestones: CalendarMilestone[];
    assignees: Worker[];
    today: Date;
    startHour: number;
    endHour: number;

    scrollToToday: () => void;
    onEventAssigneeChange: (eventId: string, newAssigneeId: string) => void;
    onEventTimeChange: (eventId: string, newStart: Date) => void;
    onEventDurationChange: (eventId: string, newEnd: Date) => void;
}

const DayColumn = forwardRef<HTMLDivElement, DayColumnProps>((props, todayRef) => {
    const { dayData, milestones, assignees, today, startHour, endHour, scrollToToday, onEventAssigneeChange, onEventTimeChange, onEventDurationChange } = props;
    const { date, events } = dayData;
    const [highlightHour, setHighlightHour] = useState<number | null>(null);
    const [highlightLane, setHighlightLane] = useState<number | null>(null);

    const hours: number[] = [];
    for (let i = startHour; i < endHour; i++) {
        hours.push(i);
    }

    const moveItem = (item: DragItem, monitor: DropTargetMonitor, zone: string) => {
        if (!ref.current) {
            return;
        }
        const clientOffset = monitor.getClientOffset();
        if (!clientOffset) {
            return;
        }

        const rect = ref.current.getBoundingClientRect();
        // The day header is 30px, so subtract that:
        const offsetY = clientOffset.y - rect.top - DAY_HEADER_HEIGHT;

        // Convert offsetY to hour
        let hour = Math.round(offsetY / HOUR_HEIGHT) + 9;
        if (hour < hours[0]) hour = hours[0];
        if (hour >= hours[hours.length - 1]) hour = hours[hours.length - 1];

        const lane = Math.floor((clientOffset.x - rect.left) / (rect.width / assignees.length));

        const newDate = new Date(date);
        newDate.setHours(hour, 0, 0, 0);

        if (zone === "hover") {
            setHighlightHour(hour);
            setHighlightLane(lane);
            return;
        } else {
            setHighlightHour(null);
            setHighlightLane(null);
        }

        const currentPosition = CALENDAR_HOVER_POSITIONS[item.segment.segmentId];
        if (currentPosition) {
            const oldDate = new Date(currentPosition.start.getTime());
            oldDate.setHours(oldDate.getHours(), 0, 0, 0); // round to hour

            if (newDate.getTime() === oldDate.getTime()) {
                return;
            }
        }
        if (lane != item.segment?.lane) {
            const assignee = assignees[lane];
            onEventAssigneeChange(item.segment.eventId, assignee.id);
        }
        onEventTimeChange(item.segment.eventId, newDate);
    }

    // Set up react-dnd drop zone for the entire day column
    const ref = useRef<HTMLDivElement>(null);
    const [{ isOver }, drop] = useDrop<DragItem, void, { isOver: boolean }>({
        accept: 'CALENDAR_EVENT',
        hover: (item, monitor) => moveItem(item, monitor, "hover"),
        drop: (item, monitor) => moveItem(item, monitor, "drop"),
        collect: (monitor) => ({ isOver: monitor.isOver() })
    });
    drop(ref);

    useEffect(() => {
        if (!isOver) {
            setHighlightHour(null);
        }
    }, [isOver]);
    const totalLanes = events.reduce((acc, e) => Math.max(acc, e.lane), 0) + 1;

    const segments = [];
    const overlappingSegments: Record<string, Array<string>> = {};
    const firstOverlap: Record<string, string> = {};

    for (const event of events) {
        for (const segment of event.segments) {
            const dayStart = startOfDay(date);
            const dayEnd = nextDay(date);
            if (segment.start >= dayStart && segment.end <= dayEnd) {
                segments.push({ segment, event, totalLanes });
            }
        }
    }

    for (const segment of segments) {
        const segmentId = segment.segment.segmentId;
        if (firstOverlap[segmentId] !== undefined) {
            continue;
        }

        for (const otherSegment of segments) {
            const otherSegmentId = otherSegment.segment.segmentId;
            if (segmentId === otherSegmentId) {
                continue;
            }
            if (doEventsOverlap(segment.event, otherSegment.event)) {
                if (overlappingSegments[segmentId] === undefined) {
                    overlappingSegments[segmentId] = [segmentId];
                    firstOverlap[segmentId] = segmentId;
                }
                if (firstOverlap[otherSegmentId] === undefined) {
                    firstOverlap[otherSegmentId] = segmentId;
                }
                overlappingSegments[segmentId].push(otherSegmentId);
            }
        }
    }

    // 1440px = 24 hours * 60 minutes
    // 4.5rem = 72px for the day header (my-4 + py-2 + text), not sure why it's 4.5rem
    // 80 px for portraits
    // 1px for the border
    const scrollAmount = (endHour - startHour) * HOUR_HEIGHT + convertRemToPixels(4.5) + 80 + 1;

    const scrollTo = (day: number) => {
        window.scrollBy({ top: day * scrollAmount, behavior: 'smooth' });
    };

    const jumpToDay = (date: Date, startDate: Date) => {
        const millisecondsInDay = 1000 * 60 * 60 * 24;
        const dayDiff = Math.round((date.getTime() - startDate.getTime() + millisecondsInDay) / (millisecondsInDay));
        scrollTo(dayDiff);
    };

    let refHook = undefined;
    if (dateSame(date, today)) {
        refHook = todayRef;
    }

    const dayHeight = (endHour - startHour) * HOUR_HEIGHT;
    return (
        <div>
            <div ref={refHook}>
                <div className="flex items-center">
                    <Floater content="Scroll to today">
                        <Label className="m-4 btn cursor-pointer" onMouseDown={scrollToToday}>Today</Label>
                    </Floater>
                    <Popover>
                        <PopoverTrigger className="flex items-center mr-2">
                            <CalendarIcon className="mr-2 w-4 h-4" />
                            <Label className="text-lg cursor-pointer underline text-cerulean-600 dark:text-aquamarine-400">{date.toDateString()}</Label>
                        </PopoverTrigger>
                        <PopoverContent className="w-[365px]">
                            <Calendar
                                required={true}
                                mode="single"
                                defaultMonth={date}
                                selected={date}
                                onSelect={(newDate) => jumpToDay(newDate, date)}
                            />
                        </PopoverContent>
                    </Popover>
                    <Floater content="Back one day">
                        <Label className="m-1 w-8 h-8 p-0 rounded-full cursor-pointer" onClick={() => scrollTo(-1)}><ChevronLeft /></Label>
                    </Floater>
                    <Floater content="Forward one day">
                        <Label className="m-1 w-8 h-8 p-0 rounded-full cursor-pointer" onClick={() => scrollTo(1)}><ChevronRight /></Label>
                    </Floater>
                    <div className="ml-4 flex">
                        {milestones.map((m) => {
                            let pointedClass = "pointed-both";
                            if (m.start.getDate() === date.getDate()) {
                                pointedClass = "pointed-right";
                            }
                            if (m.deadline.getDate() === date.getDate()) {
                                pointedClass = "pointed-left";
                            }
                            return <div key={m.id} className={`mx-1 arrow-box ${pointedClass} items-center text-center p-1 text-sm w-48`}>
                                {m.name}
                            </div>;
                        })}
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <div className="w-[64px] text-center">Team</div>
                    {assignees.map((assignee) => {
                        return <div key={assignee.id}
                            className={`flex flex-col jusitfy-center items-center my-2`}
                            style={{ width: `calc(100% / ${assignees.length})` }}
                        >
                            <Floater content={assignee.username}>
                                <Avatar className="w-16 h-16">
                                    <AvatarImage src={assignee.imageId} alt="portrait" />
                                    <AvatarFallback>{assignee.username}</AvatarFallback>
                                </Avatar>
                            </Floater>
                        </div>;
                    })}
                </div>
            </div>
            <div
                ref={ref}
                className="grid grid-cols-[auto_1fr] border-b border-gray-300 relative"
            >
                <LeftHourGutter startHour={startHour} endHour={endHour} />
                <div className="relative flex flex-col w-full">
                    <div className="absolute top-0 w-full h-full grid border-l border-gray-300"
                        style={{
                            gridTemplateColumns: `repeat(${totalLanes}, 1fr)`, // Ensures equal column widths
                            gridTemplateRows: `repeat(${hours.length}, ${HOUR_HEIGHT}px)`, // Equal row heights
                        }}
                    >
                        {hours.map((hour, rowIndex) => (
                            Array.from({ length: totalLanes }).map((_, colIndex) => {
                                const isHighlighted = highlightHour === hour && highlightLane === colIndex;
                                return (
                                    <div
                                        key={`${hour}-${colIndex}`}
                                        className={`w-full h-full ${isHighlighted ? 'bg-cerulean-300 dark:bg-aquamarine-400' : ''}`}
                                        style={{ gridColumn: colIndex + 1, gridRow: rowIndex + 1 }}
                                    />
                                );
                            })
                        ))}
                    </div>
                    <div className={`relative h-[${dayHeight}px] select-none`}>
                        {segments.map(({ segment, event, totalLanes }) => {
                            CALENDAR_HOVER_POSITIONS[segment.segmentId] = segment;
                            segment.lane = event.lane;

                            const primaryOverlap = firstOverlap[segment.segmentId];
                            const numOverlapping = primaryOverlap ? overlappingSegments[primaryOverlap].length : 0;
                            const subLane = primaryOverlap ? overlappingSegments[primaryOverlap].indexOf(segment.segmentId) : 0;

                            const laneWidthPercent = 100 / totalLanes;
                            const widthPercent = numOverlapping > 0 ? laneWidthPercent / numOverlapping : laneWidthPercent;
                            let leftPercent = event.lane * laneWidthPercent;
                            leftPercent += (subLane * widthPercent);

                            const segmentStartHour = segment.start.getHours();
                            const segmentEndHour = segment.end.getHours() ? segment.end.getHours() : 24;

                            const topPx = (segmentStartHour - startHour) * HOUR_HEIGHT;
                            const heightPx = (segmentEndHour - segmentStartHour) * HOUR_HEIGHT;

                            return (
                                <EventSegmentBox
                                    key={segment.eventId}
                                    segment={segment}
                                    assignees={assignees}
                                    event={event}
                                    topPx={topPx}
                                    heightPx={heightPx}
                                    leftPercent={leftPercent}
                                    widthPercent={widthPercent}
                                    onResize={onEventDurationChange}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
});

const dateSame = (a: Date, b: Date) => (
    a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear()
);

const dateBeforeEq = (a: Date, b: Date) => (
    a.getDate() <= b.getDate() && a.getMonth() <= b.getMonth() && a.getFullYear() <= b.getFullYear()
);

const dateAfterEq = (a: Date, b: Date) => (
    a.getDate() >= b.getDate() && a.getMonth() >= b.getMonth() && a.getFullYear() >= b.getFullYear()
);

const schedulerTasks = (schedulerTasks: SchedulerTask[]): ChartTask[] => {
    const tasks = [];
    for (const t in schedulerTasks) {
        const task: SchedulerTask = schedulerTasks[t];
        const segments = task.segments.map((s: SchedulerEventSegment) => ({
            eventId: task.id,
            segmentId: uuid(),
            start: new Date(s.start),
            end: new Date(s.end)
        }));
        tasks.push({
            taskId: task.id,
            lane: task.lane,
            slug: task.slug,
            titleSummaries: task.titleSummaries,

            state: task.state,
            priority: task.priority,
            attention: task.attention,
            assignee: task.assignee,

            expectedStart: new Date(task.expectedStart),
            actualStart: task.actualStart ? new Date(task.actualStart) : undefined,
            expectedEnd: new Date(task.expectedEnd),
            actualEnd: task.actualEnd ? new Date(task.actualEnd) : undefined,

            constraints: task.constraints,
            dependencies: task.dependencies,
            parent: task.parent,
            children: task.children,
            dueDate: task.dueDate ? new Date(task.dueDate) : undefined,
            segments
        });
    }
    return tasks;
}

export const CalendarView: React.FC = () => {
    const { milestones } = useMilestoneContext();
    const calendarMilestones = milestones.map((m) => ({
        id: m.id,
        name: m.name,
        deadline: new Date(m.dueDate),
        start: new Date(m.startDate),
        color: "blue"
    }));

    const { getChart, updateChartTask } = useChartContext();

    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const [today] = useState(now);

    const calendarRef = useRef<HTMLDivElement>(null);

    // Used to seed the initial chart display
    const [tasks, setTasks] = useState<ChartTask[]>([]);
    const [workerConstraints, setWorkerConstraints] = useState<Worker[] | null>(null);
    const [events, setEvents] = useState<ChartTask[]>([]);
    const [startHour, setStartHour] = useState<number>(0);
    const [endHour, setEndHour] = useState<number>(24);

    const [days, setDays] = useState<DayData[]>([]);
    const [scheduler, setScheduler] = useState<null | Scheduler>(null);

    const [lastAttemptedMove,] = useState<Record<string, Date>>({});
    const [lastAttemptedResize,] = useState<Record<string, Date>>({});

    const [wasmInitState, setWasmInit] = useState<null | boolean>(null);
    const id = useParams().id;

    useEffect(() => {
        const workers = workerConstraints || [];
        for (const user of workers) {
            if (!user.imageId) {
                cache.getPortrait(user.id).then((portrait) => {
                    user.imageId = portrait;
                });
            }
        }
    }, [workerConstraints]);

    useEffect(() => {
        if (!id) {
            return;
        }

        getChart(id).then((chart) => {
            console.log("Chart", id, chart);
            const workers = chart.workers;
            setWorkerConstraints(workers);

            const tasks: ChartTask[] = chart.tasks.map((t) => {
                const startTime = new Date(t.expectedStart);
                const actualStart = t.actualStart ? new Date(t.actualStart) : undefined;
                const actualEnd = t.actualDuration ? (
                    actualStart ? (new Date(actualStart.getTime() + t.actualDuration * 1000)) :
                        undefined) : undefined;

                const assigneeLane = t.assignee ? workers.findIndex((u) => u.id === t.assignee?.id) : 0;

                return {
                    taskId: t.taskId,
                    lane: assigneeLane,
                    slug: t.slug,
                    titleSummaries: t.titleSummaries,

                    state: t.state,
                    priority: t.priority,
                    attention: t.attention,
                    assignee: t.assignee ? t.assignee.id : NIL,

                    expectedStart: startTime,
                    actualStart: t.actualStart ? new Date(t.actualStart) : undefined,
                    expectedEnd: new Date(startTime.getTime() + t.expectedDuration * 1000),
                    actualEnd,

                    constraints: [],
                    dependencies: t.dependencies,
                    children: t.children,
                    dueDate: t.dueDate ? new Date(t.dueDate) : undefined,
                    segments: []
                };
            });
            setTasks(tasks);
            setStartHour(chart.workStart);
            setEndHour(chart.workEnd);
        });
    }, [id, getChart]);

    useEffect(() => {
        if (wasmInitState !== null) {
            return;
        }

        if (WASM_INIT) {
            setWasmInit(true);
            return;
        } else if (WASM_INIT === null) {
            setWasmInit(false);
            WASM_INIT = false;
            init().then(() => {
                WASM_INIT = true;
                setWasmInit(true);
                console.log("Initializing WASM");
            });
        }
    }, [wasmInitState]);

    useEffect(() => {
        if (tasks.length === 0 || workerConstraints === null) {
            return;
        }

        let newScheduler: Scheduler | null = null;
        console.log("WASM_INIT", wasmInitState, WASM_INIT, scheduler);
        if (wasmInitState && WASM_INIT && scheduler === null) {
            console.log("Initializing scheduler", tasks, workerConstraints, today, "60 minutes");
            const scheduleTasks = tasks.map((e) => {
                return {
                    id: e.taskId,
                    lane: e.lane,
                    slug: e.slug,
                    titleSummaries: e.titleSummaries,

                    priority: e.priority,
                    attention: e.attention,
                    requirements: [],
                    segments: e.segments.map((s) => ({ start: s.start.toISOString(), end: s.end.toISOString() })),
                    open: e.state === 'open',
                    assignee: e.assignee,

                    expectedStart: e.expectedStart.toISOString(),
                    expectedEnd: e.expectedEnd.toISOString(),
                    actualStart: e.actualStart ? e.actualStart.toISOString() : undefined,
                    actualEnd: e.actualEnd ? e.actualEnd.toISOString() : undefined,

                    dueDate: e.dueDate ? e.dueDate.toISOString() : undefined,
                    dependencies: e.dependencies,
                    parent: e.parent,
                    children: e.children,
                    color: e.color
                };
            });

            const sortedTasks = scheduleTasks.sort((a, b) => new Date(a.expectedStart).getTime() - new Date(b.expectedStart).getTime());
            const nowString = new Date(today).toISOString();

            newScheduler = build(
                sortedTasks,
                workerConstraints,
                nowString,
                BigInt(60 * 60)
            );
            setScheduler(newScheduler);
            setEvents(schedulerTasks(newScheduler.tasks()));
        } else if (wasmInitState === null || WASM_INIT === null) {
            console.log("Resetting scheduler");
            setWasmInit(null);
            setScheduler(null);
        }
    }, [tasks, today, workerConstraints, wasmInitState]);

    /* TODO: Implement infinite scroll */
    const handleScroll = useCallback(() => {
    }, []);

    // Recompute days when events change
    useEffect(() => {
        const dayContainsSegment = (day: Date, segment: EventSegment) => (
            dateSame(segment.start, day) || dateSame(segment.end, day)
        );

        setDays(() => {
            const daysSet: Set<number> = new Set();
            for (const task of events) {
                const startDay = new Date(task.expectedStart);
                startDay.setHours(0, 0, 0, 0);
                const endDay = new Date(task.expectedEnd);
                endDay.setHours(0, 0, 0, 0);

                daysSet.add(startDay.getTime());
                daysSet.add(endDay.getTime());
                // Add one week before the start day
                for (let i = 1; i <= 7; i++) {
                    daysSet.add(startDay.getTime() - i * 24 * 60 * 60 * 1000);
                }
                // Add one week after the end day
                for (let i = 1; i <= 7; i++) {
                    daysSet.add(endDay.getTime() + i * 24 * 60 * 60 * 1000);
                }
            }

            const newDays = [];
            for (const timestamp of daysSet) {
                const day = new Date(timestamp);
                const newDayEvents = [];
                for (const event of events) {
                    if (event.segments.some((s: EventSegment) => dayContainsSegment(day, s))) {
                        newDayEvents.push(event);
                    }
                }
                newDays.push({ date: day, events: newDayEvents });
            }
            newDays.sort((a, b) => a.date.getTime() - b.date.getTime());

            return newDays;
        });
    }, [events]);

    useEffect(() => {
        const el = calendarRef.current;
        if (!el) return;
        el.addEventListener('scroll', handleScroll);
        return () => {
            el.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const todayRef = useRef<HTMLDivElement>(null);

    if (!id) {
        return null;
    }

    // Called when an event is dropped into a new lane
    const handleEventAssigneeChange = (eventId: string, newAssigneeId: string) => {
        const theEvent = events.find((e) => e.taskId === eventId);
        if (!theEvent) {
            console.error("Event not found", eventId);
            return;
        }
        if (!scheduler || !workerConstraints) {
            console.error("Scheduler not initialized");
            return;
        }

        const task = tasks.find((t) => t.taskId === theEvent.taskId);
        if (newAssigneeId === NIL) {
            theEvent.assignee = undefined;
            theEvent.lane = 0;
            if (task) {
                task.assignee = undefined;
                task.lane = 0;
            }
        } else {
            theEvent.assignee = newAssigneeId;
            theEvent.lane = workerConstraints.findIndex((w) => w.id === newAssigneeId);
            if (task) {
                task.assignee = newAssigneeId;
                task.lane = theEvent.lane;
            }
        }

        if (task) {
            setTasks((prevTasks) => {
                return prevTasks.map((t) => {
                    if (t.taskId === theEvent.taskId) {
                        return task;
                    }
                    return t;
                });
            });
            updateChartTask(id, eventId, { "assignee": newAssigneeId });
        }
        setEvents((prevEvents) => {
            return prevEvents.map((e) => {
                const updatedEvent = tasks.find((u) => u.taskId === e.taskId);
                if (updatedEvent) {
                    return updatedEvent;
                }
                return e;
            });
        });
    };

    // Called when an event is dropped on a different time
    const handleEventTimeChange = (eventId: string, newStart: Date) => {
        const theEvent = events.find((e) => e.taskId === eventId);
        if (!theEvent) {
            console.error("Event not found", eventId);
            return;
        }
        if (!scheduler) {
            console.error("Scheduler not initialized");
            return;
        }
        if (lastAttemptedMove[eventId] && newStart.getTime() === lastAttemptedMove[eventId].getTime()) {
            console.log("Skipping duplicate move");
            return;
        }
        lastAttemptedMove[eventId] = newStart;

        const updatedEventsResult = scheduler.move_task(theEvent.taskId, newStart.toISOString());
        const updatedEvents: SchedulerTask[] = updatedEventsResult.tasks();
        const tasks = schedulerTasks(updatedEvents);

        if (updatedEventsResult.is_error() || !updatedEvents) {
            toast.error(updatedEventsResult.error() || "Error resizing event");
            return;
        }

        setEvents((prevEvents) => {
            return prevEvents.map((e) => {
                const updatedEvent = tasks.find((u) => u.taskId === e.taskId);
                if (updatedEvent) {
                    const segments = updatedEvent.segments.map((s) => ({
                        "start": s.start.toISOString(),
                        "end": s.end.toISOString()
                    }));
                    updateChartTask(id, updatedEvent.taskId, {
                        "segments":
                        {
                            "today": today.toISOString(),
                            "units": "hours",
                            "segments": segments
                        }
                    }).catch((e) => {
                        toast.error(`Backend failed to update ${updatedEvent.slug}: ${e.message}`);
                    })
                    return updatedEvent;
                }
                return e;
            });
        });

        delete lastAttemptedMove[eventId];
    };

    // Called when an event is 
    const handleEventDurationChange = (eventId: string, newEnd: Date) => {
        const theEvent = events.find((e) => e.taskId === eventId);
        if (!theEvent) return;
        if (!scheduler) return;
        if (Math.abs(newEnd.getTime() - theEvent.expectedStart.getTime()) < 3600 * 1000) {
            return;
        }

        if (lastAttemptedResize[eventId] && newEnd.getTime() === lastAttemptedResize[eventId].getTime()) {
            return;
        }
        lastAttemptedResize[eventId] = newEnd;

        const updatedEventsResult = scheduler.resize_task(theEvent.taskId, newEnd.toISOString());
        const updatedEvents: SchedulerTask[] = updatedEventsResult.tasks();
        const tasks = schedulerTasks(updatedEvents);

        if (updatedEventsResult.is_error() || !updatedEvents) {
            toast.error(updatedEventsResult.error() || "Error resizing event");
            return;
        }

        setEvents((prevEvents) => {
            return prevEvents.map((e) => {
                const updatedEvent = tasks.find((u) => u.taskId === e.taskId);
                if (updatedEvent) {
                    const segments = updatedEvent.segments.map((s) => ({
                        "start": s.start.toISOString(),
                        "end": s.end.toISOString()
                    }));
                    updateChartTask(id, updatedEvent.taskId, {
                        "segments":
                        {
                            "today": today.toISOString(),
                            "units": "hours",
                            "segments": segments
                        }
                    }).catch((e) => {
                        toast.error(`Backend failed to update ${updatedEvent.slug}: ${e.message}`);
                    })
                    return updatedEvent;
                }
                return e;
            });
        });
        delete lastAttemptedResize[eventId];
    };

    const scrollToToday = () => {
        todayRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    if (workerConstraints === null || scheduler === null || events.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Toaster />
            <DndProvider backend={HTML5Backend}>
                <div ref={calendarRef} className="h-full w-full" >
                    {days.map((day) => {
                        const dayMilestones = calendarMilestones.filter((m) => dateBeforeEq(m.start, day.date) && dateAfterEq(m.deadline, day.date));
                        return <DayColumn
                            ref={todayRef}
                            key={day.date.toDateString()}
                            startHour={startHour}
                            endHour={endHour}
                            today={today}
                            scrollToToday={scrollToToday}
                            dayData={day}
                            milestones={dayMilestones}
                            assignees={workerConstraints}
                            onEventAssigneeChange={handleEventAssigneeChange}
                            onEventTimeChange={handleEventTimeChange}
                            onEventDurationChange={handleEventDurationChange}
                        />;
                    })}
                </div>
            </DndProvider>
        </>
    );
};

export default CalendarView;
