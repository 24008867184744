import React, { memo, useEffect, useRef } from "react";
import { ListChildComponentProps, areEqual } from "react-window";
import { MilestoneIcon } from "lucide-react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import ReactTimeAgo from "react-time-ago";

import { Milestone } from "@/contexts/Milestone";
import { MilestoneTypeLabel } from "./utils";
import { Label } from "@/components/ui/label";
import { Overdue } from "@/components/cards/utils";

export const Card = memo(({ data, index, style }: ListChildComponentProps) => {
    const { items, handleClick, dragging, setDragging, dropRule } = data;
    const dataItem = items[index];
    if (!dataItem) {
        return null;
    }
    const height = (style.height as number) - 16;
    const isActiveDrag = !!dragging && dragging.id === dataItem.id;
    const opacity = isActiveDrag ? 0 : 1;

    const dynamicStyle = {
        ...style,
        opacity,
        margin: 8,
        marginLeft: 16,
        height,
        width: "calc(100% - 32px)",
    };

    return (<MiniData
        key={`data-${dataItem.id}`}
        isActiveDrag={isActiveDrag}
        setDragging={(card: Milestone | null) => setDragging(card as Milestone | null)}
        dropRule={dropRule}
        handleClick={() => handleClick(dataItem.id)}
        card={dataItem}
        style={dynamicStyle}
    />);
}, areEqual);

interface MiniDataProps {
    handleClick: () => void;
    card: Milestone;
    isActiveDrag: boolean;
    style: React.CSSProperties
    setDragging: (dragging: Milestone | null) => void;
    dropRule: (card: Milestone) => (item: unknown, monitor: DropTargetMonitor) => void;
}

function MiniData({ handleClick, card, style, isActiveDrag, setDragging, dropRule }: MiniDataProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [{ isDragging, handlerId }, connectDrag] = useDrag({
        type: "GRID_ITEM",
        item: card,
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            };
        },
    });

    useEffect(() => {
        if (!isActiveDrag && isDragging) {
            setDragging(card);
        } else if (isActiveDrag && !isDragging) {
            setDragging(null);
        }
    }, [isActiveDrag, card, setDragging, isDragging]);

    const [, connectDrop] = useDrop({
        accept: "GRID_ITEM",
        drop: dropRule(card),
    });
    connectDrag(ref);
    connectDrop(ref);

    const cardCompletedDate = card.completedDate ? new Date(card.completedDate) : null;
    const cardDueDate = card.dueDate ? new Date(card.dueDate) : null;

    return (
        <div
            ref={ref}
            onClick={handleClick}
            className="card"
            style={style}
            data-handler-id={handlerId}
        >
            <div className="card__top-bar">
                <div className="card__icon">
                    <MilestoneIcon size={24} />
                </div>
                <Overdue completed={card.completed} dueDate={cardDueDate} compressed={true} />
                <div className="flex-grow" />
                <div className="card-header">{card.name}</div>
            </div>
            <div className="m-2 justify-between items-center flex">
                <MilestoneTypeLabel type={card.milestoneType} />
                <div className="flex-grow" />
                {cardCompletedDate && <div className="mb-2 mr-2 flex m-2 items-center justify-between rounded-lg border p-1">
                    <Label>{card.completed ? "Completed" : ""}</Label>
                    <ReactTimeAgo className="ml-2" date={cardCompletedDate} />
                </div>}
            </div>
        </div>
    );
}
