import { createContext, useEffect, useState, useCallback } from 'react';
import { ProviderProps } from "./ContextTypes";
import { apiFetch, apiFetchNoContent, eventEmitter, isDevelopment } from '.';

export type Timeline = "week" | "sprint" | "month" | "quarter" | "year";

function testMilestones(): Milestone[] {
    const milestones = [
        {
            id: "2",
            orgId: "2",
            milestoneType: "checkpoint",
            name: "Milestone 2",
            description: "Description 1",
            dueDate: "2024-09-01T00:00:00",
            created: "2024-08-01T00:00:00",
            completed: false,
            started: true,
            startDate: "2024-08-01T00:00:00",
        },
        {
            id: "1",
            orgId: "1",
            milestoneType: "checkpoint",
            name: "Milestone 1",
            description: "Description 1",
            dueDate: "2024-09-01T00:00:00",
            created: "2024-08-01T00:00:00",
            completedDate: "2024-08-01T00:00:00",
            completed: true,
            started: true,
            startDate: "2024-08-01T00:00:00",
        },
    ];
    return milestones;
}

export interface Milestone {
    id: string;
    orgId: string;
    milestoneType: string;
    name: string;
    description: string;
    dueDate?: string;
    created: string;
    completed: boolean;
    completedDate?: string;
    started: boolean;
    startDate?: string;
}

export type RepeatSchema = { "increment": number } | { "date": string };
export interface RepeatData {
    repeats: string;
    repeatEnd?: Date;
    repeatSchema: RepeatSchema;
}
export type MilestoneType = string | { "timeline": Timeline } | { "deadline": string };

export interface NewMilestone {
    name: string;
    description: string;
    milestoneType: MilestoneType | null;
    dueDate?: string;
    startDate?: string;
    repeat?: RepeatData;
}

export type MilestoneUpdate = "start" |
    "stop" |
{ "completed": boolean } |
{ "name": string } |
{ "description": string } |
{ "type": MilestoneType } |
{ "dueDate": string } |
{ "completed": boolean };

async function getMilestone(milestoneId: string): Promise<Milestone> {
    return await apiFetch(`/api/v1/milestone/${milestoneId}`, { method: "GET" });
}

async function getMilestones(page: number): Promise<Milestone[]> {
    if (page === 1 && isDevelopment) {
        return testMilestones();
    }
    return await apiFetch(`/api/v1/milestone/list/${page}`, { method: "GET" });
}

async function postMilestone(milestone: NewMilestone): Promise<Milestone> {
    return await apiFetch('/api/v1/milestone', {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(milestone)
    });
}

async function removeMilestone(milestoneId: string): Promise<boolean> {
    try {
        await apiFetchNoContent(`/api/v1/milestone/${milestoneId}`, { method: "DELETE" });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

async function putUpdateMilestone(milestoneId: string, update: MilestoneUpdate): Promise<null> {
    await apiFetchNoContent(`/api/v1/milestone/${milestoneId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(update)
    });
    return null;
}

export interface MilestoneContextProps {
    milestones: Milestone[],
    hasNextPage: boolean,
    milestoneCache: number,
    loadNextPage: () => void,
    clearMilestoneCache: () => void,
    createMilestone: (milestone: NewMilestone) => Promise<void>,
    deleteMilestone: (milestoneId: string) => Promise<void>,
    getMilestone: (milestoneId: string) => Promise<Milestone>,
    updateMilestone: (milestoneId: string, update: MilestoneUpdate) => Promise<void>,
}

export const MilestoneContext = createContext<MilestoneContextProps | undefined>(undefined);

const fetchMilestones = async (setHasNextPage: (value: boolean) => void,
    setMilestones: (value: Milestone[]) => void,
    setPage: (value: number) => void,
    milestones: Milestone[],
    page: number) => {
    const newMilestones = await getMilestones(page);
    if (newMilestones.length === 0) {
        setHasNextPage(false);
    } else {
        setMilestones([...milestones, ...newMilestones]);
        setPage(page + 1);
    }
};

const MilestoneProvider: React.FC<ProviderProps> = ({ children }) => {
    const [milestones, setMilestones] = useState<Milestone[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [dataCache, setMilestoneCache] = useState(Date.now());

    const loadNextPage = useCallback(() => {
        fetchMilestones(setHasNextPage, setMilestones, setPage, milestones, page);
    }, [page, milestones]);

    const insertMilestone = useCallback((milestone: Milestone) => {
        setMilestones([...milestones, milestone]);
    }, [milestones]);

    useEffect(() => {
        const addMilestoneEvent = ({ milestone }: { milestone: Milestone }) => {
            insertMilestone(milestone);
        }
        eventEmitter.on("MILESTONE-ADD", addMilestoneEvent);
        return () => {
            eventEmitter.off("MILESTONE-ADD", addMilestoneEvent);
        }
    }, [insertMilestone]);

    useEffect(() => {
        setPage(1);
        setMilestones([]);
        setHasNextPage(true);
        fetchMilestones(setHasNextPage, setMilestones, setPage, [], 1);
    }, [dataCache]);

    const createMilestone = useCallback(async (milestone: NewMilestone) => {
        try {
            const newMilestone = await postMilestone(milestone);
            insertMilestone(newMilestone);
        } catch (e) {
            eventEmitter.emit("ERROR", "Failed to create milestone");
        }
    }, [insertMilestone]);

    const deleteMilestone = useCallback(async (milestoneId: string) => {
        if (await removeMilestone(milestoneId)) {
            const newMilestones = milestones.filter((m) => m.id !== milestoneId);
            setMilestones(newMilestones);
        } else {
            eventEmitter.emit("ERROR", "Failed to delete milestone");
        }
    }, [milestones]);

    const updateMilestone = useCallback(async (milestoneId: string, update: MilestoneUpdate) => {
        await putUpdateMilestone(milestoneId, update);
        const milestone = await getMilestone(milestoneId);
        const index = milestones.findIndex((m) => m.id === milestoneId);
        setMilestones([...milestones.slice(0, index), milestone, ...milestones.slice(index + 1)]);
        setMilestoneCache(Date.now());
    }, [milestones]);

    const value = {
        milestones,
        milestoneCache: dataCache,
        hasNextPage,
        loadNextPage,
        clearMilestoneCache: () => setMilestoneCache(Date.now()),
        createMilestone,
        deleteMilestone,
        getMilestone,
        updateMilestone,
    };

    return (<MilestoneContext.Provider value={value}>
        {children}
    </MilestoneContext.Provider>);
}
export default MilestoneProvider;

