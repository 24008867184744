import React, { useEffect, useRef, useState } from 'react';
import { FaEdit, FaChevronDown } from 'react-icons/fa';
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { ActionMeta } from 'react-select';
import ReactTimeAgo from 'react-time-ago';
import { CalendarIcon } from 'lucide-react';

import { InfoIcon } from '@/components/Floater';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';

interface EditDateProps {
    textLabel: string;
    overdueLabel?: string;
    date: Date | null;
    completed?: boolean;
    onCompleted: (date: string | null) => void;
}

function zeroPad(number: number, digits: number) {
    return number.toString().padStart(digits, '0');
}

export function EditDateTime({ textLabel, overdueLabel, date, completed, onCompleted }: EditDateProps) {
    const [dateString, setDateString] = useState<string | undefined>(undefined);
    const isOverdue = date && date < new Date();

    useEffect(() => {
        let dateString = "";
        if (date) {
            dateString = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}T${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`;
        }
        setDateString(dateString);
    }, [date]);

    const datetime = dateString ? new Date(dateString) : undefined;
    const triggerRef = useRef<HTMLButtonElement>(null);

    function setDateUtc(localDate: Date | undefined) {
        if (localDate) {
            const isoString = localDate.toISOString();
            setDateString(isoString);
            onCompleted(isoString);
        } else {
            setDateString(undefined);
            onCompleted(null);
        }
        triggerRef.current?.click();
    }

    // I don't understand why the outer div is necessary, but the component disappears without it
    return (
        <div>
            <Popover>
                <PopoverTrigger className="w-full" ref={triggerRef}>
                    <div className="m-2 flex items-center justify-between rounded-lg border p-4">
                        {!completed && isOverdue && overdueLabel ?
                            <div className="m-2 items-center justify-between text-red-500">
                                <label className="detail-grid__label">{overdueLabel}</label>
                            </div> :
                            <div className="m-2 items-center justify-between">
                                <label className="detail-grid__label">{textLabel}</label>
                            </div>}
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {datetime && <ReactTimeAgo date={datetime} className="editable__time" />}
                        <InfoIcon
                            content="Setting a due time will ensure it is not scheduled after this point"
                            className="text-sm omnibar__icon ml-1"
                        />
                    </div>
                </PopoverTrigger>
                <PopoverContent side="top" className="w-[342px] p-1 panel">
                    <Calendar
                        required={false}
                        mode="single"
                        defaultMonth={datetime}
                        selected={datetime}
                        onSelect={setDateUtc}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}

interface EditInputProps {
    textLabel: string;
    text: string;
    setText: (text: string) => void;
    setRule?: (text: string) => string;
    onCompleted: () => void;
}

export function EditInput({ textLabel, text, setText, setRule, onCompleted }: EditInputProps) {
    const rule = setRule ? setRule : (text: string) => text;

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setText(rule(event.target.value));
    }

    return (<div className="flex w-full">
        <label className="editable__label">{textLabel}:</label>
        <input
            className="editable__input"
            type="text"
            value={text}
            onChange={onChange}
            onBlur={onCompleted}
            autoFocus
        />
    </div>);
}

interface EditFormInputProps {
    textLabel: string;
    text: string;
    setText: (source: string, text: string) => void;
}
function EditFormInput({ textLabel, text, setText }: EditFormInputProps) {
    const rems = 10 / (Math.max(textLabel.length - 4, 0) + 10);

    return (<div className="flex w-72 mt-2">
        <label
            style={{ fontSize: `${rems}rem` }}
            className="editable__label w-16">{textLabel}:</label>
        <input
            className="editable__input w-52"
            type="text"
            value={text}
            onChange={(e) => { setText(textLabel, e.target.value) }}
        />
    </div>);
}

export type Option = { id: number; label: string; value: string; };

interface EditSearchProps {
    textLabel: string;
    searchFrom: (text: string) => Option[];
    onSelect: (value: string) => void;
    mustSelect?: boolean;
}

export function EditSearch({ textLabel, searchFrom, onSelect, mustSelect }: EditSearchProps) {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        return callback(searchFrom(inputValue));
    };
    const onChange = (option: Option | null, actionMeta: ActionMeta<Option>) => {
        if (option) {
            if (!mustSelect && actionMeta.action == "create-option") {
                onSelect(option.value);
            }
            if (actionMeta.action == "select-option") {
                onSelect(option.value);
            }
        }
    }

    return (<>
        {mustSelect ? <AsyncSelect placeholder={textLabel} className="col-span-3 editable__selector text-neutral-700" defaultOptions loadOptions={loadOptions} onChange={onChange} /> :
            <AsyncCreatableSelect placeholder={textLabel} className="col-span-3 editable__selector" loadOptions={loadOptions} onChange={onChange} />}
    </>);
}

interface EditSelectProps {
    textLabel: string;
    selected: string;
    options: Option[];
    selectOption: (source: string, text: string) => void;
}

function EditSelectInput({ textLabel, selected, options, selectOption }: EditSelectProps) {
    const rems = 10 / (Math.max(textLabel.length - 4, 0) + 10);
    return (<div className="flex w-72 mt-2">
        <label
            style={{ fontSize: `${rems}rem` }}
            className="editable__label w-16">{textLabel}:</label>
        <select
            className="editable__input w-52"
            value={selected}
            onChange={(e) => selectOption(textLabel, e.target.value)}
        >
            {options.map((option) => (
                <option key={option.id} value={option.value}>{option.label}</option>
            ))}
        </select>
    </div>);
}

interface EditCheckedInputProps {
    textLabel: string;
    checked: boolean;
    checkBox: (source: string, text: string) => void;
}

function EditCheckboxInput({ textLabel, checked, checkBox }: EditCheckedInputProps) {
    return (<div className="flex w-72 mt-2">
        <label className="editable__label w-16">{textLabel}:</label>
        <input type="checkbox"
            className="editable__input mr-44"
            onChange={(e) => checkBox(textLabel, JSON.stringify(e.target.checked))}
            checked={checked}
        />
    </div>);
}

export type TextElement = {
    label: string;
    text: string;
};
export type SelectElement = {
    label: string;
    text: string;
    options: Option[]
};
export type CheckboxElement = {
    label: string;
    checked: boolean;
};
export type SearchElement = {
    label: string;
    text: string;
    searchFrom: (search: string) => Option[];
    mustSelect: boolean;
}
export type FormElement = TextElement | SelectElement | CheckboxElement | SearchElement;
interface EditFormProps {
    initialElements: FormElement[];
    buttonText: string;
    onSave: (elements: FormElement[]) => void;
}

export function EditForm({ initialElements, buttonText, onSave }: EditFormProps) {
    const [elements, setElements] = useState<FormElement[]>(initialElements);

    function handleChange(source: string, text: string) {
        const current = elements;
        for (let i = 0; i < current.length; i++) {
            const elem = current[i];
            if (elem.label == source) {
                if ("text" in elem) {
                    elem.text = text;
                } else {
                    elem.checked = JSON.parse(text);
                }
            }
        }
        setElements([...current]);
    }

    function onClick() {
        onSave(elements);
        setElements(initialElements);
    }

    return (
        <div className="editable__form">
            {elements.map((element) => {
                if ("searchFrom" in element) {
                    return <EditSearch
                        key={element.label}
                        textLabel={element.label}
                        searchFrom={element.searchFrom}
                        onSelect={(value) => handleChange(element.label, value)}
                        mustSelect={element.mustSelect}
                    />;
                } else if ("options" in element) {
                    return <EditSelectInput
                        key={element.label}
                        textLabel={element.label}
                        selected={element.text}
                        options={element.options}
                        selectOption={handleChange}
                    />;
                } else if ("checked" in element) {
                    return <EditCheckboxInput
                        key={element.label}
                        textLabel={element.label}
                        checked={element.checked}
                        checkBox={handleChange}
                    />;
                } else {
                    return <EditFormInput
                        key={element.label}
                        textLabel={element.label}
                        text={element.text}
                        setText={handleChange}
                    />;
                }
            })}
            <button className="editable__button" onClick={onClick}>{buttonText}</button>

        </div>
    );
}

interface EditableRegionProps {
    children: React.ReactNode;
    position?: string;
    isEditing: boolean;
}

export function EditableRegion({ children, position, isEditing }: EditableRegionProps) {
    const className = position === "fixed" ? "editable__dropdown--fixed" : "editable__dropdown";

    return (<div>
        {isEditing && <div className={className}>
            {children}
        </div>}
    </div>);
}

interface EditableDropdownProps {
    children: React.ReactNode;
    translation: number;
    translateY?: number;
    isEditing: boolean;
    setIsEditing: (edit: boolean) => void;
    marginTop?: string;
}

export function EditableDropdown({ children, translation, translateY, isEditing, setIsEditing, marginTop }: EditableDropdownProps) {
    const translateYpx = translateY ? translateY : 14;
    const transformStyle = {
        transform: `translateX(-${translation}px) translateY(${translateYpx}px)`,
    };

    return (<div className="ml-2 align-middle">
        {isEditing ? (
            <FaChevronDown onClick={() => setIsEditing(false)} style={{ fontSize: "1rem", marginTop }} />
        ) : (
            < FaEdit onClick={() => setIsEditing(true)} style={{ fontSize: "1rem", marginTop }} />
        )}
        {isEditing && <div className="editable__dropdown" style={transformStyle}>
            {children}
        </div>}
    </div>);
}
