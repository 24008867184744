import { Project } from "@/contexts/Project";
import { UserData } from "@/contexts/User";
import { TaskData } from "@/contexts/Task";
import { ChartPlan } from "@/contexts/Chart";
import { JobDataPartial, JobDataFull } from "@/contexts/Job";
import { Milestone } from "@/contexts/Milestone";

export type CardData = TaskData | UserData | JobDataPartial | JobDataFull | Project | ChartPlan | Milestone;

export function isTaskData(data: CardData): data is TaskData {
    return 'title' in data;
}

export function isUserData(data: CardData): data is UserData {
    return 'username' in data;
}

export function isProjectData(data: CardData): data is Project {
    return 'name' in data;
}

export function isJobDataPartial(data: CardData): data is JobDataPartial {
    return 'id' in data &&
        'project' in data &&
        'taskName' in data &&
        'jobName' in data &&
        !('result' in data) &&
        !('helpRequest' in data) &&
        !('jobLog' in data) &&
        !('completionTime' in data);
}

export interface CardState {
    cardsByIndex: CardData[];
    hasMore: boolean;
}
