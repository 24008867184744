import { Label } from '@/components/ui/label';

export interface PlannedTask {
    taskId: string;
    expectedAssigneeId: string | null;
    expectedStart: string;
    expectedDuration: number;
    assignmentDependency: string | null;
    metadata: unknown;

    actualStart: string | null;
    actualDuration: number | null;

    actualAssigneeId: string | null;
    plannedStart: string;
    plannedDuration: number;
    parentTaskId: string | null;
}

interface PlannedTaskProps {
    plan: PlannedTask;
}

function expectedDuration(plan: PlannedTask): number {
    return Math.round(plan.expectedDuration / 60 / 60);
}

function plannedDuration(plan: PlannedTask): number {
    return Math.round(plan.plannedDuration / 60 / 60);
}

const PlannedTaskDisplay = ({ plan }: PlannedTaskProps) => {
    const expectedDurationDays = expectedDuration(plan);
    const plannedDurationDays = plannedDuration(plan);

    return (<div className="m-2 flex flex-col items-center justify-between rounded-lg border p-4">
        <Label>Estimated duration: {plannedDurationDays} hours</Label>
        <Label>Planned duration: {expectedDurationDays} hours</Label>
    </div>);
};

export default PlannedTaskDisplay;
