import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import OmniBar from '@/components/OmniBar';
import SideBar from '@/components/SideBar';
import Instructions from '@/components/Instructions';
import { eventEmitter, useInstructionContext } from '@/contexts';
import { login, useAppContext } from '@/contexts';
import { Toaster } from '@/components/ui/toaster';

const NO_AUTH_PAGES = [
    '/app/beta-invite',
    '/app/beta',
    '/app/github/login',
    '/app/onboarding',
    '/app/checkout',
    '/app/checkout/complete',
    '/app/down',
];

const LOGGED_IN_ROLES = [
    'active',
    'admin',
];

interface AppPageProps {
    active: string;
    children: React.ReactNode;
    customSideBar?: React.ReactNode;
}

const AppPage: React.FC<AppPageProps> = ({ active, children, customSideBar }) => {
    const { loggedInUser, loginAttempted, narrow } = useAppContext();
    const { conversationOpen, setConversationOpen } = useInstructionContext();
    const navigate = useNavigate();
    const location = useLocation();

    const basePath = location.pathname.split('?')[0];
    const onNoAuthPage = NO_AUTH_PAGES.includes(basePath);

    useEffect(() => {
        eventEmitter.on('ERROR', toast.error)
        return () => {
            eventEmitter.off('ERROR', toast.error)
        }
    }, [])

    useEffect(() => {
        if (conversationOpen && narrow && location.pathname !== '/app/chat') {
            console.log('/app/chat');
            navigate("/app/chat");
        }

        if (!narrow && location.pathname === '/app/chat') {
            setConversationOpen(true);
            console.log('nav back');
            navigate(-1);
        }
    }, [conversationOpen, setConversationOpen, navigate, narrow, location]);

    if (!onNoAuthPage) {
        if (!loginAttempted) {
            return;
        }

        if (!loggedInUser) {
            login();
            return;
        }

        const validatedUser = loggedInUser?.role && LOGGED_IN_ROLES.includes(loggedInUser.role);

        if (!validatedUser) {
            console.log('/app/verify-email');
            navigate('/app/verify-email');
        }
    }

    return (
        <div key="grid" className="app-grid">
            <OmniBar key="omnibar" />
            <div className="main-container">
                {!narrow && <Instructions key="conversation" />}
                {children}
            </div>
            {customSideBar ? customSideBar : <SideBar key="sidebar" active={active} />}
            <Toaster />
        </div>
    );
}
export default AppPage;
