import { FormControl } from "@/components/ui/form";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select";

export interface FormItemProps<T> {
    value?: T;
    onChange: (value: T) => void;
}

export const TimeZoneSelect = ({ onChange, value }: FormItemProps<string>) => {
    const onValueChange = (value: string) => {
        console.log("Time zone", value);
        onChange(value);
    };

    return <Select onValueChange={onValueChange} defaultValue={value}>
        <FormControl>
            <SelectTrigger>
                <SelectValue placeholder="Select Timezone" />
            </SelectTrigger>
        </FormControl>
        <SelectContent>
            {/* Pacific
            <SelectItem className="select__hover" value="Etc/GMT+12">(GMT-12:00) International Date Line West</SelectItem>
            <SelectItem className="select__hover" value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</SelectItem> */}
            <SelectGroup>
                <SelectLabel>Americas</SelectLabel>
                <SelectItem className="select__hover" value="Pacific/Honolulu">(GMT-10:00) Hawaii</SelectItem>
                <SelectItem className="select__hover" value="America/Anchorage">(GMT-09:00) Alaska</SelectItem>
                <SelectItem className="select__hover" value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</SelectItem>
                <SelectItem className="select__hover" value="America/Denver">(GMT-07:00) Mountain Time (US & Canada)</SelectItem>
                <SelectItem className="select__hover" value="America/Chicago">(GMT-06:00) Central Time (US & Canada)</SelectItem>
                <SelectItem className="select__hover" value="America/New_York">(GMT-05:00) Eastern Time (US & Canada)</SelectItem>
                <SelectLabel>Other regions coming soon...</SelectLabel>
            </SelectGroup>

            {/* South America
            <SelectItem value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</SelectItem>
            <SelectItem value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</SelectItem>
            <SelectItem value="America/Caracas">(GMT-04:00) Caracas, La Paz</SelectItem>
            <SelectItem value="America/Santiago">(GMT-04:00) Santiago</SelectItem>
            <SelectItem value="America/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</SelectItem>
            <SelectItem value="Atlantic/South_Georgia">(GMT-02:00) Mid-Atlantic</SelectItem>
            <SelectItem value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</SelectItem> */}
            {/* Europe
            <SelectItem value="Europe/London">(GMT+00:00) London, Dublin</SelectItem>
            <SelectItem value="Europe/Lisbon">(GMT+00:00) Lisbon</SelectItem>
            <SelectItem value="Africa/Casablanca">(GMT+00:00) Casablanca</SelectItem>
            <SelectItem value="Europe/Paris">(GMT+01:00) Paris, Berlin</SelectItem>
            <SelectItem value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen</SelectItem>
            <SelectItem value="Europe/Warsaw">(GMT+01:00) Warsaw, Zagreb</SelectItem> */}
            {/* Africa and Asia
            <SelectItem value="Europe/Istanbul">(GMT+02:00) Istanbul</SelectItem>
            <SelectItem value="Asia/Jerusalem">(GMT+02:00) Jerusalem</SelectItem>
            <SelectItem value="Europe/Kiev">(GMT+02:00) Kiev</SelectItem>
            <SelectItem value="Africa/Cairo">(GMT+02:00) Cairo</SelectItem>
            <SelectItem value="Europe/Athens">(GMT+02:00) Athens, Bucharest</SelectItem>
            <SelectItem value="Asia/Baghdad">(GMT+03:00) Baghdad</SelectItem>
            <SelectItem value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg</SelectItem>
            <SelectItem value="Africa/Nairobi">(GMT+03:00) Nairobi</SelectItem>
            <SelectItem value="Asia/Tehran">(GMT+03:30) Tehran</SelectItem>
            <SelectItem value="Asia/Dubai">(GMT+04:00) Abu Dhabi, Muscat</SelectItem>
            <SelectItem value="Asia/Yerevan">(GMT+04:00) Yerevan</SelectItem>
            <SelectItem value="Asia/Kabul">(GMT+04:30) Kabul</SelectItem>
            <SelectItem value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi</SelectItem>
            <SelectItem value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</SelectItem>
            <SelectItem value="Asia/Kolkata">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</SelectItem>
            <SelectItem value="Asia/Kathmandu">(GMT+05:45) Kathmandu</SelectItem>
            <SelectItem value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</SelectItem>
            <SelectItem value="Asia/Dhaka">(GMT+06:00) Dhaka</SelectItem>
            <SelectItem value="Asia/Yangon">(GMT+06:30) Yangon (Rangoon)</SelectItem>
            <SelectItem value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</SelectItem>
            <SelectItem value="Asia/Ho_Chi_Minh">(GMT+07:00) Ho Chi Minh City</SelectItem>
            <SelectItem value="Asia/Shanghai">(GMT+08:00) Beijing, Chongqing, Hong Kong</SelectItem>
            <SelectItem value="Asia/Singapore">(GMT+08:00) Singapore, Kuala Lumpur</SelectItem>
            <SelectItem value="Asia/Tokyo">(GMT+09:00) Tokyo, Osaka</SelectItem>
            <SelectItem value="Asia/Seoul">(GMT+09:00) Seoul</SelectItem>
            <SelectItem value="Asia/Yakutsk">(GMT+09:00) Yakutsk</SelectItem> */}
            {/* Australia and Pacific
            <SelectItem value="Australia/Sydney">(GMT+10:00) Sydney</SelectItem>
            <SelectItem value="Australia/Brisbane">(GMT+10:00) Brisbane</SelectItem>
            <SelectItem value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</SelectItem>
            <SelectItem value="Asia/Vladivostok">(GMT+10:00) Vladivostok</SelectItem>
            <SelectItem value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</SelectItem>
            <SelectItem value="Pacific/Fiji">(GMT+12:00) Fiji, Marshall Is.</SelectItem>
            <SelectItem value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</SelectItem> */}
        </SelectContent>
    </Select>;
};
