import { TaskData } from "@/contexts/Task";
import { Link } from "react-router-dom";

interface ChildTasksProps {
    children: TaskData[];
}

export default function ChildTasks({ children }: ChildTasksProps) {
    const hasChildren = children.length > 0;
    if (hasChildren) {
        return (
            <div className="flex items-center m-2 justify-between rounded-lg border p-4">
                <h3 className="text-lg font-semibold">Children</h3>
                {children.map((task, index) => (
                    <Link key={index} className="links__tag--dependsOn p-3 text-lg" to={`/app/tasks/id/${task.id}`}>{task.slug}</Link>
                ))}
            </div>
        );
    }
    return null;
}
