import { MilestoneType } from "@/contexts/Milestone";
import { Label } from "@/components/ui/label";

export function MilestoneTypeLabel({ type }: { type: MilestoneType }) {
    if (typeof type === "string") {
        return <Label className="w-64 p-3 mr-2">{type}</Label>;
    } else if ("timeline" in type) {
        return <Label className="w-64 p-3 mr-2">{`Timeline: ${type.timeline}`}</Label>;
    } else if ("deadline" in type) {
        return <Label className="w-64 p-3 mr-2">{`Deadline: ${type.deadline}`}</Label>;
    }
}
