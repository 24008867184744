import { eventEmitter, apiFetch } from ".";

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PlannedTask } from "@/components/cards/task/PlannedTask";
import { Release } from "@/contexts/Project";
import { UserData } from "@/contexts/User";

export interface Milestone {
    milestoneType: string;
    name: string;
    description: string;
    dueDate?: string;
    created: string;
    completed: boolean;
    completedDate?: string;
}

export interface TaskData {
    id: string;
    slug: string;
    created: string;
    title: string;
    titleSummaries: string[];
    description: string;
    author: UserData;
    assignee?: UserData;
    abstractState: string;
    priority: number;
    dueDate?: string;
    milestone?: Milestone;
}

export interface FlowNode {
    id: string;
    nodeName: string;
}

export interface TaskLink {
    link: TaskData;
    linkType: string;
}

export interface TaskAssociation {
    source: string;
    url: string;
    conflict?: boolean;
}

export interface TaskDetails {
    task: TaskData;
    metadata: object[];
    state: FlowNode;
    validTransitions: FlowNode[];
    watchers: UserData[];
    linksOut: TaskLink[];
    linksIn: TaskLink[];
    releases?: Release[];
    deployment?: string;
    plan?: PlannedTask;
    association?: TaskAssociation;
}

export const TASKS_PER_PAGE = 50;
export type Order = "alphabetical" | "created" | "due" | "priority";
const orders: Order[] = ["alphabetical", "created", "due", "priority"];

interface GetTasksResponse {
    data: TaskData[];
    page: number;
    limit: number;
    next?: string;
    prev?: string;
}

async function searchTasks(projectId: string | null, searchTerm: string | null, filterRule: FilterRule, page: number, order: Order): Promise<GetTasksResponse> {
    const searchParams = new URLSearchParams();
    if (filterRule) {
        searchParams.set("filterRule", filterRule);
    }
    if (projectId) {
        searchParams.set("projectId", projectId);
    }
    searchParams.set("page", page.toString());
    searchParams.set("limit", TASKS_PER_PAGE.toString());
    searchParams.set("order", order);
    if (searchTerm) {
        searchParams.set("query", searchTerm);
    }
    return await apiFetch(`/api/v1/task/list?${searchParams}`, { method: "GET", }, "Failed to search tasks");
}

export type MetadataSource = 'tag';

export type TaskUpdate = { assignOther: { userId: string } } |
    "assignSelf" |
{ changeDescription: { description: string } } |
{ changeTitle: { title: string } } |
{ changePriority: { value: number } } |
{ changeDueDate: { dueDate: string | null } } |
{ changeMilestone: { milestoneId?: string } } |
{ addComment: { content: string, parentId?: string } } |
{ editComment: { commentId: string, content: string } } |
{ removeComment: { commentId: string } } |
{ addRelease: { releaseId: string } } |
{ removeRelease: { releaseId: string } } |
{ link: { taskId: string, linkType: string } } |
{ stopWatchingTask: string } |
{ metadata: { source: MetadataSource, value: object } } |
{ transition: { nodeId: string } } |
    "close" |
    "restart" |
    "archive" |
    "unassign" |
    "undo" |
{ unlink: { taskId: string } } |
{ removeMetadata: { value: object } } |
{ watchTask: string };

export async function updateTaskMutation({ taskId, data }: { taskId: string, data: TaskUpdate }): Promise<TaskData | null> {
    return await apiFetch(`/api/v1/task/${taskId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    }, `Failed to update task ${taskId}`);
}

export const useUpdateTask = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ taskId, data }: { projectId: string, taskId: string, data: TaskUpdate }) => updateTaskMutation({ taskId, data }),
        onError: () => {
            eventEmitter.emit("ERROR", "Failed to update task");
        },
        onSettled: (_data, _error, variables) => {
            const { projectId, taskId } = variables;
            queryClient.invalidateQueries({ queryKey: ['task', taskId] });
            orders.forEach((order) => {
                queryClient.invalidateQueries({ queryKey: ['tasks', projectId, order] });
            });
        }
    });
}

export interface TaskCreateData {
    projectId: string;
    description: string;
    assigneeId?: string;
    priority: number;
    dueDate?: string;
}

async function addTaskMutation(taskData: TaskCreateData): Promise<TaskData> {
    return await apiFetch("/api/v1/task", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(taskData),
    }, "Failed to add task");
}

export const useAddTask = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (taskData: TaskCreateData) => addTaskMutation(taskData),
        onError: () => {
            eventEmitter.emit("ERROR", "Failed to add task");
        },
        onSettled: (_data, _error, variables) => {
            const { projectId } = variables;
            orders.forEach((order) => {
                queryClient.invalidateQueries({ queryKey: ['tasks', projectId, order] });
            });
        }
    });
}

export const useTasks = (projectId: string | null, order: Order, filter?: string, filterRule?: FilterRule) => {
    return useInfiniteQuery({
        queryKey: ['tasks', projectId, order, filter, filterRule],
        queryFn: ({ pageParam }) => {
            return searchTasks(projectId, filter ?? null, filterRule ?? null, pageParam, order);
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, _pages, lastPageParam) => {
            if (lastPage.data.length < TASKS_PER_PAGE) {
                return undefined;
            }
            return lastPageParam + 1;
        },
        getPreviousPageParam: (_firstPage, _pages, firstPageParam) => {
            if (firstPageParam <= 1) {
                return undefined;
            }
            return firstPageParam - 1;
        }
    });
}

export async function fetchTask(taskId: string): Promise<TaskDetails | null> {
    return await apiFetch<TaskDetails>(`/api/v1/task/${taskId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
}

export const useTask = (taskId: string) => {
    return useQuery({ queryKey: ['task', taskId], queryFn: () => fetchTask(taskId) });
}

export type FilterRule = null | "open" | "inProgress" | "closed" | "archived" | "watching" | "assignedToMe";
