import React, { memo, useEffect, useRef } from "react";
import { ChartArgs } from "@/contexts/Chart";
import { ListChildComponentProps, areEqual } from "react-window";
import { FaRegChartBar } from "react-icons/fa6";
import Personelle from "@/components/Personelle";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { Project } from "@/contexts/Project";
import ReactTimeAgo from 'react-time-ago';

export const Card = memo(({ data, index, style }: ListChildComponentProps) => {
    const { items, dragging, handleClick, setDragging, dropRule } = data;
    const [isActiveDrag, setIsActiveDrag] = React.useState(false);
    const [dataItem, setDataItem] = React.useState(items[index]);

    useEffect(() => {
        setDataItem(items[index]);
        const dataItem = items[index];
        if (!dataItem) {
            return;
        }
        setIsActiveDrag(!!dragging && dragging.id === dataItem.id);
    }, [dragging, items, index]);

    const height = style.height as number;

    const opacity = dragging ? 0 : 1;

    const dynamicStyle = {
        ...style,
        opacity,
        margin: 8,
        marginLeft: 16,
        height: height - 16,
        width: 'calc(100% - 32px)',
    };

    return (<MiniChart
        key={`data-${dataItem.id}`}
        handleClick={() => handleClick(dataItem.planId)}
        card={dataItem}
        style={dynamicStyle}
        isActiveDrag={isActiveDrag}
        dropRule={dropRule}
        setDragging={(card: ChartArgs | null) => setDragging(card as ChartArgs | null)}
    />);
}, areEqual);

interface MiniChartProps {
    handleClick: () => void;
    card: ChartArgs;
    setDragging: (dragging: ChartArgs | null) => void;
    isActiveDrag: boolean;
    style: React.CSSProperties;
    dropRule: (card: ChartArgs) => (item: unknown, monitor: DropTargetMonitor) => void;
}

function MiniChart({ handleClick, card, isActiveDrag, setDragging, style, dropRule }: MiniChartProps) {
    const ref = useRef<HTMLDivElement>(null);
    const title = card.projects.map((p: Project) => p.name).join(", ");

    const [{ isDragging, handlerId }, connectDrag] = useDrag({
        type: "GRID_ITEM",
        item: card,
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            };
        },
    });

    useEffect(() => {
        if (!isActiveDrag && isDragging) {
            setDragging(card);
        } else if (isActiveDrag && !isDragging) {
            setDragging(null);
        }
    }, [isActiveDrag, card, setDragging, isDragging]);

    const [, connectDrop] = useDrop({
        accept: "GRID_ITEM",
        drop: dropRule(card),
    });

    connectDrag(ref);
    connectDrop(ref);

    return (
        <div
            ref={ref}
            onClick={handleClick}
            className="card"
            style={style}
            data-handler-id={handlerId}
        >
            <div className="card__top-bar">
                <div className="card__icon">
                    <FaRegChartBar />
                </div>
                <div className="card-header">{title}</div>
            </div>
            <div className="mt-2 mx-2 grid grid-cols-2 items-center justify-center">
                <Personelle userData={card.createdBy} tag="Plan started by" />
                <ReactTimeAgo date={new Date(card.createdAt)} locale="en-US" />
            </div>
        </div>
    );
}
