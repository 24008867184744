import { Moon, Sun, Computer } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useEffect, useMemo } from "react";

import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
} from "@/components/ui/form";
import { apiFetch, useAppContext } from "@/contexts";
import { ScrollArea } from "@/components/ui/scrollarea";
import ImageContextualDisplay from "@/components/cards/user/ImageUpload";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { UserData } from "@/contexts/User";
import { TimeZoneSelect } from "@/components/parts";


const SettingsSchema = z.object({
    email: z.object({
        notifications: z.boolean(),
        frequency: z.string(),
        channel_tasks: z.boolean().optional(),
    }),
    notifications: z.object({
        notifications: z.boolean(),
        channel_tasks: z.boolean().optional(),
    }),
    personal: z.object({
        job_title: z.string(),
        family_name: z.string(),
        given_name: z.string(),
        timezone: z.string().optional(),
        locale: z.string().optional(),
    }),
});

export function UserProfile() {
    const { narrow, selectedTheme, loggedInUser, setLoggedInUser, setTheme } = useAppContext();
    const width = narrow ? "w-full" : "w-1/2";
    const tristateToggle = () => {
        if (selectedTheme === "light") {
            setTheme("dark");
        } else if (selectedTheme === "dark") {
            setTheme(null);
        } else {
            setTheme("light");
        }
    }

    const form = useForm<z.infer<typeof SettingsSchema>>({
        resolver: zodResolver(SettingsSchema),
        defaultValues: useMemo(() => {
            const settings = loggedInUser?.settings;
            const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
            return {
                email: {
                    notifications: settings?.email.notifications ?? true,
                    frequency: settings?.email.frequency ?? "instant",
                    channel_tasks: settings?.email.channels.includes("all") ?? true,
                },
                notifications: {
                    notifications: settings?.notifications.notifications ?? true,
                    channel_tasks: settings?.notifications.channels.includes("all") ?? true,
                },
                personal: {
                    job_title: loggedInUser?.jobTitle,
                    family_name: loggedInUser?.familyName,
                    given_name: loggedInUser?.givenName,
                    timezone: settings?.personal.timezone ?? resolvedOptions.timeZone,
                    locale: settings?.personal.locale ?? resolvedOptions.locale,
                },
            };
        }, [loggedInUser]),
    });

    useEffect(() => {
        const settings = loggedInUser?.settings;
        const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
        form.reset({
            email: {
                notifications: settings?.email.notifications ?? true,
                frequency: settings?.email.frequency ?? "instant",
                channel_tasks: settings?.email.channels.includes("all") ?? true,
            },
            notifications: {
                notifications: settings?.notifications.notifications ?? true,
                channel_tasks: settings?.notifications.channels.includes("all") ?? true,
            },
            personal: {
                job_title: loggedInUser?.jobTitle,
                family_name: loggedInUser?.familyName,
                given_name: loggedInUser?.givenName,
                timezone: settings?.personal.timezone ?? resolvedOptions.timeZone,
                locale: settings?.personal.locale ?? resolvedOptions.locale,
            },
        });
    }, [form, loggedInUser]);

    const themeIcon = () => {
        if (selectedTheme === "light") {
            return <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 transition-all dark:-rotate-90" />;
        } else if (selectedTheme === "dark") {
            return <Moon className="h-[1.2rem] w-[1.2rem] rotate-90 transition-all dark:rotate-0" />;
        } else {
            return <Computer className="h-[1.2rem] w-[1.2rem] scale-100 transition-all" />;
        }
    }

    const onSubmit = (data: z.infer<typeof SettingsSchema>) => {
        const settings = {
            job_title: loggedInUser?.jobTitle,
            given_name: loggedInUser?.givenName,
            family_name: loggedInUser?.familyName,
            settings: loggedInUser?.settings,
        }
        if (!settings.settings) {
            settings.settings = {
                email: {
                    notifications: false,
                    frequency: "instant",
                    channels: [],
                },
                personal: {},
                history: {},
                notifications: {
                    notifications: false,
                    channels: [],
                },
                security: {},
            };
        }

        settings.job_title = data.personal.job_title;
        settings.given_name = data.personal.given_name;
        settings.family_name = data.personal.family_name;
        settings.settings.email.notifications = data.email.notifications;
        settings.settings.email.frequency = data.email.frequency;
        settings.settings.email.channels = data.email.channel_tasks ? ["all"] : [];
        settings.settings.notifications.notifications = data.notifications.notifications;
        settings.settings.notifications.channels = data.notifications.channel_tasks ? ["all"] : [];
        settings.settings.personal.timezone = data.personal.timezone;
        settings.settings.personal.locale = data.personal.locale;

        apiFetch<UserData>('/api/v1/user/settings', { method: "PUT", headers: { "Content-Type": "application/json" }, body: JSON.stringify(settings) })
            .then((user) => {
                setLoggedInUser(user);
            })
            .catch(async (err) => {
                console.error(await err.text());
                form.reset();
            });
    };

    return <ScrollArea className="p-8 detail-grid__scrolling-area">
        {loggedInUser && <ImageContextualDisplay
            userId={loggedInUser.id}
            jobTitle={loggedInUser.jobTitle}
            maxZoom={3}
            zoomStep={0.2}
            rotationStep={10}
        />}
        <div className="extra-padding flex flex-col items-center rounded-lg border p-4">
            <h2 className="mb-2">Personalization</h2>
            <h4 className="mb-2">Theme</h4><div />
            <Button variant="outline" size="icon" onClick={tristateToggle}>
                {themeIcon()} <span className="sr-only">Toggle theme</span>
            </Button>
            <Form {...form}>
                <form className={width} onSubmit={form.handleSubmit(onSubmit)}>
                    <h4 className="mt-2 mb-2">Email</h4>
                    <FormField
                        control={form.control}
                        name="email.notifications"
                        render={({ field }) => (<FormItem>
                            <FormLabel>Email Notifications</FormLabel>
                            <FormControl>
                                <Switch checked={field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                            <FormDescription>Set to receive email notifications</FormDescription>
                        </FormItem>)}
                    />
                    <FormField
                        control={form.control}
                        name="email.frequency"
                        render={({ field }) => (<FormItem>
                            <FormLabel>Notification Frequency</FormLabel>
                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select a frequency" />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem className="select__hover" value="instant">Instant</SelectItem>
                                        <SelectLabel>Coming soon...</SelectLabel>
                                    </SelectGroup>
                                    {/* <SelectItem className="select__hover" value="halfHour">Bundled every 30 minutes</SelectItem> */}
                                    {/* <SelectItem className="select__hover" value="daily">Bundled daily</SelectItem> */}
                                </SelectContent>
                            </Select>
                            <FormDescription>How often you receive notifications</FormDescription>
                        </FormItem>)}
                    />
                    <FormField
                        control={form.control}
                        name="email.channel_tasks"
                        render={({ field }) => (<FormItem>
                            <FormLabel>Notification Channel: Tasks</FormLabel>
                            <FormControl>
                                <Switch checked={field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                            <FormDescription>Set to receive email notifications on watched tasks</FormDescription>
                        </FormItem>)}
                    />
                    <h4 className="mt-2 mb-2">App Notifications</h4>
                    <FormField
                        control={form.control}
                        name="notifications.notifications"
                        render={({ field }) => (<FormItem>
                            <FormLabel>Notifications</FormLabel>
                            <FormControl>
                                <Switch checked={field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                            <FormDescription>Set to receive in-app notifications</FormDescription>
                        </FormItem>)}
                    />
                    <FormField
                        control={form.control}
                        name="notifications.channel_tasks"
                        render={({ field }) => (<FormItem>
                            <FormLabel>Notification Channel: Tasks</FormLabel>
                            <FormControl>
                                <Switch checked={field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                            <FormDescription>Set to receive in-app notifications for updated tasks</FormDescription>
                        </FormItem>)}
                    />
                    <h4 className="mt-2 mb-2">Info</h4>
                    <FormField
                        control={form.control}
                        name="personal.job_title"
                        render={({ field }) => (<FormItem>
                            <FormLabel>Job Title</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormDescription>Your current job title, displayed on your profile</FormDescription>
                        </FormItem>)}
                    />
                    <FormField
                        control={form.control}
                        name="personal.given_name"
                        render={({ field }) => (<FormItem>
                            <FormLabel>First Name</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormDescription>Your given name</FormDescription>
                        </FormItem>)}
                    />
                    <FormField
                        control={form.control}
                        name="personal.family_name"
                        render={({ field }) => (<FormItem>
                            <FormLabel>Last Name</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormDescription>Your family name</FormDescription>
                        </FormItem>)}
                    />
                    <FormField
                        control={form.control}
                        name="personal.timezone"
                        render={({ field }) => (<FormItem>
                            <FormLabel>Timezone</FormLabel>
                            <TimeZoneSelect onChange={field.onChange} value={field.value} />
                            <FormDescription>Your current time zone</FormDescription>
                        </FormItem>)}
                    />
                    <FormField
                        control={form.control}
                        name="personal.locale"
                        render={({ field }) => (<FormItem>
                            <FormLabel>Locale</FormLabel>
                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select Locale" />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem className="select__hover" value="en-US">English (United States)</SelectItem>
                                        <SelectLabel>Localization coming soon...</SelectLabel>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                            <FormDescription>Your current locale</FormDescription>
                        </FormItem>)}
                    />

                    <Button className="mt-4 w-1/2 btn" type="submit"> Save </Button>
                </form>
            </Form>
        </div>
    </ScrollArea>;
}
