import React, { useEffect } from "react";
import AppPage from "./AppPage";
import { ConsentTask } from '@/contexts/InstructionContext';
import { AssignmentChange, CommentChange, fetchNotifications, InviteNotification, markAllNotificationsRead, Notification, TaskChange } from "./Notification";
import { useMilestoneContext, useProjectsContext, useUsersContext } from "@/contexts";
import { fetchTask, TaskUpdate, useTask } from "@/contexts/Task";
import { Milestone } from "@/contexts/Milestone";
import ReactTimeAgo from "react-time-ago";
import { Link } from "react-router-dom";
import { priorityNameFromPriorityValues, priorityValuesToPriorityArray } from "./cards/task/Priority";
import { UserData } from "@/contexts/User";

const InviteNotification: React.FC<{ message: InviteNotification, created: string, read: boolean }> = ({ message, created, read }) => {
    const date = new Date(created + "Z");
    const highlightClass = read ? "notification__item" : "notification__item notification__item--highlight";

    return (
        <div className={highlightClass}>
            <div className="notification__message">
                You have been invited to join {message.orgName} by {message.invitedBy.username}
            </div>
            <ReactTimeAgo className="ml-2 text-sm" date={date} />
        </div>
    );
}

interface TaskChangeNotificationProps {
    update: { update: TaskChange } | { assignment: AssignmentChange } | { comment: CommentChange };
    created: string;
    read: boolean;
}

interface TaskNotificationData {
    taskId: string;
    from: string;
}

type TaskMessage = { update: TaskChange } | { assignment: AssignmentChange } | { comment: CommentChange };
function getTaskDataFromNotification(message: TaskMessage): TaskNotificationData {
    if ("update" in message) {
        const data = message.update;
        return { taskId: data.taskId, from: data.from };
    } else if ("assignment" in message) {
        const data = message.assignment;
        return { taskId: data.taskId, from: data.from };
    } else if ("comment" in message) {
        const data = message.comment;
        return { taskId: data.taskId, from: data.from };
    }
    throw new Error("Invalid notification message");
}

export function updateToMessage(
    users: UserData[],
    priorityValues: Record<number, string> | null,
    milestones: Milestone[],
    userId: string,
    task: ConsentTask,
    update: TaskUpdate): React.ReactElement {
    const user = users.find((user) => user.id === userId) || { username: "Unknown" };

    if (typeof update === "object" && "assignOther" in update) {
        const userId = update.assignOther.userId;
        const user = users.find((user) => user.id === userId);
        return (<span>{user?.username} assigned {task.slug} to {user?.username}</span>);
    } else if (typeof update === "object" && "changeDescription" in update) {
        return (<span>{user?.username} modified the description for {task.slug}</span>);
    } else if (typeof update === "object" && "changeTitle" in update) {
        return (<span>{user?.username} set the title of {task.slug} to {update.changeTitle.title}</span>);
    } else if (typeof update === "object" && "changePriority" in update) {
        if (priorityValues) {
            const priorities = priorityValuesToPriorityArray(priorityValues);
            const name = priorityNameFromPriorityValues(update.changePriority.value, priorities);
            return (<span>{user?.username} changed the priority of {task.slug} to {name}</span>);
        }
    } else if (typeof update === "object" && "changeDueDate" in update) {
        if (update.changeDueDate.dueDate === null) {
            return (<span>{user?.username} removed the due date of {task.slug}</span>);
        } else {
            const date = new Date(update.changeDueDate.dueDate);
            return (
                <>
                    <div>{user?.username}</div>
                    <span>set the due date of {task.slug} to </span>
                    <ReactTimeAgo className="ml-2" date={date} />
                </>
            );
        }
    } else if (typeof update === "object" && "changeMilestone" in update) {
        if (update.changeMilestone.milestoneId) {
            const milestone = milestones.find(m => m.id === update.changeMilestone.milestoneId);
            return (<span>{user?.username} changed the milestone of {task.slug} to {milestone?.name}</span>);
        } else {
            return (<span>{user?.username} removed the milestone from {task.slug}</span>);
        }
    } else if (typeof update === "object" && "addComment" in update) {
        if (update.addComment.parentId) {
            return (<span>{user?.username} replied to a comment on {task.slug}</span>);
        } else {
            return (<span>{user?.username} added a comment to {task.slug}</span>);
        }
    } else if (typeof update === "object" && "editComment" in update) {
        return (<span>{user?.username} edited a comment on {task.slug}</span>);
    } else if (typeof update === "object" && "removeComment" in update) {
        return (<span>{user?.username} deleted a comment from {task.slug}</span>);
    } else if (typeof update === "object" && "link" in update) {
        fetchTask(update.link.taskId).then((linkedTask) => {
            return (<span>{user?.username} linked {task.slug} to task {linkedTask?.task.slug} ({update.link.linkType})</span>);
        });
    } else if (typeof update === "object" && "unlink" in update) {
        fetchTask(update.unlink.taskId).then((linkedTask) => {
            return (<span>{user?.username} unlinked {task.slug} from task {linkedTask?.task.slug}</span>);
        });
    } else if (typeof update === "object" && "metadata" in update) {
        if ("component" in update.metadata.value) {
            return (<span>{user?.username} added component {update.metadata.value.component as string} from {task.slug}</span>);
        } else if ("label" in update.metadata.value) {
            return (<span>{user?.username} removed tag {update.metadata.value.label as string} from {task.slug}</span>);
        }
    } else if (typeof update === "object" && "removeMetadata" in update) {
        if ("component" in update.removeMetadata.value) {
            return (<span>{user?.username} removed component {update.removeMetadata.value.component as string} from {task.slug}</span>);
        } else if ("label" in update.removeMetadata.value) {
            return (<span>{user?.username} removed tag {update.removeMetadata.value.label as string} from {task.slug}</span>);
        }
    } else if (typeof update === "object" && "transition" in update) {
        return (<span>{user?.username} transitioned {task.slug} to in progress</span>);
    } else if (typeof update === "object" && "stopWatchingTask" in update) {
        return (<span>{user?.username} stopped watching {task.slug}</span>);
    } else if (typeof update === "object" && "watchTask" in update) {
        return (<span>{user?.username} watching {task.slug}</span>);
    } else if (typeof update === "string") {
        switch (update) {
            case "assignSelf":
                return (<span>{user?.username} assigned themself to {task.slug}</span>);
            case "close":
                return (<span>{user?.username} closed {task.slug}</span>);
            case "restart":
                return (<span>{user?.username} reopened {task.slug}</span>);
            case "archive":
                return (<span>{user?.username} archived {task.slug}</span>);
            case "unassign":
                return (<span>{user?.username} unassigned {task.slug}</span>);
            case "undo":
                return (<span>{user?.username} undid the last action on {task.slug}</span>);
            default:
                return (<span>{user?.username} updated {task.slug}</span>);
        }
    }

    return <span>{user?.username} updated {task.slug}</span>;
}

const TaskChangeNotification: React.FC<TaskChangeNotificationProps> = ({ created, update, read }) => {
    const date = new Date(created + "Z");
    const highlightClass = read ? "notification__item" : "notification__item notification__item--highlight";
    const { taskId, from: userId } = getTaskDataFromNotification(update);
    const { data: taskDetail, isError, isLoading } = useTask(taskId);
    const task = taskDetail?.task;
    const { milestones } = useMilestoneContext();
    const { priorityValues } = useProjectsContext();
    const { users } = useUsersContext();

    const [message, setMessage] = React.useState<React.ReactNode | null>(null);
    const [link, setLink] = React.useState<string | null>(null);

    useEffect(() => {
        if (!task || isError || isLoading) {
            return;
        }
        const user = users.find((user) => user.id === userId);
        if (!user) {
            return;
        }

        setLink("/app/tasks/id/" + taskId);

        if ("assignment" in update) {
            setMessage(<span>{user.username} assigned you to {task.title}</span>);
        } else if ("comment" in update) {
            setMessage(<span>{user?.username} commented on {task?.title}</span>);
        } else {
            const consentTask = {
                task_id: task.id,
                slug: task.slug,
                title: task.title,
                description: task.description,
                tags: [],
                components: [],
                state: task.abstractState,
                assignee: task.assignee,
            };
            setMessage(updateToMessage(users, priorityValues, milestones, userId, consentTask, update.update.update));
        }
    }, [task, isError, isLoading, taskId, milestones, priorityValues, users, update, userId]);

    if (link) {
        return (
            <Link to={link}>
                <div className={highlightClass}>
                    <div className="notification__message">{message}</div>
                    <ReactTimeAgo className="ml-2 text-sm" date={date} />
                </div>
            </Link>
        );
    } else {
        return (
            <div className={highlightClass}>
                <div className="notification__message">{message}</div>
                <ReactTimeAgo className="ml-2 text-sm" date={date} />
            </div>
        );
    }
}

const ResultNotification: React.FC<{ created: string, message: string, read: boolean }> = ({ created, message, read }) => {
    const date = new Date(created + "Z");
    const highlightClass = read ? "notification__item" : "notification__item notification__item--highlight";
    return (
        <div className={highlightClass}>
            <div className="notification__message">{message}</div>
            <ReactTimeAgo className="ml-2 text-sm" date={date} />
        </div>
    );
}

const NotificationItem: React.FC<{ notification: Notification }> = ({ notification }) => {
    switch (notification.channel) {
        case "invite": {
            return <InviteNotification {...notification} message={notification.message as InviteNotification} />;
        }
        case "taskChange": {
            return <TaskChangeNotification {...notification} update={notification.message as TaskMessage} />;
        }
        default: {
            return <ResultNotification {...notification} message={notification.message as string} />;
        }
    }
};

export default function NotificationPage() {
    const [notifications, setNotifications] = React.useState<Notification[]>([]);

    React.useEffect(() => {
        fetchNotifications(1).then((notifications) => {
            setNotifications(notifications);
            markAllNotificationsRead();
        });
    }, []);

    return (
        <AppPage active="notifications">
            <div className="flex-col ml-auto mr-auto p-2">
                <h1 className="mb-4 w-full text-center">Notifications</h1>
                <div className="notification__container">
                    {notifications.map((notification) => (
                        <NotificationItem key={notification.id} notification={notification} />
                    ))}
                </div>
            </div>
        </AppPage>
    );
}
