import { FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { FaChartBar } from "react-icons/fa";
import ReactSelect, { ActionMeta, MultiValue } from "react-select";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { eventEmitter, useAppContext, useChartContext, useProjectsContext } from "@/contexts";
import reactSelectTheme from "@/components/SelectTheme";
import Checkbox from "@/components/ui/checkbox";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Select, SelectContent, SelectGroup, SelectTrigger, SelectItem, SelectValue } from "@/components/ui/select";
import { FormItemProps, TimeZoneSelect } from "@/components/parts";
import { Input } from "@/components/ui/input";
import DetailGrid, { DetailContentArea } from "../DetailGrid";
import { Button } from "@/components/ui/button";

const ChartSchema = z.object({
    projectIds: z.array(z.string()),
    workInfo: z.object({
        days: z.array(z.boolean()),
        timeStart: z.number().min(0).max(23),
        timeEnd: z.number().min(1).max(24),
        timeZone: z.string().min(1)
    }),
    planName: z.string().min(1),
    isPublic: z.boolean().optional(),
});

interface Option {
    readonly value: string;
    readonly label: string;
}

const WeekCheckBoxes = React.forwardRef<HTMLInputElement, FormItemProps<boolean[]>>(({ value, onChange }) => {
    const week = value || [false, true, true, true, true, true, false];

    const setDay = (day: number) => {
        week[day] = !week[day];
        onChange(week);
    }

    return (
        <div className="week-grid m-2 pb-2 mb-10">
            <label htmlFor="Sunday">Sunday</label>
            <label htmlFor="Monday">Monday</label>
            <label htmlFor="Tuesday">Tuesday</label>
            <label htmlFor="Wednesday">Wednesday</label>
            <label htmlFor="Thursday">Thursday</label>
            <label htmlFor="Friday">Friday</label>
            <label htmlFor="Saturday">Saturday</label>
            <Checkbox className="m-auto" id="Sunday" checked={week[0]} onCheckedChange={() => { setDay(0) }} />
            <Checkbox className="m-auto" id="Monday" checked={week[1]} onCheckedChange={() => { setDay(1) }} />
            <Checkbox className="m-auto" id="Tuesday" checked={week[2]} onCheckedChange={() => { setDay(2) }} />
            <Checkbox className="m-auto" id="Wednesday" checked={week[3]} onCheckedChange={() => { setDay(3) }} />
            <Checkbox className="m-auto" id="Thursday" checked={week[4]} onCheckedChange={() => { setDay(4) }} />
            <Checkbox className="m-auto" id="Friday" checked={week[5]} onCheckedChange={() => { setDay(5) }} />
            <Checkbox className="m-auto" id="Saturday" checked={week[6]} onCheckedChange={() => { setDay(6) }} />
        </div>
    );
});

const TIMES: { display: string, value: number }[] = Array.from({ length: 25 }, (_, i) => {
    const hour = i % 12 || 12;
    const ampm = i < 12 ? "AM" : "PM";
    return { display: `${hour}:00 ${ampm}`, value: i };
});

const ChartCreate = () => {
    const { createChart } = useChartContext();
    const navigate = useNavigate();
    const vanish = () => {
        navigate(-1);
    };
    const { orgProjects } = useProjectsContext();
    const { theme, config } = useAppContext();
    const projectOptions = orgProjects.map((project) => ({ value: project.id, label: project.name }));
    const [selectedOptions, setSelectedOptions] = React.useState(projectOptions);
    const { selectTheme, classes } = React.useMemo(() => {
        return reactSelectTheme(theme, config);
    }, [theme, config]);

    const form = useForm<z.infer<typeof ChartSchema>>({
        resolver: zodResolver(ChartSchema),
        defaultValues: useMemo(() => (
            {
                projectIds: [],
                workInfo: {
                    days: [false, true, true, true, true, true, false],
                    timeStart: 9,
                    timeEnd: 17,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                planName: "",
                isPublic: true,
            }
        ), []),
    });

    const onChangeSelected = (_option: MultiValue<Option> | null, actionMeta: ActionMeta<Option>) => {
        if (actionMeta.action == "select-option" && actionMeta.option) {
            setSelectedOptions([...selectedOptions, actionMeta.option]);
        } else if (actionMeta.action == "remove-value") {
            setSelectedOptions(selectedOptions.filter((option) => option.value !== actionMeta.removedValue?.value));
        } else if (actionMeta.action == "clear") {
            setSelectedOptions([]);
        }
    };

    const onSubmit = (data: z.infer<typeof ChartSchema>) => {
        console.log("Creating plan", data);
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        const create = {
            projectIds: selectedOptions.map((option) => option.value),
            startDate: date.toISOString(),
            timeZone: data.workInfo.timeZone,
            workingDays: data.workInfo.days,
            workingStart: data.workInfo.timeStart,
            workingEnd: data.workInfo.timeEnd,
            name: data.planName,
            isPublic: data.isPublic || true,
        };
        createChart(create).then((newPlan) => {
            navigate("/app/charts/" + newPlan);
        }).catch((error) => {
            console.error("Failed to create plan", error);
            eventEmitter.emit("ERROR", "Failed to create plan, you may not have appropriate permissions.");
        });
    };

    useEffect(() => {
        if (form.formState.errors.root?.message) {
            eventEmitter.emit("ERROR", form.formState.errors.root.message);
        }
        if (form.formState.errors.isPublic?.message) {
            eventEmitter.emit("ERROR", form.formState.errors.isPublic.message);
        }
        if (form.formState.errors.planName?.message) {
            eventEmitter.emit("ERROR", `Plan name ${form.formState.errors.planName.message}`);
        }
        if (form.formState.errors.workInfo?.days?.message) {
            eventEmitter.emit("ERROR", `Work days ${form.formState.errors.workInfo.days.message}`);
        }
        if (form.formState.errors.workInfo?.timeStart?.message) {
            eventEmitter.emit("ERROR", `Start time ${form.formState.errors.workInfo.timeStart.message}`);
        }
        if (form.formState.errors.workInfo?.timeEnd?.message) {
            eventEmitter.emit("ERROR", `End time ${form.formState.errors.workInfo.timeEnd.message}`);
        }
        if (form.formState.errors.workInfo?.timeZone?.message) {
            console.log(form.getValues());
            eventEmitter.emit("ERROR", `Time zone ${form.formState.errors.workInfo.timeZone.message}`);
        }
        if (form.formState.errors.projectIds) {
            eventEmitter.emit("ERROR", "Please select at least one project.");
        }
    }, [form.formState.errors]);

    const startTime = form.watch("workInfo.timeStart");

    return (<div className="card-detail" >
        <div className="card-detail__top-bar">
            <div className="card__icon">
                <FaChartBar />
            </div>
            <div className="card-detail__header-container">
                <div className="flex-grow" />
                <h2 className="card-detail__header">Project Planning</h2>
                <div className="flex-grow" />
            </div>
            <div className="card-detail__close" onClick={vanish}>
                <FaXmark />
            </div>
        </div>
        <DetailGrid narrow={true}>
            <DetailContentArea>
                {orgProjects ? <Form {...form}>
                    <form className="m-4 ml-6 text-left" onSubmit={form.handleSubmit(onSubmit)}>
                        <FormField
                            control={form.control}
                            name="projectIds"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Project Plans</FormLabel>
                                    <ReactSelect
                                        {...field}
                                        isMulti
                                        value={selectedOptions}
                                        isClearable
                                        classNames={classes as object}
                                        options={projectOptions}
                                        theme={selectTheme}
                                        onChange={onChangeSelected} />
                                    <FormDescription>Select the projects you want to create a plan for</FormDescription>
                                </FormItem>
                            )} />
                        <FormField
                            name="workInfo.days"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Working Days</FormLabel>
                                    <WeekCheckBoxes {...field} />
                                    <FormDescription className="mt-4 mb-4">What are your team's working days?</FormDescription>
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="workInfo.timeStart"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Start Time</FormLabel>
                                    <Select onValueChange={(v) => field.onChange(Number(v))} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Start time" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                {TIMES.map((option) => (
                                                    <SelectItem key={option.value} value={option.value.toString()}>{option.display}</SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <FormDescription>What time does your team typically start working?</FormDescription>
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="workInfo.timeEnd"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>End Time</FormLabel>
                                    <Select onValueChange={(v) => field.onChange(Number(v))} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="End time" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                {TIMES.filter((time) => startTime < time.value).map((option) => (
                                                    <SelectItem key={option.value} value={option.value.toString()}>{option.display}</SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <FormDescription>What time does your team typically stop working?</FormDescription>
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="workInfo.timeZone"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Time Zone</FormLabel>
                                    <TimeZoneSelect onChange={field.onChange} value={field.value} />
                                    <FormDescription>What time zone is your team in?</FormDescription>
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="planName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Plan Name</FormLabel>
                                    <Input {...field} className="form-input" />
                                    <FormDescription>What would you like to call this plan?</FormDescription>
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="isPublic"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Public</FormLabel>
                                    <Checkbox {...field} />
                                    <FormDescription>Would you like to make this plan public?</FormDescription>
                                </FormItem>
                            )}
                        />
                        <Button type="submit" className="btn mt-4">Create Plan</Button>
                    </form>
                </Form> : <div className="m-4 ml-6 text-left w-min">You don't have any organization projects to create a plan for. </div>}
            </DetailContentArea>
        </DetailGrid>
    </div>);
}
export default ChartCreate;
