import { ScrollArea } from "@/components/ui/scrollarea";
import { Label } from "@/components/ui/label";
import { isOrgAdmin, orgRoleString } from "@/contexts/User";
import { useAppContext } from "@/contexts";

export function OrganizationSettings() {
    const { loggedInUser } = useAppContext();
    const org = loggedInUser?.org;
    const orgAdmin = isOrgAdmin(loggedInUser);
    const orgRole = orgRoleString(loggedInUser?.orgRole);

    return <ScrollArea className="p-8 detail-grid__scrolling-area">
        <div className="extra-padding grid grid-cols-2 items-center rounded-lg border p-4 mt-2">
            <h4 className="mb-2 col-span-2">Organization</h4>

            <><Label>Name:</Label> <p>{org?.name}</p></>
            {org?.metadata.description && <><Label>Description:</Label><p>{org.metadata.description}</p></>}
            <><Label>Role:</Label> <p>{orgRole}</p></>

            {orgAdmin && <>
                {org?.metadata.max_capacity && org?.metadata.max_capacity < 1E6 &&
                    <><Label>Max Capacity: </Label><p>{org.metadata.max_capacity}</p></>}
                {org?.metadata.max_projects && org?.metadata.max_projects < 1E6 &&
                    <><Label>Max Projects: </Label><p>{org.metadata.max_projects}</p></>}
                {org?.metadata.credits && <><Label>Credits:</Label><p>{org.metadata.credits}</p></>}
                {org?.metadata.last_payment_date && <><Label>Last Payment Date: </Label><p>{org.metadata.last_payment_date}</p></>}
                {org?.metadata.overdue_since && <><Label>Overdue Since: </Label><p>{org.metadata.overdue_since}</p></>}
                {org?.metadata.region && <><Label>Region: </Label><p>{org.metadata.region}</p></>}
                {org?.metadata.timezone && <><Label>Timezone: </Label><p>{org.metadata.timezone}</p></>}
                {org?.metadata.contact && <><Label>Contact: </Label><p>{org.metadata.contact}</p></>}
                {org?.metadata.phone && <><Label>Phone: </Label><p>{org.metadata.phone}</p></>}
            </>}
        </div>
    </ScrollArea>;
}
