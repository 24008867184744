import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { apiFetch, apiFetchNoContent, eventEmitter } from ".";
import { ProviderProps } from "./ContextTypes";
import { Milestone } from "./Task";
import { toast } from "sonner";

export type ImportStateInfo = "none" | { issues: { issues: number } } | { comments: { total: number } } | { portraits: { total: number } };
export type InProgressState = "issues" | "comments" | "portraits";
export type ImportState = "pending" | { inProgress: InProgressState } | { paused: InProgressState } | "done" | "error";

export interface ImportUpdate {
    projectId: string;
    projectTrackerId: string;
    state: ImportState;
    error: null | string;
    info: ImportStateInfo;
}

export interface ProjectTracker {
    id: string,
    projectId: string,
    tracker: string,
    repo: string,
    created: string,
    repoUrl: string | null,
    isDefault: boolean,
}

export interface ProjectImportState {
    id: string,
    projectId: string,
    projectTracker: ProjectTracker,
    importState: ImportState,
    importError: string | null,
}

export interface Import {
    state: ImportState;
    error: null | string;
    info: ImportStateInfo;
}

export interface Repo {
    id: string;
    tracker: string;
    repoGitRemote: string;
    repoWebUrl?: string;
    isDefault: boolean;
    importState?: Import;
}

export interface Release {
    repo: Repo;
    previousRelease: Release | null;
    milestone: Milestone;
    name: string;
    releasedDate: string | null;
    plannedDate: string | null;
    metadata: object;
}

export interface Deployment {
    release: Release;
    deployedAt: string;
    environment: string;
    metadata: object;
}

export interface Project {
    id: string;
    name: string;
    slug: string;
    description: string;
    repos: Repo[];
    keys: string[];
    orgId?: string;
}

export interface ChartPlan {
    id: string;
    org_id: string;
    created_at: string;
    created_by: string;
    metadata: unknown;
}

export interface ExistingProject {
    repo: string,
    projectTracker: string,
}

export type ProjectSource = "personal" | "organization";

async function getProjects(index: number, source: ProjectSource): Promise<Project[]> {
    let list = "list";
    if (source === "organization") {
        list = "org_list";
    }

    try {
        return await apiFetch(`/api/v1/project/${list}/${index}`, {
            method: "GET",
        });
    } catch (error) {
        return [];
    }
}

async function createProject(
    name: string,
    description: string,
    projectSource: ProjectSource | null
): Promise<Project> {

    let path = '/api/v1/project';
    if (projectSource === 'organization') {
        path = '/api/v1/org/project';
    }

    return await apiFetch(path, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name,
            description,
            active: true,
        }),
    },
        (response) => {
            if (!response.ok) {
                if (response.status === 403) {
                    eventEmitter.emit("ERROR", "You cannot create more projects");
                } else {
                    eventEmitter.emit("ERROR", "Failed to create project");
                }
                throw new Error("Failed to create project");
            }
        }
    );
}

async function fetchProject(projectId: string): Promise<Project | null> {
    try {
        return await apiFetch(`/api/v1/project/${projectId}`, { method: "GET", });
    } catch (err) {
        return null;
    }
}

export type ProjectTrackerType = "zini" | "github";
export type ProjectTrackerUpdate = { tracker: ProjectTrackerType } |
{ repo: string } |
{ repoUrl: string } |
{ isDefault: boolean };
export type ProjectKeyType = "github";
export type Option = { id: number; label: string; value: string; };

export type ProjectUpdate = { name: string } |
{ description: string } |
{ defaultFlow: { flowId: string } } |
{ addRepo: { tracker: ProjectTrackerType, repo: string, repoUrl?: string, isDefault: boolean } } |
{ updateRepo: { repoId: string, update: ProjectTrackerUpdate } } |
{ deleteRepo: { repoId: string } } |
{ updateProjectKey: { key: ProjectKeyType, token: string } } |
{ deleteProjectKey: ProjectKeyType } |
    "setActiveProject";


async function updateProject(projectId: string, update: ProjectUpdate): Promise<Project> {
    return await apiFetch(`/api/v1/project/${projectId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(update),
    }, "Failed to update project");
}

async function callDeleteProject(projectId: string): Promise<void> {
    await apiFetchNoContent(`/api/v1/project/${projectId}`, {
        method: "DELETE",
    }, "You cannot delete this project");
}

export interface ProjectsContextProps {
    projects: Project[];
    orgProjects: Project[];
    nameToProject: Record<string, Project>;
    priorityValues: Record<number, string> | null;
    activeProject: Project | null;
    projectTags: Option[];
    projectComponents: Option[];

    taskPrompt: string;
    setTaskPrompt: (prompt: string) => void;

    priorityPrompt: string;
    setPriorityPrompt: (prompt: string) => void;

    getProject: (projectId: string) => Promise<Project | null>;
    refreshProjects: () => void;
    clearProjectCache: () => void;
    setProjects: (projects: Project[]) => void;
    setActiveProject: (project: Project) => void;
    addProject: (card: CreatedProject) => void;
    updateProject: (projectId: string, update: ProjectUpdate) => void;
    deleteProject: (projectId: string) => void;
    moveProjectToOrg: (project: Project) => void;
}

async function doMoveProjectToOrg(project: Project): Promise<void> {
    return await apiFetchNoContent(`/api/v1/project/${project.id}/to_org`, {
        method: "POST",
    });
}

interface UserPrompt {
    prompt: string;
}

async function getUserPrompt(userPromptType: string): Promise<string> {
    console.log("Getting user prompt", userPromptType);
    const result = await apiFetch<UserPrompt>(`/api/v1/prompt/${userPromptType}`, {
        method: "GET",
    });
    return result.prompt;
}

async function setUserPrompt(userPromptType: string, prompt: string): Promise<void> {
    await apiFetchNoContent('/api/v1/prompt', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ promptType: userPromptType, prompt }),
    });
}

export const ProjectsContext = createContext<ProjectsContextProps | undefined>(undefined);

interface PriorityLevel { name: string, value: number }
interface ProjectPriorityLevelsResponse { priorityLevels: PriorityLevel[] }

async function fetchPriorityValues(projectId: string): Promise<Record<number, string>> {
    const result = await apiFetch<ProjectPriorityLevelsResponse>(`/api/v1/project/${projectId}/priority_levels`, {
        method: "GET",
    });
    const record: Record<number, string> = {};
    for (let i = 0; i < result.priorityLevels.length; i += 1) {
        const item = result.priorityLevels[i];
        record[item.value] = item.name;
    }
    return record;
}

export interface CreatedProject {
    name: string;
    description: string;
    projectSource: ProjectSource | null;
}

async function fetchProjects(setProjects: (projects: Project[]) => void,
    getProjects: (page: number) => Promise<Project[]>) {
    let page = 1;
    let lastLen = -1;
    let newProjects: Project[] = [];
    while (newProjects.length !== lastLen) {
        lastLen = newProjects.length;
        newProjects = [...newProjects, ...await getProjects(page)];
        setProjects(newProjects);
        page += 1
    }
}

async function apiGetProject(projectId: string): Promise<Project | null> {
    try {
        return await apiFetch(`/api/v1/project/${projectId}`, {
            method: "GET",
        });
    } catch (error) {
        return null;
    }
}

export interface TagComponent {
    component: string;
}

export interface TagLabel {
    label: string;
}
export type Tag = TagComponent | TagLabel;

interface ProjectMetadataResponse { tags: string[], components: string[] }
async function getProjectMeta(projectId: string): Promise<ProjectMetadataResponse> {
    const response = await apiFetch<ProjectMetadataResponse>(`/api/v1/project/${projectId}/metadata`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    return response;
}

const ProjectsProvider: React.FC<ProviderProps> = ({ children }) => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [orgProjects, setOrgProjects] = useState<Project[]>([]);
    const [projectCache, setProjectCache] = useState(Date.now());
    const [activeProject, setActiveProject_] = useState<Project | null>(null);
    const [priorityValues, setPriorityValues] = useState<Record<number, string> | null>(null);
    const [projectTags, setProjectTags] = useState<Option[]>([]);
    const [projectComponents, setProjectComponents] = useState<Option[]>([]);

    const [taskPrompt, setTaskPrompt_] = useState<string>("");
    const [priorityPrompt, setPriorityPrompt_] = useState<string>("");

    useEffect(() => {
        getUserPrompt("taskDescription").then(setTaskPrompt_);
        getUserPrompt("taskPriority").then(setPriorityPrompt_);
    }, []);

    const setTaskPrompt = useCallback(async (prompt: string) => {
        await setUserPrompt("taskDescription", prompt);
        toast.success("Task prompt updated");
        setTaskPrompt_(prompt);
    }, []);

    const setPriorityPrompt = useCallback(async (prompt: string) => {
        await setUserPrompt("taskPriority", prompt);
        toast.success("Priority prompt updated");
        setPriorityPrompt_(prompt);
    }, []);

    useEffect(() => {
        if (activeProject?.id) {
            getProjectMeta(activeProject.id).then((metadata) => {
                const tagsParsed: Tag[] = metadata.tags.map((tag) => {
                    return { label: tag };
                }) as TagLabel[];
                const tagElements: Option[] = tagsParsed
                    .map((tag, id) => {
                        const label = (tag as TagLabel).label;
                        return { id, label, value: label };
                    });
                setProjectTags(tagElements);

                const componentsParsed: Tag[] = metadata.components.map((tag) => {
                    return { component: tag };
                }) as TagComponent[];
                const componentElements: Option[] = componentsParsed
                    .map((tag, id) => {
                        const label = (tag as TagComponent).component;
                        return { id, label, value: label };
                    });
                setProjectComponents(componentElements);
            }).catch(() => {
                console.error("Failed to get project", activeProject?.id);
            });
        }
    }, [activeProject?.id]);


    const refreshProjects = useCallback(async () => {
        await Promise.allSettled([
            fetchProjects(setProjects, (page) => getProjects(page, "personal")),
            fetchProjects(setOrgProjects, (page) => getProjects(page, "organization"))
        ]);
    }, []);
    useEffect(() => { refreshProjects(); }, [refreshProjects, projectCache]);

    const clearProjectCache = useCallback(() => {
        setProjectCache(Date.now());
    }, []);

    const nameToProject = useMemo(() => {
        const nameToProject: Record<string, Project> = {};
        for (let i = 0; i < projects.length; i += 1) {
            const project = projects[i];
            nameToProject[project.name] = project;
        }
        return nameToProject;
    }, [projects]);

    const insertProject = useCallback((project: Project) => {
        if (project.orgId) {
            if (orgProjects.find((item) => item.id === project.id)) {
                return;
            }
            setOrgProjects([...orgProjects, project]);
        } else {
            if (projects.find((item) => item.id === project.id)) {
                return;
            }
            setProjects([...projects, project]);
        }
    }, [projects, orgProjects]);

    const addProject = useCallback(({ name, description, projectSource }: CreatedProject) => {
        createProject(name, description, projectSource).then((project) => insertProject(project));
    }, [insertProject])

    useEffect(() => {
        const addProjectEvent = ({ project }: { project: Project }) => {
            insertProject(project);
        };

        eventEmitter.on("PROJECT-ADD", addProjectEvent);
        return () => {
            eventEmitter.off("PROJECT-ADD", addProjectEvent);
        };
    }, [insertProject]);

    const _updateProject = useCallback((projectId: string, update: ProjectUpdate) => {
        updateProject(projectId, update).then((project) => {
            const updatedProjects = projects.map((item) => {
                if (project.id === item.id) {
                    return project;
                }
                return item;
            })
            setProjects(updatedProjects);
        })
            .catch(() => {
                return null;
            });
    }, [projects]);

    const deleteProject = useCallback((projectId: string) => {
        callDeleteProject(projectId).then(() => {
            const updatedProjects = projects.filter((item) => projectId !== item.id);
            setProjects(updatedProjects);
            const updatedOrgProjects = orgProjects.filter((item) => projectId !== item.id);
            setOrgProjects(updatedOrgProjects);
            console.log("Deleted project", projectId);
        });
    }, [projects, orgProjects]);

    const setActiveProject = useCallback((project: Project) => {
        updateProject(project.id, "setActiveProject").then((project) => {
            setActiveProject_(project);
        });
    }, []);

    useEffect(() => {
        if (activeProject) {
            return;
        }

        fetchProject("active").then((project) => {
            if (project) {
                setActiveProject_(project);
            } else if (projects.length > 0) {
                setActiveProject(projects[0]);
            } else if (orgProjects.length > 0) {
                setActiveProject(orgProjects[0]);
            }
        });
    }, [projects, orgProjects, setActiveProject, activeProject]);

    useEffect(() => {
        if (activeProject) {
            fetchPriorityValues(activeProject.id).then(setPriorityValues);
        }
    }, [activeProject]);

    const moveProjectToOrg = useCallback((project: Project) => {
        doMoveProjectToOrg(project).then(() => {
            const updatedProjects = projects.filter((item) => item.id !== project.id);
            setProjects(updatedProjects);
            setOrgProjects([...orgProjects, project]);
        });
    }, [projects, orgProjects]);

    const getProject = useCallback(async (projectId: string): Promise<Project | null> => {
        return await apiGetProject(projectId);
    }, []);

    const value = useMemo(() => {
        return {
            projects,
            orgProjects,
            nameToProject,
            priorityValues,
            projectTags,
            projectComponents,
            taskPrompt,
            setTaskPrompt,
            priorityPrompt,
            setPriorityPrompt,
            refreshProjects,
            clearProjectCache,
            getProject,
            setProjects,
            activeProject,
            setActiveProject,
            moveProjectToOrg,
            addProject,
            updateProject: _updateProject,
            deleteProject
        };
    }, [projects,
        orgProjects,
        nameToProject,
        activeProject,
        priorityValues,
        projectTags,
        projectComponents,
        taskPrompt,
        setTaskPrompt,
        priorityPrompt,
        setPriorityPrompt,
        moveProjectToOrg,
        clearProjectCache,
        getProject,
        refreshProjects,
        setActiveProject,
        _updateProject,
        deleteProject,
        addProject]);

    return (
        <ProjectsContext.Provider value={value}>
            {children}
        </ProjectsContext.Provider>
    );
}
export default ProjectsProvider;
