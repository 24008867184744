import { useCallback, useEffect, useState } from "react";
import { MilestoneIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import { useMilestoneContext } from "@/contexts";
import { Milestone } from "@/contexts/Milestone";
import DetailGrid, { DetailContentArea } from "@/components/cards/DetailGrid";
import DetailCard from "@/components/cards/DetailCard";
import { Label } from "@/components/ui/label";
import { MilestoneTypeLabel } from "./utils";
import { FaRegSave } from "react-icons/fa";
import { toast } from "sonner";
import { Button } from "react-day-picker";
import Editor from "@/components/Editor";
import { EditDateTime } from "../EditableDropdown";

interface MilestoneDetailProps {
    id: string
}

function MilestoneDetail({ id }: MilestoneDetailProps) {
    const { getMilestone, updateMilestone, milestoneCache } = useMilestoneContext();
    const [data, setData] = useState<Milestone | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getMilestone(id).then((milestone) => setData(milestone));
    }, [id, getMilestone, milestoneCache]);

    const vanish = () => {
        navigate(-1);
    };

    const onStart = useCallback(async () => {
        if (data) {
            await updateMilestone(data.id, "start");
            toast.success("Milestone started", { icon: <FaRegSave /> });
        }
    }, [data, updateMilestone]);

    const onStop = useCallback(async () => {
        if (data) {
            await updateMilestone(data.id, "stop");
            toast.success("Milestone halted", { icon: <FaRegSave /> });
        }
    }, [data, updateMilestone]);

    const onSetComplete = useCallback(async (complete: boolean) => {
        if (data && complete != data.completed) {
            await updateMilestone(data.id, { completed: complete });
            toast.success("Milestone updated", { icon: <FaRegSave /> });
        }
    }, [data, updateMilestone]);

    const onSetName = useCallback(async (name: string) => {
        if (data && name != data.name) {
            await updateMilestone(data.id, { name });
            toast.success("Milestone updated", { icon: <FaRegSave /> });
        }
    }, [data, updateMilestone]);

    const onSetDescription = useCallback(async (description: string) => {
        if (data && description != data.description) {
            await updateMilestone(data.id, { description });
            toast.success("Milestone updated", { icon: <FaRegSave /> });
        }
    }, [data, updateMilestone]);

    const onSetDueDate = useCallback(async (dateString: string | null) => {
        if (data && dateString && dateString != data.dueDate) {
            await updateMilestone(data.id, { dueDate: dateString });
            toast.success("Milestone updated", { icon: <FaRegSave /> });
        }
    }, [data, updateMilestone]);

    if (!data) {
        return null;
    }

    const createdDate = new Date(data.created);
    const dueDate = data.dueDate ? new Date(data.dueDate) : null;
    const startDate = data.startDate ? new Date(data.startDate) : null;

    return (
        <DetailCard
            title={data.name}
            titleIcon={<MilestoneIcon size={24} />}
            editable={true}
            onSetTitle={onSetName}
            onClose={vanish} >
            <DetailGrid narrow={true}>
                <DetailContentArea>
                    <div className="mb-2 h-10 w-64 mr-2 flex m-2 items-center justify-between rounded-lg border p-2">
                        <MilestoneTypeLabel type={data.milestoneType} />
                    </div>
                    <div className="mb-2 h-10 w-64 mr-2 flex m-2 items-center justify-between rounded-lg border p-2">
                        <Label className="detail-grid__label">Created</Label>
                        <ReactTimeAgo className="mr-2" date={createdDate} />
                    </div>
                    <div className="mb-2 h-10 w-64 mr-2 flex m-2 items-center justify-between rounded-lg border p-2">
                        <Label className="detail-grid__label">Started</Label>
                        {startDate && <ReactTimeAgo className="mr-2" date={startDate} />}
                        {data.started && <Button onClick={onStop} className="btn text-sm p-1">Stop</Button>}
                        {!data.started && <Button onClick={onStart} className="btn text-sm">Start</Button>}
                    </div>
                    <div className="m-2 w-64">
                        <EditDateTime
                            textLabel="Due"
                            overdueLabel="Overdue"
                            date={dueDate}
                            completed={data.completed}
                            onCompleted={onSetDueDate}
                        />
                    </div>
                    <div className="mb-2 h-10 w-64 mr-2 flex m-2 items-center justify-between p-2">
                        {!data.completed && <Button onClick={() => onSetComplete(true)} className="btn w-full">Complete</Button>}
                        {data.completed && <Button onClick={() => onSetComplete(false)} className="btn w-full">Reopen</Button>}
                    </div>
                    <div className="m-2 items-center justify-between rounded-lg border p-4 w-9/12">
                        <h2 className="mb-2">Description</h2>
                        <Editor
                            markdown={data.description}
                            users={[]}
                            placeholder="Description..."
                            onSaveMarkdown={onSetDescription}
                        />
                    </div>
                </DetailContentArea>
            </DetailGrid>
        </DetailCard>
    );
}
export default MilestoneDetail;
